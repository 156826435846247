import { useMemo } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import StarsDecoration from "@Assets/Images/stars-decoration.png";
import { useNavigate } from "react-router-dom";
import Button from "@Atom/Button";
export default function CarouselLandingPageClass() {
  const itemCategories = useMemo(() => {
    return [
      {
        image: Images.MAP_LANDING_PAGE,
        value: "Language",
        title: "Bahasa",
      },
      {
        image: Images.GLOBE_LANDING_PAGE,
        value: "Bisnis",
        title: "Bisnis",
      },
      {
        image: Images.ART_LANDING_PAGE,
        value: "Seni/Kerajinan",
        title: "Kerajinan",
      },
      {
        image: Images.MARKETING_LANDING_PAGE,
        title: "Marketing",
        value: "Marketing",
      },

      {
        image: Images.DESIGN_LANDING_PAGE,
        title: "Desain",
        value: "Design",
      },
      {
        image: Images.PARCEL_BOX,
        title: "Sales",
        value: "Sales",
      },
      {
        image: Images.NOTES_LANDING_PAGE,
        title: "Jurnalistik",
        value: "Jurnalistik",
      },
      {
        image: Images.PERCENTAGE_LANDING_PAGE,
        title: "Manajemen",
        value: "Manajemen",
      },

      {
        image: Images.LIFE_STYLE_LANDING_PAGE,
        title: "Lifestyle",
        value: "Lifestyle",
      },

      {
        image: Images.HEADPHONE_LANDING_PAGE,
        title: "Komunikasi",
        value: "Communication",
      },
    ];
  }, []);
  const navigate = useNavigate();
  return (
    <div className={Styles.container}>
      <div className={Styles.headerClassCategory} id="categories">
        <h1>
          <img src={StarsDecoration} alt="stars" />
          Kategori Kelas Unggulan <span>{" Skills.id "}</span>
        </h1>
      </div>
      <div className={Styles.mainContainCategory}>
        <h2 className={Styles.title}>Kategori Kelas Unggulan</h2>
        <div className={Styles.wrapCategory}>
          {itemCategories.map((c, idx) => (
            <div
              key={idx}
              onClick={() => navigate(`/courses?categories=${c.value}`)}
            >
              <img src={c?.image} alt={c?.title} />
              <h3>{c?.title}</h3>
            </div>
          ))}
        </div>
        <div className={Styles.button}>
          <Button
            variant="outlined"
            text={"Lihat Selengkapnya"}
            iconSize={"20"}
            endIcon={"arrow-right-next"}
            onClick={() => navigate("courses")}
          />
        </div>
      </div>
    </div>
  );
}
