import Input from "@Atom/Input";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useCallback, useEffect, useRef } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Styles from "./styles.module.scss";

const options = {
  disableDefaultUI: false,
  zoomControl: false,
  fullScreenControl: false,
  streetViewControl: false,
};

const libraries = ["places"];

const center = { lat: 0, lng: 0 };

export default function Maps({
  marker,
  mapWidth = "755px",
  setMarker = () => {},
  setAddress,
  withInput,
}) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB0Gy-BuZ0XyKlvhNtuhRPJh4RGdfQOQgE",
    libraries,
  });
  const mapContainerStyle = {
    height: "451px",
    width: mapWidth,
  };

  const onMapClick = useCallback(async (e) => {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapRef = useRef();

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(20);
    try {
    } catch (error) {}
  }, []);

  const handleSelect = async (address) => {
    setAddress(address);
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = getLatLng(results[0]);
      panTo({ lat, lng });
      setMarker({ lat, lng });
    } catch (error) {}
  };

  const onMapLoad = useCallback(
    (map) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => null
      );
      mapRef.current = map;
    },
    [panTo]
  );

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div className={Styles.container}>
      {withInput && (
        <div className={Styles.mapsInput}>
          {/* <Text size={"s"} width={"medium"} color={"#616161"}>
            Alamat
          </Text> */}
          <SearchAutocomplete handleSelect={handleSelect} />
        </div>
      )}
      <GoogleMap
        id="map"
        zoom={20}
        mapContainerStyle={mapContainerStyle}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {marker ? (
          <div>
            <Marker position={{ lat: marker?.lat, lng: marker?.lng }} />
          </div>
        ) : null}
      </GoogleMap>
    </div>
  );
}

function SearchAutocomplete({
  handleSelect,
  setMapDialog,
  inputValue,
  setInputValue,
  setLocation,
}) {
  const { value, suggestions, setValue } = usePlacesAutocomplete({
    requestOptions: {},
    // debounce: 300,
  });

  useEffect(() => {
    handleSelect(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      <div>
        <Input
          value={value}
          setValue={setValue}
          dropdownOptions={suggestions.data.map(
            ({ place_id, description }) => description
          )}
          isDropdown
        />
        {/* <Autocomplete
              onChange={(event, newValue) => {
                newValue === null ? handleSelect("") : handleSelect(newValue);
              }}
              onInputChange={(e, newValue) => handleChangeInput(e, newValue)}
              noOptionsText="No Location found"
              inputValue={value}
              value={value}
              options={suggestions.data.map(
                ({ place_id, description }) => description
              )}
              // loading={status !== "OK"}
              renderInput={(params) => <TextField {...params} label="Search" />}
            /> */}
      </div>
    </div>
  );
}
