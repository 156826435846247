import Avatar from "@Atom/Avatar";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Icon from "@Atom/Icon";
import TransparentSearchbar from "@Atom/TransparentSearchbar";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Footer from "@Molecule/Footer";
import Table from "@Molecule/Table";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import useWindowSize from "@Hooks/useWindowSize";
import { getAllInstructors } from "@Services/user";
import { fileBaseUrl } from "@Config/index";

export default function AdminInstructorLayout() {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { width } = useWindowSize();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await getAllInstructors();
        setLoading(false);
        if (res.status) {
          setData(res.data);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const template = useMemo(() => {
    return {
      data: data
        ?.filter((el) =>
          el.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice(limit * (page - 1), limit * page)
        .map((el) => {
          return {
            ...el,
            id: el?._id,
            profilePicture: el?.profilePicture || "",
          };
        }),

      columns: [
        {
          name: "",
          title: "Nama Instruktur",
          width: width > 768 ? "455px" : "200px",
          renderData: (row) => (
            <div
              className={Styles.instructor}
              onClick={() => navigate(`edit/${row.id}`)}
              style={{ cursor: "pointer" }}
            >
              <Avatar
                imgUrl={
                  row?.profilePicture ? fileBaseUrl + row?.profilePicture : ""
                }
                iconName="user"
                iconSize="12px"
                size="24px"
              />
              <span>{row?.name}</span>
            </div>
          ),
        },
        {
          name: "email",
          title: "Email",
          hideOnMobile: true,
        },
        {
          name: "phoneNumber",
          title: "Nomor HP",
          hideOnMobile: true,
        },
      ],
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, limit, page, width, searchTerm]);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat datang {<span>{user?.name}</span>}</h3>
            <span>Berikut adalah daftar seluruh instruktur di Skills.id</span>
            <TransparentSearchbar
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder="Cari"
            />
          </div>
          <button onClick={() => navigate("new")}>
            <Icon icon={"plus"} size={10} />
            <span>Tambah Instruktur</span>
          </button>
        </DoodleGradientCard>
        <Table
          data={template?.data}
          totalData={data?.length}
          totalPage={Math.ceil(data.length / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          isLoading={loading}
          expandComponent={(row) => (
            <div className={Styles.expandedInfo}>
              <div className={Styles.infoRow}>
                <span>Email</span>
                <span>{row?.email}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>No. Telepon</span>
                <span>{row?.phoneNumber}</span>
              </div>
            </div>
          )}
        />
      </div>

      <Footer />
    </div>
  );
}
