import React, { useEffect, useMemo } from "react";
import Styles from "./style.module.scss";
import Searchbar from "@Atom/Searchbar";
import { useState } from "react";
import Table from "@Molecule/Table";
import useWindowSize from "@Hooks/useWindowSize";
import { useParams } from "react-router-dom";
import { getClassSurveysAdmin } from "@Services/admin";
import moment from "moment";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";

export default function JournalListAdmin() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const { width } = useWindowSize();
  const [data, setData] = useState({ totalData: 0, data: [] });
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [selectedSurvey, setSelectedSurvey] = useState(null);


  const getSurveysData = async () => {
    try {
      setLoading(true);
      const res = await getClassSurveysAdmin(id, page, limit);
      setData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    getSurveysData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, id]);

  const template = useMemo(() => {
    return {
      data: data.data,
      columns: [
        {
          name: "",
          //   width: width > 768 ? "455px" : "200px",
          title: "Nama Peserta",
          renderData: (row) => (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedSurvey(row)}
            >
              {row?.user?.name}
            </span>
          ),
        },
        {
          name: "",
          title: "Email",
          renderData: (row) => <span>{row?.user?.email}</span>,
        },
        {
          name: "",
          title: "Tanggal Diisi",
          renderData: (row) => (
            <span>
              {row?.createdAt
                ? moment(row?.createdAt).format("DD MMMM YYYY")
                : "-"}
            </span>
          ),
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, width]);

  return (
    <div className={Styles.container}>
      <Searchbar />
      <div className={Styles.tableWrapper}>
        <Table
          data={template?.data}
          totalData={data?.totalData}
          totalPage={Math.ceil(data?.totalData / limit)}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !data?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withNumbering
          withPagination
          isLoading={loading}
        />
      </div>
      <Modal isOpen={!!selectedSurvey}>
        <div className={Styles.modalContainer}>
          <div className={Styles.modalHead}>
            <p>Detail Reflective Journal</p>
            <Icon
              icon={"cross"}
              size={24}
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedSurvey(null)}
            />
          </div>
          <div className={Styles.divider} />
          <div className={Styles.modalBody}>
            {selectedSurvey?.surveys?.map((el, idx) => (
              <div className={Styles.surveyWrapper}>
                <p>{idx + 1}.</p>
                <div className={Styles.textWrapper}>
                  <p>{el.contentHTML}</p>
                  <p>{el.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}
