// import Styles from './style.module.scss'

// export default function Spinner({
//   size = '48px',
//   className=''
// }) {

//   return (
//     <span style={{ width:size }} className={`${Styles.spinner} ${className}`}></span>
//   )
// }
import Images from "@Theme/Images";
import Styles from "./style.module.scss";

export default function Spinner({ width = "24px" }) {
  return (
    <img
      style={{ width }}
      className={Styles.loader}
      src={Images.LOADER_GRADIENT}
      alt="loading"
    />
  );
}
