import React, { useEffect, useMemo } from "react";
import Styles from "./styles.module.scss";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import { useNavigate } from "react-router-dom";
import { capitalizeWords } from "@Helpers/capitallizeWords";
import moment from "moment";
import { fileBaseUrl } from "@Config/index";
import useWindowSize from "@Hooks/useWindowSize";
import { encryptStorageData } from "@Helpers/encryptDecrypt";
export default function ArticleLandingPage({ data }) {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  useEffect(() => {
    encryptStorageData("showArticle", data?.length, sessionStorage);
  }, [data]);

  const articleDescription = useMemo(() => {
    return data?.map((a, idx) => ({
      id: a?._id,
      isNewest: idx === 0,
      category: capitalizeWords(a?.category),
      updatedAt: moment(a?.createdAt).format(
        width <= 380 && idx !== 0 ? "ll" : "DD MMMM YYYY"
      ),
      image: fileBaseUrl + a?.banner?.url,
      title: a?.name,
      description: a?.content,
    }));
  }, [data, width]);

  return data?.length >= 4 ? (
    <article id="article" className={Styles.articleWrapper}>
      <div className={Styles.articleLanding}>
        <div className={Styles.header}>
          <h2>
            Artikel <span> Skills.id</span>
          </h2>
          <div className={Styles.button}>
            <Button
              variant="outlined"
              text={"Lihat Lebih Banyak"}
              iconSize={"16"}
              endIcon={"arrow-up-right"}
              className={Styles.btnMore}
              onClick={() => navigate("article")}
            />
          </div>
        </div>
        <section className={Styles.mainContain}>
          <div className={Styles.left}>
            {articleDescription
              .filter((ar) => ar?.isNewest)
              .map((a) => (
                <div
                  className={Styles.articleNewest}
                  onClick={() => navigate(`/article/${a?.id}`)}
                  key={a.id}
                >
                  <img src={a.image} alt={a.title} />
                  <div className={Styles.shadowInsetImg} />
                  <div className={Styles.descriptionContent}>
                    <div className={Styles.category}>
                      <div className={Styles.name}>
                        <span>{a.category}</span>
                      </div>
                      <div className={`${Styles.newest} ${Styles.name} `}>
                        <span>Terbaru</span>
                      </div>
                      <div className={Styles.name}>
                        <Icon icon={"calendar1"} color={"fff"} size={"16px"} />
                        <span>{a.updatedAt}</span>
                      </div>
                    </div>
                    <h4>{a.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: a?.description }} />
                  </div>
                </div>
              ))}
          </div>
          <div className={Styles.right}>
            {articleDescription
              .filter((ar) => !ar?.isNewest)
              .map((a) => (
                <div
                  className={Styles.wrapDescription}
                  key={`list-articles-${a.id}`}
                  onClick={() => navigate(`/article/${a?.id}`)}
                >
                  <img src={a?.image} alt={a?.title} />
                  <div className={Styles.descriptionContent}>
                    <div className={Styles.category}>
                      <div className={Styles.name}>
                        <span>{a.category}</span>
                      </div>
                      <div className={Styles.name} style={{ flexShrink: "0" }}>
                        <Icon icon={"calendar1"} color={"fff"} size={"16px"} />
                        <span>{a.updatedAt}</span>
                      </div>
                    </div>
                    <h4>{a.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: a?.description }} />
                  </div>
                </div>
              ))}
          </div>
        </section>
      </div>
    </article>
  ) : (
    ""
  );
}
