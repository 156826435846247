import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import Select from "@Atom/Select";
import CheckBox from "@Atom/Checkbox";
import Button from "@Atom/Button";
import {
  _createPromo,
  _deletePromo,
  _editPromo,
  _fetchPromo,
  _searchClassByTitle,
} from "@Services/promo";
import { fileBaseUrl } from "@Config/index";
import ImagePreview from "@Atom/ImagePreview";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { translateError } from "@Helpers/translateError";
import toast from "react-hot-toast";
import ModalConfirmDelete from "@Molecule/ModalConfirmDelete";

export default function ModalAddPromo({
  open,
  onClose = () => {},
  isEdit,
  refetchData,
}) {
  const [promoDetail, setPromoDetail] = useState({
    name: "",
    promoType: "Nominal Rupiah",
    promoValue: "",
    start: "",
    end: "",
  });

  const [courses, setCourses] = useState([
    {
      _id: 1,
      title: "",
      quota: "",
      noQuota: "",
    },
  ]);

  const [loadingClass, setLoadingClass] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingGetData, setLoadingGetData] = useState(false);
  const [classList, setClassList] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const navigate = useNavigate();
  console.log(loadingGetData);

  const handleChangeDetail = (key, value) => {
    let temp = { ...promoDetail };
    temp[key] = value;
    if (key === "promoType") {
      temp.promoValue = "";
    }
    setPromoDetail(temp);
  };

  const handleDeletePromo = async () => {
    try {
      await _deletePromo(isEdit);
      refetchData();
      onClose();
      setOpenConfirmDelete(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = () => {
    if (selectAll) {
      setCourses([classList[0]]);
    } else {
      setCourses(classList.filter((course) => !course.promo));
    }
    setSelectAll((prev) => !prev);
  };

  const handleChangeCoursesData = (index, key, value) => {
    setCourses((prev) => {
      return prev.map((course, i) => {
        if (i !== index) return course;
        const updatedCourse = { ...course, [key]: value };
        if (key === "noQuota") {
          updatedCourse.quota = "";
        }
        if (key === "title") {
          const findClass = [...classList]
            .filter(
              (course) => !courses.some((filter) => course._id === filter._id)
            )
            .find((o) => o.title === value);

          if (findClass) {
            return { ...updatedCourse, ...findClass };
          }
          delete updatedCourse.asset;
        }
        return updatedCourse;
      });
    });
  };

  useEffect(() => {
    const findClass = async () => {
      try {
        setLoadingClass(true);
        const res = await _searchClassByTitle("");
        setClassList(res);
        setLoadingClass(false);
      } catch (error) {
        setLoadingClass(false);
      }
    };
    findClass();
  }, []);

  useEffect(() => {
    const fetchPromo = async () => {
      try {
        setLoadingGetData(true);
        const res = await _fetchPromo(isEdit);
        setCourses(
          res.courses.map((el) => ({
            ...el,
            title: el.title,
            noQuota: el.promo.quota === 999,
            quota: el.promo.quota === 999 ? "" : el.promo.quota - el.promo.used,
            disableEdit: true,
            used: el?.promo?.used || 0,
          }))
        );
        setPromoDetail({
          promoType: res.promoType === "NOMINAL" ? "Nominal Rupiah" : "Persen",
          start: moment(res.start).format("YYYY-MM-DD"),
          end: moment(res.end).format("YYYY-MM-DD"),
          name: res.name,
          promoValue: res.promoValue,
        });
        setLoadingGetData(false);
      } catch (error) {
        setLoadingGetData(false);
        setLoadingClass(false);
      }
    };
    if (isEdit && classList) {
      fetchPromo();
    }
  }, [classList, isEdit]);

  const handleCreatePromo = async () => {
    const dataToSend = {
      ...promoDetail,
      promoType:
        promoDetail.promoType === "Nominal Rupiah" ? "NOMINAL" : "PERCENTAGE",
      courses: courses.map((course) => ({
        courseID: course._id,
        quota: course.noQuota ? 999 : course?.quota,
        used: course?.used || 0,
      })),
    };
    try {
      setLoadingSubmit(true);
      if (isEdit) {
        await _editPromo(isEdit, dataToSend);
      } else {
        await _createPromo(dataToSend);
      }
      navigate("", { replace: true });
      refetchData();
      onClose();
      setLoadingSubmit(false);
    } catch (error) {
      const errorMsg = translateError(error.response.data.error);
      setLoadingSubmit(false);
      toast.error(errorMsg);
    }
  };

  return (
    <Modal isOpen={open}>
      <div className={Styles.container}>
        <div className={Styles.modalHeader}>
          <div className={Styles.headerText}>
            <p>{isEdit ? "Edit" : "Tambah"} Promo</p>
            {!isEdit && <p>Lengkapi data berikut untuk membuat promo baru</p>}
          </div>
          <Icon icon={"cross"} size={24} onClick={onClose} />
        </div>
        <div className={Styles.scrollWrapper}>
          <div className={Styles.modalInput}>
            <div className={Styles.inputWithLabel}>
              <p className={Styles.label}>
                Nama Promo<span>*</span>
              </p>
              <Input
                label="Nama Promo"
                placeholder="Masukan Nama Promo"
                endIcon="search-normal"
                value={promoDetail.name}
                setValue={(e) => handleChangeDetail("name", e)}
              />
            </div>
            <div className={Styles.inputBottomContainer}>
              <div className={Styles.flex}>
                <div className={Styles.inputWithLabel}>
                  <p className={Styles.label}>
                    Jumlah Potongan<span>*</span>
                  </p>
                  <Select
                    placeholder="Masukan Jumlah Potongan"
                    options={["Nominal Rupiah", "Persen"]}
                    menuPosition={"auto"}
                    className={Styles.select}
                    value={promoDetail.promoType}
                    setValue={(e) => handleChangeDetail("promoType", e)}
                  />
                </div>
                <div>
                  <Input
                    label="Jumlah potongan"
                    placeholder="Jumlah potongan"
                    value={promoDetail.promoValue}
                    setValue={(e) => handleChangeDetail("promoValue", e)}
                    isCurrency={
                      promoDetail.promoType === "Nominal Rupiah" ? true : false
                    }
                    isPhoneNumber={false}
                    isPercentage={
                      promoDetail.promoType === "Persen" ? true : false
                    }
                  />
                </div>
              </div>

              <div className={Styles.flex}>
                <div className={Styles.inputWithLabel}>
                  <p className={Styles.label}>
                    Periode Promo<span>*</span>
                  </p>
                  <Input
                    label="Tanggal Mulai"
                    placeholder="Tanggal Mulai"
                    isDate
                    value={promoDetail.start}
                    setValue={(e) => handleChangeDetail("start", e)}
                  />
                </div>
                <Input
                  label="Tanggal Selesai"
                  placeholder="Tanggal Selesai"
                  isDate
                  value={promoDetail.end}
                  setValue={(e) => handleChangeDetail("end", e)}
                  min={promoDetail.start}
                />
              </div>
            </div>
          </div>
          <div className={Styles.content}>
            <div className={Styles.contentHeader}>
              <p>Daftar Kelas Diberikan Promo</p>
              <div>
                <CheckBox isChecked={selectAll} onClick={handleSelectAll} />
                <span>Gunakan untuk semua kelas</span>
              </div>
            </div>
            <div className={Styles.contentWrapper}>
              {courses.map((item, index) => (
                <ClassCardInput
                  key={index}
                  value={item}
                  handleDelete={() =>
                    setCourses((prev) =>
                      prev.filter((el) => el._id !== item._id)
                    )
                  }
                  handleChangeDetail={(key, value) =>
                    handleChangeCoursesData(index, key, value)
                  }
                  data={item}
                  classList={classList}
                  loading={loadingClass}
                  courses={courses}
                  isEdit={isEdit}
                />
              ))}
              <div className={Styles.addClass}>
                <Button
                  startIcon={"add-circle"}
                  text={"Tambah Kelas"}
                  variant="text"
                  onClick={() =>
                    setCourses((prev) => [...prev, { _id: Math.random() }])
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={Styles.modalAction}>
          <div className={Styles.deleteButton}>
            <Button
              text={"Hapus Promo"}
              variant="outlined-danger"
              startIcon={"trash1"}
              onClick={() => setOpenConfirmDelete(true)}
            />
          </div>
          <Button
            text={"Batal"}
            variant="outlined"
            onClick={onClose}
            disabled={loadingSubmit}
          />
          <Button
            text={loadingSubmit ? "Menyimpan..." : "Simpan"}
            onClick={handleCreatePromo}
            disabled={loadingSubmit}
          />
        </div>
      </div>
      <ModalConfirmDelete
        isOpen={openConfirmDelete}
        header={"Hapus Promo"}
        body={`Apakah anda yakin ingin menghapus promo ini "\t${promoDetail.name}\t"?`}
        onClose={() => setOpenConfirmDelete(false)}
        onConfirm={handleDeletePromo}
        confirmButton={"Ya, Hapus Promo"}
        cancelButton={"Batal"}
      />
    </Modal>
  );
}

function ClassCardInput({
  data,
  handleDelete = () => {},
  handleChangeDetail = () => {},
  classList,
  loading,
  courses,
  isEdit,
  ...props
}) {
  return (
    <div className={Styles.ClassCardInput} {...props}>
      <div className={Styles.imageWrapper}>
        {data?.asset?.thumbnail?.imageURL ? (
          <ImagePreview
            src={fileBaseUrl + data?.asset?.thumbnail?.imageURL}
            alt="thumbnail"
            className={Styles.thumbnail}
          />
        ) : (
          <div className={Styles.imagePlaceholder}>
            <Icon icon={"image1"} size={20} />
          </div>
        )}
      </div>
      <div className={Styles.inputWithLabel}>
        <p className={Styles.label}>
          Nama Kelas<span>*</span>
        </p>
        <Input
          label="Nama Kelas"
          placeholder="Masukan Nama Kelas"
          isDropdown
          value={data.title}
          setValue={(e) => handleChangeDetail("title", e)}
          isLoadingDropdown={loading}
          disabled={data?.disableEdit}
          dropdownOptions={classList
            .filter(
              (course) =>
                course._id === data._id ||
                !courses.some((filter) => course._id === filter._id)
            )
            .map((el) => el.title)}
        />
      </div>
      {isEdit && (
        <div className={Styles.inputWithLabel} style={{ width: "116px" }}>
          <p className={Styles.label}>Kuota Terpakai</p>
          <Input
            label="Kuota Terpakai"
            placeholder="Masukan Kuota Terpakai"
            value={data?.used || 0}
            disabled
          />
        </div>
      )}
      <div className={Styles.quotaInput}>
        <div className={Styles.inputWithLabel}>
          <p className={Styles.label}>Jumlah Kuota Promo</p>
          <Input
            placeholder="Jumlah kuota promo"
            value={data.quota}
            setValue={(e) => handleChangeDetail("quota", e)}
            isNumber
            disabled={data.noQuota}
          />
        </div>
        <div>
          <CheckBox
            isChecked={data.noQuota}
            onClick={() => {
              handleChangeDetail("noQuota", !data.noQuota);
            }}
          />
          <span>Kuota Promo Tanpa Batas</span>
        </div>
      </div>
      <div>
        <Icon
          icon={"trash1"}
          color={"#B22A12"}
          size={24}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
}
