import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import Modal from "@Atom/Modal";

export default function ModalSuccess({
  isOpen,
  onConfirm,
  header,
  loading,
  confirmButton,
  children,
}) {
  return (
    <Modal isOpen={isOpen}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <Icon icon={"checklist"} size={24} color={"#60AA15"}/>
          <p>{header}</p>
        </div>
        <div className={Styles.contentWrapper}>{children}</div>
        <div className={Styles.actionWrapper}>
          <Button disabled={loading} text={confirmButton} onClick={onConfirm} variant="outlined"/>
        </div>
      </div>
    </Modal>
  );
}
