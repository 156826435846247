import React, { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import Pagination from "@Atom/Pagination";
import Button from "@Atom/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { ListArticleSelection, ListCardArticle } from "..";
import Skeleton, { BoxSkeleton } from "@Molecule/Skeleton";
import moment from "moment";
import { fileBaseUrl } from "@Config/index";
import { capitalizeWords } from "@Helpers/capitallizeWords";
import useWindowSize from "@Hooks/useWindowSize";
import useQuery from "@Hooks/useQuery";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { getAllArticleCategories } from "@Services/articles";
import _ from "lodash";

export default function MainArticleLayout({ data, isLoader = false }) {
  const query = useQuery();
  const { width } = useWindowSize();
  const refDown = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [page, setPage] = useState(1);
  const [listCategories, setListCategories] = useState([]);
  const [activeCategories, setActiveCategories] = useState(["Semua Artikel"]);
  const articleDescription = useMemo(() => {
    return data?.mostViewedArticles?.map((a) => ({
      id: a?._id,
      isNewest: false,
      category: a?.category,
      updatedAt: moment(a?.updatedAt).format("DD MMMM YYYY"),
      image: fileBaseUrl + a?.banner?.url,
      title: a?.name,
      description: a?.content,
    }));
  }, [data?.mostViewedArticles]);

  const dataArticlePaginations = useMemo(() => {
    return data?.pagination?.map((a) => ({
      id: a?._id,
      isNewest: false,
      category: a?.category,
      updatedAt: moment(a?.updatedAt).format("DD MMMM YYYY"),
      image: fileBaseUrl + a?.banner?.url,
      title: a?.name,
      description: a?.content,
    }));
  }, [data?.pagination]);

  const handleSelectedFilter = (v) => {
    setPage(1);
    const all = "Semua Artikel";
    const lenght1 = activeCategories.length === 1;

    if (lenght1 && activeCategories[0] === v) return setActiveCategories([all]);
    if (v === all) return setActiveCategories([all]);
    if (activeCategories[0] === all && lenght1 && v !== all)
      setActiveCategories((prevC) => prevC.filter((c) => c !== all));
    if (activeCategories.find((f) => f === v))
      setActiveCategories((prevC) => prevC.filter((f) => f !== v));
    else setActiveCategories((prevC) => [...prevC, v]);
  };

  const categoryQuery = activeCategories
    .map(
      (c) => `category=${encodeURIComponent(c === "Semua Artikel" ? "" : c)}`
    )
    .join("&");

  useEffect(() => {
    navigate(`${pathname}?query=article&page=${page}&${categoryQuery}`, {
      replace: true,
    });
  }, [page, navigate, pathname, categoryQuery]);

  const backToTop = () => {
    const yOffset = -100;
    const y =
      refDown.current.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({
      top: y,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (+query.get("page") !== page) {
      backToTop();
    }
  }, [page, query]);

  useEffect(() => {
    const fetchDataCategories = async () => {
      try {
        const { data } = await getAllArticleCategories();
        const dataCategories = data?.map((c) => c?.name);
        setListCategories(["Semua Artikel", ...dataCategories]);
      } catch (error) {
        console.log("Error get data category article", error);
      }
    };
    fetchDataCategories();
  }, []);

  const isLoadingRefetchPagination = useMemo(() => {
    const categoryQuery = query.getAll("category")?.filter((cq) => cq !== "");
    const dataActiveCategories = activeCategories.filter(
      (ac) => ac !== "Semua Artikel"
    );
    if (
      +query.get("page") !== page ||
      !_.isEqual(categoryQuery, dataActiveCategories)
    )
      return true;
    else return false;
  }, [activeCategories, page, query]);

  const PaginationRendering = () => (
    <Pagination
      setSelectedPage={setPage}
      selectedPage={page}
      isV2
      totalPage={data?.totalPage}
      siblingCount={width < 500 ? 0 : 1}
    />
  );

  return (
    <section className={Styles.sectionMainArticlePage}>
      <div className={Styles.header}>
        <h1>Artikel Skills.id</h1>
        <p>
          Temukan beragam artikel penuh inspirasi, tips, dan wawasan menarik
          untuk Anda.
        </p>
        <img src={Images.RIGHT_CLAY} className={Styles.rightClay} alt="clay" />
        <Icon
          role="button"
          icon={"chevron-right"}
          className={Styles.iconActive}
          size={50}
          color={"#fff"}
          onClick={() => backToTop()}
        />
      </div>
      <div className={Styles.mainContain}>
        <div className={Styles.highlightArticle}>
          {isLoader ? (
            <BoxSkeleton h="470px" />
          ) : (
            <div className={Styles.articleNewest}>
              <img
                src={fileBaseUrl + data?.newestArticles[0]?.banner?.url}
                alt={"main-article"}
              />
              <div className={Styles.shadowInsetImg} />
              <div className={Styles.descriptionContent}>
                <div className={Styles.category}>
                  <div className={Styles.name}>
                    <span>
                      {capitalizeWords(data?.newestArticles[0]?.category)}
                    </span>
                  </div>
                  <div className={`${Styles.newest} ${Styles.name} `}>
                    <span>Terbaru</span>
                  </div>
                  <div className={Styles.name}>
                    <Icon icon={"calendar1"} color={"fff"} size={"16px"} />
                    <span>
                      {moment(data?.newestArticles[0]?.updatedAt).format(
                        "DD MMMM YYYY"
                      )}
                    </span>
                  </div>
                </div>
                <h4>{data?.newestArticles[0]?.name}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data?.newestArticles[0]?.content,
                  }}
                />
                <Button
                  variant="neutral-outlined"
                  text={"Baca Selengkapnya"}
                  className={Styles.btnMore}
                  endIcon={"arrow-up-right"}
                  onClick={() =>
                    navigate(`/article/${data?.newestArticles[0]?._id}`)
                  }
                />
              </div>
            </div>
          )}
          <aside>
            {data?.newestArticles
              ?.slice(1, data?.newestArticles.length)
              .map((a, idx) =>
                isLoader ? (
                  <React.Fragment key={"load-box-" + idx}>
                    <BoxSkeleton h={width < 500 ? "100%" : "231px"} w="100%" />
                  </React.Fragment>
                ) : (
                  <div
                    key={a?._id}
                    onClick={() => navigate(`/article/${a?._id}`)}
                  >
                    <img src={fileBaseUrl + a?.banner?.url} alt={a?.name} />
                    <div className={Styles.wrapperText}>
                      <div className={Styles.shadowInsetImg} />
                      <div className={Styles.category}>
                        <div className={`${Styles.newest} ${Styles.name} `}>
                          <span>{capitalizeWords(a?.category)}</span>
                        </div>
                        <div className={Styles.name}>
                          <Icon
                            icon={"calendar1"}
                            color={"fff"}
                            size={"16px"}
                          />
                          <span>
                            {moment(a?.updatedAt).format("DD MMMM YYYY")}
                          </span>
                        </div>
                      </div>
                      <strong>{a?.name}</strong>
                    </div>
                  </div>
                )
              )}
          </aside>
        </div>
        <div className={Styles.listArticles} ref={refDown}>
          <div className={Styles.left}>
            <div className={Styles.headerListBetween}>
              <h1>Artikel Kami</h1>
              {isLoader ? (
                <Skeleton w="200px" />
              ) : (
                <span>{data?.pagination?.length} Artikel ditampilkan</span>
              )}
            </div>
            <div className={Styles.filterArticle}>
              {listCategories?.map((f, idx) => (
                <div
                  key={idx}
                  className={`${Styles.tab} ${isLoader && Styles.isLoad} `}
                  is-selected={activeCategories
                    ?.some((active) => active === f && !isLoader)
                    ?.toString()}
                  onClick={() => (isLoader ? void {} : handleSelectedFilter(f))}
                >
                  <p>{` ${idx === 0 ? "🔥" : ""} ${f}`}</p>
                </div>
              ))}
            </div>
            <div className={Styles.cards}>
              {(isLoadingRefetchPagination
                ? Array.from({ length: 6 })
                : dataArticlePaginations
              )?.map((a, idx) => (
                <ListCardArticle
                  l={a}
                  key={idx}
                  isLoader={isLoader || isLoadingRefetchPagination}
                />
              ))}
            </div>
            {activeCategories.length >= 1 && data?.pagination?.length === 0 && (
              <TableEmptyHandler
                title="Data Tidak Ditemukan"
                desription="Kategori Artikel yang anda cari saat ini belum tersedia"
              />
            )}
            {width <= 1024 && (
              <div className={Styles.wrapPagination}>
                <PaginationRendering />
              </div>
            )}
          </div>
          <div className={Styles.rightSticky}>
            <h4>Artikel Pilihan</h4>
            {articleDescription
              ?.filter((ar) => !ar?.isNewest)
              ?.map((a) => (
                <ListArticleSelection key={a?.id} a={a} isLoader={isLoader} />
              ))}
          </div>
        </div>
        <div className={Styles.wrapPagination}>
          {width > 1024 && <PaginationRendering />}
        </div>
      </div>
    </section>
  );
}
