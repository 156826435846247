import { Outlet, useLocation } from "react-router-dom";
import "./App.scss";
import "moment/locale/id";
import Topbar from "@Molecule/Topbar";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.focus();
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <Topbar>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            maxWidth: 500,
          },
        }}
      />
      <ScrollToTop />
      <Outlet />
    </Topbar>
    // <div className="App">
    //   <main>
    //     <Outlet />
    //   </main>
    // </div>
  );
}

export default App;
