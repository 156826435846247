import React, { useMemo } from "react";
import Styles from "./style.module.scss";
import { ListArticleSelection, ListCardArticle } from "..";
import Icon from "@Atom/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton, { BoxSkeleton } from "@Molecule/Skeleton";
import moment from "moment";
import { fileBaseUrl } from "@Config/index";
import { capitalizeWords } from "@Helpers/capitallizeWords";
import Images from "@Theme/Images";
import toast from "react-hot-toast";
export default function DetailArticleLayout({ data, isLoader = false }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const articleDescription = useMemo(() => {
    return data?.mostViewedArticles?.map((a) => ({
      id: a?._id,
      isNewest: false,
      category: capitalizeWords(a?.category),
      updatedAt: moment(a?.updatedAt).format("DD MMMM YYYY"),
      image: fileBaseUrl + a?.banner?.url,
      title: capitalizeWords(a?.name),
      description: a?.content,
    }));
  }, [data]);

  const otherArticles = useMemo(() => {
    return data?.articles.map((a, idx) => ({
      id: a?._id,
      isNewest: idx === 0,
      category: capitalizeWords(a?.category),
      updatedAt: moment(a?.updatedAt).format("DD MMMM YYYY"),
      image: fileBaseUrl + a?.banner?.url,
      title: capitalizeWords(a?.name),
      description: a?.content,
    }));
  }, [data]);

  const shareLink = (platform) => {
    let shareUrl = "";
    const currentUrl = window.location.origin + pathname;

    // Mengambil meta data yang diperlukan (judul dan gambar artikel)
    const title = data?.detail?.name || "Default Title";
    const description =
      data?.detail?.content?.substring(0, 200) || "Default Description";

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://wa.me/?text=${encodeURIComponent(currentUrl)}`;
        break;
      case "telegram":
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "line":
        shareUrl = `https://line.me/R/share?url=${encodeURIComponent(
          currentUrl
        )}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          currentUrl
        )}&quote=${encodeURIComponent(description)}`;
        break;
      case "x":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          title
        )}&url=${encodeURIComponent(currentUrl)}`;
        break;
      case "link":
        navigator.clipboard
          .writeText(currentUrl)
          .then(() => {
            toast.success("Link berhasil disalin!");
          })
          .catch((error) => {
            toast.error(" Link gagal disalin!!");
          });
        break;
      default:
        break;
    }

    if (shareUrl && platform !== "link") {
      window.open(shareUrl, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <>
      <div className={Styles.wrapperDetailArticleLayout}>
        <div className={Styles.topBetweenMenu}>
          <div className={Styles.breadCrumbs}>
            <Icon
              icon={"home"}
              color={"#000"}
              size={"20"}
              className={Styles.iconHome}
              onClick={() => navigate("/")}
            />
            <Icon icon={"chevron-right"} size={"20"} />
            <span onClick={() => navigate("/article")}>Daftar Artikel</span>
            <Icon icon={"chevron-right"} size={"20"} />
            {isLoader ? (
              <div>
                <Skeleton w="300px" />
              </div>
            ) : (
              <span>{data?.detail?.name}</span>
            )}
          </div>
          <div className={Styles.shareLink}>
            <img
              src={Images.WHATSAPP}
              alt="whatsapp"
              onClick={() => shareLink("whatsapp")}
            />
            <img
              src={Images.TELEGRAM}
              alt="telegram"
              onClick={() => shareLink("telegram")}
            />
            <img
              src={Images.LINE}
              alt="line"
              onClick={() => shareLink("line")}
            />
            <img
              src={Images.FACEBOOK}
              alt="facebook"
              onClick={() => shareLink("facebook")}
            />
            <img src={Images.X} alt="twitter" onClick={() => shareLink("x")} />
            <img
              src={Images.LINK}
              alt="link"
              onClick={() => shareLink("link")}
            />
          </div>
        </div>
        <section className={Styles.mainDetailArticle}>
          {isLoader ? (
            <BoxSkeleton h="497px" />
          ) : (
            <img
              src={fileBaseUrl + data?.detail?.banner?.url}
              alt="article-images"
            />
          )}
          <div className={Styles.viewerRow}>
            <div className={Styles.wrapText}>
              <div className={Styles.titleAndDate}>
                {isLoader ? (
                  <Skeleton h="40px" />
                ) : (
                  <h2>{data?.detail?.name}</h2>
                )}
                {isLoader ? (
                  <Skeleton w="200px" />
                ) : (
                  <div>
                    <Icon icon={"calendar2"} color={"#ADB5BD"} size={"20"} />
                    <span>
                      {moment(data?.detail?.updatedAt).format(
                        "DD MMMM YYYY HH:MM"
                      )}
                    </span>
                  </div>
                )}
              </div>
              {isLoader ? (
                <div
                  className={Styles.description}
                  style={{
                    display: "flex",
                    rowGap: "4px",
                    flexDirection: "column",
                  }}
                >
                  {Array.from({ length: 12 }).map((_, idx) => (
                    <Skeleton
                      key={idx}
                      w={
                        idx === 4 ? "70%" : idx > 3 && idx < 8 ? "50%" : "100%"
                      }
                    />
                  ))}
                </div>
              ) : (
                <p
                  id="allowed_underline"
                  className={Styles.textRenderer}
                  dangerouslySetInnerHTML={{
                    __html: data?.detail?.content,
                  }}
                />
              )}
            </div>
            <div className={Styles.articleSelectionSticky}>
              <h2>Artikel Pilihan</h2>
              {articleDescription
                ?.filter((ar) => !ar?.isNewest)
                ?.map((a) => (
                  <ListArticleSelection
                    key={a?.id + "list"}
                    a={a}
                    isLoader={isLoader}
                  />
                ))}
            </div>
          </div>
          <div className={Styles.otherArticles}>
            <h1>Baca Artikel Lainnya</h1>
            <div className={Styles.wrapCards}>
              {otherArticles?.map((l, idx) => (
                <ListCardArticle l={l} isLoader={isLoader} key={idx} />
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
