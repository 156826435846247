import MainArticleLayout from "@Organism/Article/Main";
import { getAllArticles } from "@Services/articles";
import React, { Suspense, useEffect } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const page = params?.get("page") || 1;
  const categories = [];
  params?.forEach((value, key) => {
    if (key === "category") {
      categories.push(value);
    }
  });

  return defer({
    getDataAllArticles: getAllArticles(page, 10, categories),
  });
}

export default function MainArticlePage() {
  const { getDataAllArticles } = useLoaderData();
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);
  return (
    <Suspense fallback={<MainArticleLayout isLoader />}>
      <Await
        resolve={getDataAllArticles}
        errorElement={<h1>Error get data</h1>}
      >
        {({ data }) => <MainArticleLayout data={data} />}
      </Await>
    </Suspense>
  );
}
