import Styles from "./style.module.scss";
import IconTableEmptyHandler from "@Assets/Images/tableEmpptyHandler.png";
export default function TableEmptyHandler({
  title = "Empty",
  desription = "There is no data available at the moment",
  children = null,
}) {
  return (
    <div className={Styles.container}>
      <div className={Styles.wrapImage}>
        <img
          src={IconTableEmptyHandler}
          alt="empty-icon"
          className={Styles.emptyIcon}
        />
      </div>
      <div className={Styles.description}>
        <h3>{title}</h3>
        <span>{desription}</span>
      </div>
      {children}
    </div>
  );
}
