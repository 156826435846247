import React, { useEffect, useState } from "react";

export default function ImagePreview({ src, ...props }) {
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, [src]);

  return (
    <img
      {...props}
      alt="Preview"
      onError={() => setImageError(true)}
      src={imageError ? "https://demofree.sirv.com/nope-not-here.jpg?w=150" : src}
    />
  );
}
