import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import WaveTsunami from "@Assets/Icon/wave-tsunami";
import ImgLightQuestion from "@Assets/Images/question-light-img.png";
import useWindowSize from "@Hooks/useWindowSize";
import ImgWwaveTsunami from "@Assets/Images/wave-element-skills-for-education.png";
export default function MainFeatureSkillsEducation() {
  const { width } = useWindowSize();
  const dataCarousel = useMemo(() => {
    return [
      {
        id: "01",
        title: "Sistem Belajar Online",
        description: "Sekolah dapat mengakses kelas dimanapun dan kapanpun",
        image: Images.IMG_CAROUSEL_1_SKILLS_EDUCATION,
      },
      {
        id: "02",
        title: "Tracking Progress Belajar Siswa",
        description:
          "Guru dan bagian administrasi bisa melakukan pengecekan Progress belajar siswa dengan efisien",
        image: Images.IMG_CAROUSEL_2_SKILLS_EDUCATION,
      },
      {
        id: "03",
        title: "Penilaian dan Penerbitan Rapor",
        description:
          "Pihak sekolah bisa dengan mudah memberikan nilai dan penerbitan rapor siswa",
        image: Images.IMG_CAROUSEL_3_SKILLS_EDUCATION,
      },
      {
        id: "04",
        title: "Manajemen Sekolah",
        description:
          "Pihak sekolah dapat menambahkan kelas dan administrasi lainnya dengan mudah melalui LMS SKills.id",
        image: Images.IMG_CAROUSEL_4_SKILLS_EDUCATION,
      },
    ];
  }, []);

  const dataStepsForSchool = useMemo(() => {
    return [
      {
        title: "Hubungi Tim Kami",
        description:
          "Isi form hubungi kami pada halaman ini, dan tunggu tim kami untuk menghubungi Anda kembali.",
      },
      {
        title: "Demo Aplikasi",
        description:
          "Tim kami akan melakukan demo aplikasi kepada Anda, pada tahap ini kita juga bisa saling melakukan diskusi terkait kebutuhan sekolah Anda.",
      },
      {
        title: "Persetujuan Akhir",
        description:
          "Bila anda merasa cocok dan ingin menggunakan aplikasi dari skills.id, tim kami akan memberikan penawaran untuk Anda.",
      },
    ];
  }, []);

  const [activeCarousel, setActiveCarousel] = useState(dataCarousel[0]);

  const nextCarouselItem = () => {
    const currentIndex = dataCarousel.findIndex(
      (item) => item.id === activeCarousel.id
    );
    const nextIndex = (currentIndex + 1) % dataCarousel.length;
    setActiveCarousel(dataCarousel[nextIndex]);
  };

  useEffect(() => {
    const interval = setInterval(nextCarouselItem, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCarousel, dataCarousel]);

  return (
    <div className={Styles.wrapperConditionPrakerja}>
      <div className={Styles.wrapperText}>
        <h1>
          Fitur Utama <span>Skills.id for Education</span>
        </h1>
      </div>
      <div className={Styles.carouselImg}>
        <div className={Styles.left}>
          {dataCarousel.map((c) => (
            <div
              onClick={() => setActiveCarousel(c)}
              className={`${Styles.list} ${
                c.id === activeCarousel?.id && Styles.active
              }`}
              key={c.id}
            >
              <h4>{c.id}</h4>
              <h2>{c.title}</h2>
              {c.id === activeCarousel.id && <p>{c.description}</p>}
            </div>
          ))}
        </div>
        <div className={Styles.right}>
          <img src={activeCarousel.image} alt={activeCarousel.title} />
        </div>
      </div>
      <div className={Styles.borderWave}>
        {width <= 768 ? (
          <WaveTsunami className={Styles.wave} isGradient isMobile />
        ) : (
          <img
            src={ImgWwaveTsunami}
            // style={{ width: "1280px", height: "346px" }}
            alt=""
            className={Styles.newWave}
          />
          // <svg
          //   id="wave"
          //   viewBox="0 0 1440 490"
          //   version="1.1"
          //   xmlns="http://www.w3.org/2000/svg"
          //   className={Styles.wave}
          // >
          //   <defs>
          //     <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
          //       <stop stop-color="rgb(140, 197, 222)" offset="0%"></stop>
          //       <stop stop-color="rgb(140, 197, 222)" offset="100%"></stop>
          //     </linearGradient>
          //   </defs>
          //   <path
          //     fill="url(#sw-gradient-0)"
          //     d="M0,196L48,171.5C96,147,192,98,288,122.5C384,147,480,245,576,277.7C672,310,768,278,864,277.7C960,278,1056,310,1152,277.7C1248,245,1344,147,1440,122.5C1536,98,1632,147,1728,187.8C1824,229,1920,261,2016,261.3C2112,261,2208,229,2304,196C2400,163,2496,131,2592,138.8C2688,147,2784,196,2880,236.8C2976,278,3072,310,3168,326.7C3264,343,3360,343,3456,351.2C3552,359,3648,376,3744,343C3840,310,3936,229,4032,163.3C4128,98,4224,49,4320,73.5C4416,98,4512,196,4608,269.5C4704,343,4800,392,4896,351.2C4992,310,5088,180,5184,179.7C5280,180,5376,310,5472,375.7C5568,441,5664,441,5760,441C5856,441,5952,441,6048,441C6144,441,6240,441,6336,424.7C6432,408,6528,376,6624,343C6720,310,6816,278,6864,261.3L6912,245L6912,490L6864,490C6816,490,6720,490,6624,490C6528,490,6432,490,6336,490C6240,490,6144,490,6048,490C5952,490,5856,490,5760,490C5664,490,5568,490,5472,490C5376,490,5280,490,5184,490C5088,490,4992,490,4896,490C4800,490,4704,490,4608,490C4512,490,4416,490,4320,490C4224,490,4128,490,4032,490C3936,490,3840,490,3744,490C3648,490,3552,490,3456,490C3360,490,3264,490,3168,490C3072,490,2976,490,2880,490C2784,490,2688,490,2592,490C2496,490,2400,490,2304,490C2208,490,2112,490,2016,490C1920,490,1824,490,1728,490C1632,490,1536,490,1440,490C1344,490,1248,490,1152,490C1056,490,960,490,864,490C768,490,672,490,576,490C480,490,384,490,288,490C192,490,96,490,48,490L0,490Z"
          //   ></path>
          //   <defs>
          //     <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
          //       <stop stop-color="rgb(140, 197, 222)" offset="0%"></stop>
          //       <stop stop-color="rgb(140, 197, 222)" offset="100%"></stop>
          //     </linearGradient>
          //   </defs>
          //   <path
          //     fill="url(#sw-gradient-1)"
          //     d="M0,294L48,253.2C96,212,192,131,288,89.8C384,49,480,49,576,106.2C672,163,768,278,864,334.8C960,392,1056,392,1152,383.8C1248,376,1344,359,1440,367.5C1536,376,1632,408,1728,359.3C1824,310,1920,180,2016,171.5C2112,163,2208,278,2304,310.3C2400,343,2496,294,2592,277.7C2688,261,2784,278,2880,269.5C2976,261,3072,229,3168,253.2C3264,278,3360,359,3456,383.8C3552,408,3648,376,3744,367.5C3840,359,3936,376,4032,326.7C4128,278,4224,163,4320,138.8C4416,114,4512,180,4608,212.3C4704,245,4800,245,4896,228.7C4992,212,5088,180,5184,196C5280,212,5376,278,5472,318.5C5568,359,5664,376,5760,318.5C5856,261,5952,131,6048,114.3C6144,98,6240,196,6336,212.3C6432,229,6528,163,6624,155.2C6720,147,6816,196,6864,220.5L6912,245L6912,490L6864,490C6816,490,6720,490,6624,490C6528,490,6432,490,6336,490C6240,490,6144,490,6048,490C5952,490,5856,490,5760,490C5664,490,5568,490,5472,490C5376,490,5280,490,5184,490C5088,490,4992,490,4896,490C4800,490,4704,490,4608,490C4512,490,4416,490,4320,490C4224,490,4128,490,4032,490C3936,490,3840,490,3744,490C3648,490,3552,490,3456,490C3360,490,3264,490,3168,490C3072,490,2976,490,2880,490C2784,490,2688,490,2592,490C2496,490,2400,490,2304,490C2208,490,2112,490,2016,490C1920,490,1824,490,1728,490C1632,490,1536,490,1440,490C1344,490,1248,490,1152,490C1056,490,960,490,864,490C768,490,672,490,576,490C480,490,384,490,288,490C192,490,96,490,48,490L0,490Z"
          //   ></path>
          //   <defs>
          //     <linearGradient id="sw-gradient-2" x1="0" x2="0" y1="1" y2="0">
          //       <stop stop-color="rgb(104, 102, 216)" offset="0%"></stop>
          //       <stop stop-color="rgb(125, 102, 216)" offset="10%"></stop>
          //     </linearGradient>
          //   </defs>
          //   <path
          //     style={{ transform: `translate(0, 100px); opacity:0.8` }}
          //     fill="url(#sw-gradient-2)"
          //     d="M0,392L48,375.7C96,359,192,327,288,334.8C384,343,480,392,576,343C672,294,768,147,864,73.5C960,0,1056,0,1152,8.2C1248,16,1344,33,1440,89.8C1536,147,1632,245,1728,285.8C1824,327,1920,310,2016,261.3C2112,212,2208,131,2304,122.5C2400,114,2496,180,2592,196C2688,212,2784,180,2880,212.3C2976,245,3072,343,3168,392C3264,441,3360,441,3456,424.7C3552,408,3648,376,3744,302.2C3840,229,3936,114,4032,98C4128,82,4224,163,4320,179.7C4416,196,4512,147,4608,163.3C4704,180,4800,261,4896,269.5C4992,278,5088,212,5184,171.5C5280,131,5376,114,5472,147C5568,180,5664,261,5760,261.3C5856,261,5952,180,6048,187.8C6144,196,6240,294,6336,318.5C6432,343,6528,294,6624,277.7C6720,261,6816,278,6864,285.8L6912,294L6912,490L6864,490C6816,490,6720,490,6624,490C6528,490,6432,490,6336,490C6240,490,6144,490,6048,490C5952,490,5856,490,5760,490C5664,490,5568,490,5472,490C5376,490,5280,490,5184,490C5088,490,4992,490,4896,490C4800,490,4704,490,4608,490C4512,490,4416,490,4320,490C4224,490,4128,490,4032,490C3936,490,3840,490,3744,490C3648,490,3552,490,3456,490C3360,490,3264,490,3168,490C3072,490,2976,490,2880,490C2784,490,2688,490,2592,490C2496,490,2400,490,2304,490C2208,490,2112,490,2016,490C1920,490,1824,490,1728,490C1632,490,1536,490,1440,490C1344,490,1248,490,1152,490C1056,490,960,490,864,490C768,490,672,490,576,490C480,490,384,490,288,490C192,490,96,490,48,490L0,490Z"
          //   ></path>
          //   <defs>
          //     <linearGradient id="sw-gradient-3" x1="0" x2="0" y1="1" y2="0">
          //       <stop stop-color="rgb(125, 102, 216)" offset="0%"></stop>
          //       <stop stop-color="rgb(125, 102, 216)" offset="100%"></stop>
          //     </linearGradient>
          //   </defs>
          //   <path
          //     // style="transform:translate(0, 150px); opacity:0.7"
          //     fill="url(#sw-gradient-3)"
          //     d="M0,147L48,122.5C96,98,192,49,288,32.7C384,16,480,33,576,49C672,65,768,82,864,89.8C960,98,1056,98,1152,106.2C1248,114,1344,131,1440,163.3C1536,196,1632,245,1728,236.8C1824,229,1920,163,2016,114.3C2112,65,2208,33,2304,57.2C2400,82,2496,163,2592,163.3C2688,163,2784,82,2880,65.3C2976,49,3072,98,3168,122.5C3264,147,3360,147,3456,187.8C3552,229,3648,310,3744,326.7C3840,343,3936,294,4032,277.7C4128,261,4224,278,4320,294C4416,310,4512,327,4608,277.7C4704,229,4800,114,4896,81.7C4992,49,5088,98,5184,171.5C5280,245,5376,343,5472,359.3C5568,376,5664,310,5760,253.2C5856,196,5952,147,6048,163.3C6144,180,6240,261,6336,318.5C6432,376,6528,408,6624,408.3C6720,408,6816,376,6864,359.3L6912,343L6912,490L6864,490C6816,490,6720,490,6624,490C6528,490,6432,490,6336,490C6240,490,6144,490,6048,490C5952,490,5856,490,5760,490C5664,490,5568,490,5472,490C5376,490,5280,490,5184,490C5088,490,4992,490,4896,490C4800,490,4704,490,4608,490C4512,490,4416,490,4320,490C4224,490,4128,490,4032,490C3936,490,3840,490,3744,490C3648,490,3552,490,3456,490C3360,490,3264,490,3168,490C3072,490,2976,490,2880,490C2784,490,2688,490,2592,490C2496,490,2400,490,2304,490C2208,490,2112,490,2016,490C1920,490,1824,490,1728,490C1632,490,1536,490,1440,490C1344,490,1248,490,1152,490C1056,490,960,490,864,490C768,490,672,490,576,490C480,490,384,490,288,490C192,490,96,490,48,490L0,490Z"
          //   ></path>
          // </svg>
        )}
        <div className={Styles.viewRowBetween}>
          <img
            src={ImgLightQuestion}
            alt="question-img"
            className={Styles.imgQuestion}
          />
          <div className={Styles.left}>
            <h2>Ingin Menggunakan Skills.id Untuk Sekolah Anda?</h2>
            <span>Ikuti langkah berikut ini :</span>
          </div>
          <div className={Styles.right}>
            {dataStepsForSchool.map((l, idx) => (
              <div className={Styles.viewColWithDivider} key={idx}>
                <div className={Styles.divider}></div>
                <div className={Styles.title}>
                  <div>
                    <span>{idx + 1}</span>
                  </div>
                  <h4>{l.title}</h4>
                </div>
                <p>{l.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
