import ModalConfirmDelete from "@Molecule/ModalConfirmDelete";
import { deleteArticle } from "@Services/articles";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function ModalDeleteBlog({ title, open, handleClose,callback=() => {} }) {
  const navigate = useNavigate();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const handleDeleteArticle = async () => {
    try {
      setLoadingDelete(true);
      await deleteArticle(open);
      setLoadingDelete(false);
      toast.success(`Artikel ${title} telah berhasil dihapus`);
      navigate("/admin/blog");
      handleClose()
      callback()
    } catch (error) {
      setLoadingDelete(false);
      console.log(error);
    }
  };
  return (
    <ModalConfirmDelete
      isOpen={open}
      header={"Hapus Artikel"}
      body={`Apakah kamu yakin ingin menghapus artikel “${title}”`}
      onClose={handleClose}
      onConfirm={handleDeleteArticle}
      confirmButton={"Ya, Hapus Artikel"}
      cancelButton={"Batal"}
      loading={loadingDelete}
    />
  );
}
