import React from "react";
import Styles from "./style.module.scss";
import BackgroundDoodle from "@Molecule/BackgroundDoodle";
import FooterLandingPage from "@Molecule/FooterLandingPage";
import ContactLandingPage from "@Molecule/ContactMeLandingPage";
import ImageGallerySkillsEducation from "@Molecule/GallerySkillsEducation";
import DescribeSkillsEducation from "@Molecule/GallerySkillsEducation/DescribeSkillsEducation";
import MainFeatureSkillsEducation from "@Molecule/GallerySkillsEducation/MainFeature";
import FormContactUs from "@Molecule/GallerySkillsEducation/FormContactUs";
export default function LayoutLandingSkillsEducation() {
  return (
    <div className={Styles.containerLandingPagePrakerja}>
      <ImageGallerySkillsEducation />
      <DescribeSkillsEducation />
      <BackgroundDoodle>
        <MainFeatureSkillsEducation />
      </BackgroundDoodle>
      <FormContactUs />
      <FooterLandingPage />
      <ContactLandingPage />
    </div>
  );
}
