import FooterLandingPage from "@Molecule/FooterLandingPage";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Skeleton, { BoxSkeleton } from "@Molecule/Skeleton";
import { capitalizeWords } from "@Helpers/capitallizeWords";

export default function ArticleLayout() {
  return (
    <article id="fullWidthContain">
      <Outlet />
      <FooterLandingPage />
    </article>
  );
}

export const ListArticleSelection = ({
  a,
  isLandingPage = false,
  isLoader = false,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${Styles.wrapDescription} ${Styles[isLandingPage]} `}
      key={`list-articles-${a?.id}`}
      onClick={() => navigate("/article/" + a?.id)}
    >
      {isLoader ? (
        <BoxSkeleton h="100px" />
      ) : (
        <img src={a?.image} alt={a?.title} />
      )}
      {isLoader ? (
        <div
          style={{
            display: "flex",
            rowGap: "4px",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Skeleton w="40%" />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        <div className={Styles.descriptionContent}>
          <div className={Styles.category}>
            <div className={Styles.name}>
              <span>{capitalizeWords(a?.category)}</span>
            </div>
            <div className={Styles.name}>
              <Icon icon={"calendar1"} color={"fff"} size={"16px"} />
              <span>{a?.updatedAt}</span>
            </div>
          </div>
          <h4>{a?.title}</h4>
          <p dangerouslySetInnerHTML={{ __html: a?.description }} />
        </div>
      )}
    </div>
  );
};

export const ListCardArticle = ({ l, isLoader = false }) => {
  const navigate = useNavigate();
  return (
    <div
      className={Styles.card}
      key={l?.id}
      onClick={() => navigate("/article/" + l?.id)}
    >
      {isLoader ? (
        <BoxSkeleton h="calc(378px - 128px)" />
      ) : (
        <img src={l?.image} alt={l?.title} />
      )}
      {!isLoader && (
        <span className={Styles.category}>{capitalizeWords(l?.category)}</span>
      )}
      {isLoader ? (
        <div className={Styles.viewColSkeleton}>
          <Skeleton w="40%" />
          <Skeleton w="90%" />
          <Skeleton w="70%" />
          <Skeleton w="90%" />
        </div>
      ) : (
        <div className={Styles.wrapText}>
          <div className={Styles.uploadedAt}>
            <Icon icon={"calendar1"} color={"fff"} size={"16px"} />
            <span>{l?.updatedAt}</span>
          </div>
          <span>{l?.title}</span>
          <p dangerouslySetInnerHTML={{ __html: l?.description }} />
          <div className={Styles.btnMore}>
            <span>Baca Selengkapnya</span>
            <Icon icon={"arrow-up-right"} color={"#fff"} size={"16"} />
          </div>
        </div>
      )}
    </div>
  );
};
