import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import toast from "react-hot-toast";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { fileBaseUrl } from "@Config/index";

export default function BlogCard({
  data,
  setOpenDeleteModal,
  setSelectedData,
}) {
  const navigate = useNavigate();

  return (
    <div className={Styles.container} onClick={() => navigate(data._id)}>
      <div className={Styles.cardImage}>
        <img
          alt=""
          src={
            data?.banner?.url
              ? fileBaseUrl + data?.banner?.url
              : "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
          }
        />
      </div>
      <div className={Styles.cardContentWrapper}>
        <div className={Styles.headSection}>
          <p>{data.name}</p>
          <div className={Styles.tagWrapper}>
            <div className={Styles.view}>
              <Icon icon={"remove_red_eye"} size={20} />
              <p>{data.views} View</p>
            </div>
            <div className={Styles.tag} status={data.status}>
              <p>{data.status.toLowerCase()}</p>
            </div>
            <Icon
              icon={"link-square"}
              size={24}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                if (data.status !== "PUBLISH") {
                  toast.error("Kelas belum dipublish");
                } else {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/article/${data._id}`
                  );
                  toast.success("Link berhasil disalin");
                }
              }}
            />
            <Icon
              icon={"trash1"}
              size={24}
              style={{ cursor: "pointer" }}
              color={"#B22A12"}
              onClick={(e) => {
                e.stopPropagation();
                setOpenDeleteModal(data._id);
                setSelectedData(data);
              }}
            />
          </div>
        </div>
        <div className={Styles.dateWrapper}>
          <Icon icon={"calendar-outlined"} size={20} color={"#9E9E9E"} />
          {/* 08 Juli 2023 • 09:42 */}
          <p className={Styles.date}>
            {moment(data.updatedAt).format("DD MMMM YYYY • HH:mm")}
          </p>
          <div className={Styles.tag}>
            <p>{data.category}</p>
          </div>
        </div>
        <div
          className={Styles.textWrapper}
          dangerouslySetInnerHTML={{ __html: data.content }}
        ></div>
      </div>
    </div>
  );
}
