import { useEffect, useRef, useState } from 'react';
import Styles from './style.module.scss';
import Icon from '@Atom/Icon';
import useOutsideClick from '@Hooks/useOutsideClick';

export default function Select({
  value,
  setValue,
  options,
  placeholder = 'Select...',
  className = '',
  width = 'auto',
  menuPosition = 'auto',
  ...props
}) {
  const [expand, setExpand] = useState(false);
  const [position, setPosition] = useState({ left: '0', top: '100%' });
  const boxRef = useRef(null);
  const boxOutsideClick = useOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick && expand) {
      setExpand(false);
    }
  }, [boxOutsideClick, expand]);

  useEffect(() => {
    if (expand && boxRef.current) {
      const rect = boxRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      const spaceRight = window.innerWidth - rect.right;
      const spaceLeft = rect.left;

      let newTop = '100%'; // Default: open below
      let newLeft = '0'; // Default: open to the right

      if (menuPosition === 'vertical') {
        if (spaceBelow < 150 && spaceAbove > spaceBelow) {
          newTop = 'auto';
        }
      } else if (menuPosition === 'horizontal') {
        newTop = '0';
        if (spaceRight < 150 && spaceLeft > spaceRight) {
          newLeft = 'auto';
        }
      } else if (menuPosition === 'diagonal' || menuPosition === 'auto') {
        if (spaceBelow < 150 && spaceAbove > spaceBelow) {
          newTop = 'auto';
        }
        if (spaceRight < 150 && spaceLeft > spaceRight) {
          newLeft = 'auto';
        }
      }

      setPosition({ left: newLeft, top: newTop });
    }
  }, [expand, menuPosition]);

  return (
    <div 
      {...props} 
      ref={boxRef} 
      style={{ width }} 
      className={`${Styles.container} ${className}`} 
      onClick={() => setExpand(!expand)}
    >
      <span>{value || placeholder}</span>
      <Icon className={expand ? Styles.rotate : ''} icon="keyboard_arrow_down" size={18} />

      {expand && (
        <div 
          className={Styles.optionsWrapper} 
          style={{ 
            top: position.top, 
            bottom: position.top === 'auto' ? '100%' : 'auto', 
            left: position.left, 
            right: position.left === 'auto' ? '0' : 'auto' 
          }}
        >
          {options?.map((o, oI) => (
            <div 
              key={oI} 
              onClick={() => { 
                setValue(o); 
                setExpand(false); 
              }} 
            >
              {o}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
