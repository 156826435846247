import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import { useNavigate, useParams } from "react-router-dom";
import Searchbar from "@Atom/Searchbar";
import Table from "@Molecule/Table";
import moment from "moment";
import {
  fetchInstructorClassAssesment,
  fetchInstructorClassProgress,
} from "@Services/instructor";
import { getCourse } from "@Services/course";
import _ from "lodash";

const ExpandComponent = ({ type, data }) => {
  switch (type) {
    case "tpm":
      return (
        <div className={Styles.expandComponentContainer}>
          <div />
          <div className={Styles.leftSection}>
            <p>Pre-Test</p>
            {data.tpm.map((el, idx) => (
              <p key={idx}>TPM {idx + 1}</p>
            ))}
            <p>Post-Test</p>
          </div>
          <div className={Styles.rightSection}>
            <div
              className={Styles.tpmTagExpand}
              status={data.preTest !== 0 ? "true" : "none"}
            >
              <div>
                <p>{data.preTest || "100"}</p>
              </div>
            </div>
            {data.tpm.map((el, idx) => (
              <div
                className={Styles.tpmTagExpand}
                status={el ? "true" : "none"}
                key={idx}
              >
                <div>
                  <p>{el || "100"}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      );

    default:
      return (
        <div className={Styles.expandComponentContainer}>
          <div />

          <div className={Styles.leftSection}>
            <p>Email</p>
            <p>Batch</p>
            <p>Tgl Pembelian</p>
          </div>
          <div className={Styles.rightSection}>
            <p className={Styles.text}>{data.email}</p>
            <p className={Styles.text}>{data.batch}</p>
            <p className={Styles.text}>
              {moment(new Date(data?.createdAt)).format("ll")}
            </p>
          </div>
        </div>
      );
  }
};

export default function InstructorActivityDetail() {
  const [tab, setTab] = useState("progress");
  const [table, setTable] = useState("0");
  const navigate = useNavigate();
  // const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [datas, setDatas] = useState([]);
  const { id } = useParams();
  const [assesmentData, setAssesmentData] = useState([]);
  const [classDetail, setClassDetail] = useState();

  // console.log(datas);

  useEffect(() => {
    const getClassDetail = async () => {
      try {
        const { data } = await getCourse(id);
        setClassDetail(data);
      } catch (error) {}
    };
    getClassDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getDataProgress = async () => {
      const fetchAllStatus = [1, 2, 3].map((status) =>
        fetchInstructorClassProgress(id, status, limit, page)
      );
      const res = await Promise.all(fetchAllStatus);
      if (res.some((el) => !el.status)) return;

      setDatas(res.map((el) => el.data));
    };
    const getDataAssesment = async () => {
      const fetchAllStatus = ["tpm", "uk"].map((status) =>
        fetchInstructorClassAssesment(id, status, limit, page)
      );
      const res = await Promise.all(fetchAllStatus);
      if (res.some((el) => !el.status)) return;

      setAssesmentData(res.map((el) => el.data));
    };
    if (tab === "progress") {
      getDataProgress();
    } else {
      getDataAssesment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, page, limit]);

  const tableTab = useMemo(() => {
    switch (tab) {
      case "progress":
        return [
          {
            type: "0",
            text: "Sudah Selesai",
            value: datas[0]?.totalData ? datas[0]?.totalData : 0,
          },
          {
            type: "1",
            text: "Belum Selesai",
            value: datas[1]?.totalData ? datas[1]?.totalData : 0,
          },
          {
            type: "2",
            text: "Tidak Hadir",
            value: datas[2]?.totalData ? datas[2]?.totalData : 0,
          },
        ];
      default:
        return [
          {
            type: "tpm",
            text: "Penilaian Tugas Kelas",
            value: assesmentData[0]?.totalData,
          },
          {
            type: "uk",
            text: "Uji Keterampilan",
            value: assesmentData[1]?.totalData,
          },
        ];
    }
  }, [tab, datas, assesmentData]);

  const legend = [
    {
      type: "Sudah Dinilai",
      color: "#60AA15",
    },
    {
      type: "Belum Dinilai",
      color: "#B22A12",
    },
    {
      type: "Belum Tersedia",
      color: "#C2C2C2",
    },
  ];

  const tpmCount = assesmentData[0]?.data[0]?.tpm || [];

  const progressTemplate = useMemo(() => {
    return {
      data: datas[+table]?.data,
      columns: [
        {
          name: "name",
          //   width: width > 768 ? "455px" : "230px",
          title: "Nama Peserta",
        },
        {
          name: "email",
          title: "Email",
          hideOnMobile: true,
        },
        {
          name: "batch",
          title: "Batch",
          hideOnMobile: true,
        },
        {
          name: "",
          title: "Tgl. Pembelian",
          renderData: (row) => (
            <span>{moment(new Date(row?.transactionDate)).format("ll")}</span>
          ),
          hideOnMobile: true,
        },
        {
          name: "progress",
          title: "Progress",
        },
      ],
      totalPage: datas[+table]?.totalPage,
    };
  }, [datas, table]);

  const template = useMemo(() => {
    return {
      tpm: {
        data: assesmentData[0]?.data,
        columns: [
          {
            name: "name",
            //   width: width > 768 ? "455px" : "230px",
            title: "Nama Peserta",
            renderData: (row) => (
              <div
                onClick={() =>
                  classDetail?.platform !== "OFFLINE" &&
                  navigate(`${row._id}/tpm?name=${row.name}`)
                }
                style={{
                  cursor:
                    classDetail?.platform !== "OFFLINE" ? "pointer" : "default",
                }}
              >
                <p>{row.name}</p>
              </div>
            ),
          },
          {
            name: "",
            //   width: width > 768 ? "455px" : "230px",
            title: "Pre-Test",
            hideOnMobile: true,

            renderData: (row) => (
              <div
                className={Styles.tpmTag}
                status={row.preTest !== -1 ? "true" : "none"}
              >
                <div>
                  <p>{`${row.preTest}` || "100"}</p>
                </div>
              </div>
            ),
          },
          ...tpmCount.map((el, idx) => ({
            name: "",
            title: "TPM " + (idx + 1),
            hideOnMobile: true,
            renderData: (row) => (
              <div
                className={Styles.tpmTag}
                status={
                  _.get(row, ["tpm", idx], null) === null
                    ? "none"
                    : _.get(row, ["tpm", idx], "") === 0
                    ? "false"
                    : "true"
                }
              >
                <div>
                  <p>{_.get(row, ["tpm", idx], "100") || "100"}</p>
                </div>
              </div>
            ),
          })),

          {
            name: "",
            //   width: width > 768 ? "455px" : "230px",
            title: "Post-Test",
            hideOnMobile: true,

            renderData: (row) => (
              <div
                className={Styles.tpmTag}
                status={row?.postTest !== -1 ? "true" : "none"}
              >
                <div>
                  <p>{`${row?.postTest}` || "100"}</p>
                </div>
              </div>
            ),
          },
        ],
        totalPage: assesmentData[0]?.totalPage,
      },
      uk: {
        data: assesmentData[1]?.data,
        columns: [
          {
            name: "name",
            //   width: width > 768 ? "455px" : "230px",
            title: "Nama Peserta",
            renderData: (row) => (
              <div
                onClick={() =>
                  navigate(`${row._id}/skilltest?name=${row.name}`)
                }
                style={{ cursor: "pointer" }}
              >
                <p>{row.name}</p>
              </div>
            ),
          },
          {
            name: "email",
            //   width: width > 768 ? "455px" : "230px",
            title: "Email",
            hideOnMobile: true,
          },
          {
            name: "",
            //   width: width > 768 ? "455px" : "230px",
            title: "Status",
            renderData: (row) => (
              <div
                className={Styles.skillTestTag}
                status={
                  row.status === "No submission"
                    ? "notFound"
                    : row.status === 0
                    ? "notAssessed"
                    : "assessed"
                }
              >
                <p>
                  {row.status === "No submission"
                    ? "Belum Tersedia"
                    : row.status === 0
                    ? "Belum Dinilai"
                    : "Sudah Dinilai"}
                </p>
              </div>
            ),
          },
        ],
        totalPage: assesmentData[1]?.totalPage,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas, assesmentData, page, limit, tpmCount]);

  console.log(progressTemplate);

  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbsWrapper}>
        <div className={Styles.backIcon} onClick={() => navigate(-1)}>
          <Icon icon={"chevron-left"} size={16} />
        </div>
        <div className={Styles.breadcrumb}>
          <p>Aktifitas</p>
          <Icon icon={"chevron-right"} size={16} color={"#3E4856"} />
          <p>Detail Kelas</p>
        </div>
      </div>
      <div className={Styles.tabHeader}>
        <Button
          variant="tab"
          text={"Progress Murid"}
          isActive={tab === "progress"}
          onClick={() => {
            setTab("progress");
            setTable("0");
          }}
        />
        <Button
          variant="tab"
          text={"Penilaian Tugas"}
          isActive={tab === "task"}
          onClick={() => {
            setTab("task");
            setTable("tpm");
          }}
        />
      </div>
      <div className={Styles.mainTable}>
        <div className={Styles.tableTabWrapper}>
          {tableTab.map((el, idx) => (
            <Button
              variant="table-tab"
              text={el.text}
              isActive={el.type === table}
              onClick={() => setTable(el.type)}
              key={idx}
              dataLength={el.value}
            />
          ))}
        </div>
        <div className={Styles.searchbarWrapper}>
          <Searchbar placeholder="Cari Peserta" />
        </div>
        <div className={Styles.tableSection}>
          {table === "tpm" && (
            <div className={Styles.legendWrapper}>
              {legend.map((el, idx) => (
                <div className={Styles.legend} key={idx}>
                  <div
                    className={Styles.icon}
                    style={{ backgroundColor: el.color }}
                  />
                  <p>{el.type}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        {tab === "progress" ? (
          <Table
            data={progressTemplate.data}
            totalData={progressTemplate.data?.length}
            totalPage={progressTemplate.totalPage}
            columns={progressTemplate.columns}
            page={page}
            setPage={setPage}
            className={`${Styles.table} ${
              !progressTemplate.data?.length ? Styles.grow : ""
            }`}
            limit={limit}
            setLimit={setLimit}
            expandComponent={(row) => (
              <ExpandComponent data={row} type={table} />
            )}
            withPagination
          />
        ) : (
          <React.Fragment>
            {table === "tpm" ? (
              <Table
                data={template?.tpm?.data}
                totalData={template?.tpm?.data?.length}
                totalPage={template?.tpm?.totalPage}
                columns={template?.tpm?.columns}
                page={page}
                setPage={setPage}
                className={`${Styles.table} ${
                  !template?.tpm?.data?.length ? Styles.grow : ""
                }`}
                limit={limit}
                setLimit={setLimit}
                expandComponent={(row) => (
                  <ExpandComponent data={row} type={table} />
                )}
                withPagination
              />
            ) : (
              <Table
                data={template?.uk?.data}
                totalData={template?.uk?.data?.length}
                totalPage={template?.uk?.totalPage}
                columns={template?.uk?.columns}
                page={page}
                setPage={setPage}
                className={`${Styles.table} ${
                  !template?.uk?.data?.length ? Styles.grow : ""
                }`}
                limit={limit}
                setLimit={setLimit}
                expandComponent={(row) => (
                  <ExpandComponent data={row} type={table} />
                )}
                withPagination
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
