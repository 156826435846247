import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../useDebounce";
import { getAdminArticleList } from "@Services/articles";

export default function useAllBlog() {
  const [activeTab, setActiveTab] = useState("");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const deboucedSearch = useDebounce(search, 500);
  const [data, setData] = useState([]);
  const containerRef = useRef();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getAdminArticleList({
        page,
        search,
        status: activeTab,
      });
      const data = res.data;
      setTotalPage(Math.ceil(res.totalData / 5));
      if (page === 1) {
        setData(data);
      } else {
        setData((prev) => [...prev, ...data]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  console.log(isLoading);

  const refetchData = () => {
    setData([])
    setPage(1);
    fetchData();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, page, deboucedSearch]);

  useEffect(() => {
    setPage((prev) => 1);
    setData([]);
  }, [activeTab, deboucedSearch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading) {
          if (page < totalPage) {
            setPage((p) => p + 1);
          }
        }
      },
      { threshold: 1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, [page, totalPage, isLoading]);

  return {
    activeTab,
    setActiveTab,
    search,
    setSearch,
    isLoading,
    data,
    containerRef,
    withFilter: search,
    refetchData,
    setData,
  };
}
