import Modal from "@Atom/Modal";
import { fileBaseUrl } from "@Config/index";
import ClassesDesc from "@Molecule/ClassesDesc";
import FilePreview from "@Molecule/FilePreview";
import MaterialDesc from "@Molecule/MaterialDesc";
import ModalPopUpQuiz from "@Molecule/ModalPopUpQuiz";
import PreTestHeader from "@Molecule/PreTestHeader";
import UploadFileSection from "@Molecule/UploadFileSection";
import {
  postAnswerMatter,
  postAnswerQuiz,
  postAnswerTpm,
  postCompletionDate,
} from "@Services/userSheet";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import QuizModal from "../QuizModal";
import Styles from "./styles.module.scss";
import FileUploadSuccess from "@Molecule/FileUploadSuccessModal";
import ThumbnailClassVideo from "@Molecule/ThumbnailVideoClass";
import PreTestWarningInformation from "@Molecule/PreTestWarningInformation";
import { translateError } from "@Helpers/translateError";
import moment from "moment";
import NextPrevButton from "@Molecule/NextPrevButton";
import useQuery from "@Hooks/useQuery";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import { checkPrakerjaStatus } from "@Services/prakerja";
import ReedemCodeModal from "@Molecule/ReedemCodeModal";
import Images from "@Theme/Images";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import ModalResultScore from "@Molecule/ModalScore";

export default function Material({ data, userSheet, schedule }) {
  //
  const [issOpenModalScore, setIsOpenModalScore] = useState(false);
  //
  const { materialCode } = useParams();
  const materialStr = materialCode.split("-")[1];
  const chapterStr = materialCode.split("-")[0];
  const chapterNumber = Number(chapterStr.split("chapter")[1]);
  const materialNumber = Number(materialStr?.split("material")[1]);
  const materialData = useMemo(() => {
    return data.sessions[chapterNumber - 1]["subjects"][materialNumber - 1];
  }, [chapterNumber, data.sessions, materialNumber]);
  const chapterData = useMemo(() => {
    return data.sessions[chapterNumber - 1];
  }, [chapterNumber, data.sessions]);
  const [openQuizModal, setOpenQuizModal] = useState(false);
  const progressData = useMemo(() => {
    return userSheet.sessions[chapterNumber - 1]["subjects"][
      materialNumber - 1
    ];
  }, [chapterNumber, materialNumber, userSheet.sessions]);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [popupAnswer, setPopupAnswer] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const { pathname } = useLocation();
  const [redeemCode, setRedeemCode] = useState("");
  const user = decryptStorageData("user", localStorage);
  const [finishUploadFile, setFinishUploadFile] = useState(false);
  const [errorUploadFile, setErrorUploadFile] = useState(false);
  const query = useQuery();
  const title = query.get("t");
  const [openDisableSession, setOpenDisableSession] = useState(false);
  const [errorRedeem, setErrorRedeem] = useState(false);
  // const [finishRedeem, setFinishRedeem] = useState(false);
  const [prakerjaLink, setPrakerjaLink] = useState("");
  const [getScore, setGetScore] = useState(0);
  const [loadingRedeem, setLoadingRedeem] = useState(false);
  const withNoPostTest =
    data.configurations.find((el) => el.label === "postTest")?.value ===
    "false";
  const withNoSkillTest =
    data.configurations.find((el) => el.label === "skillTest")?.value ===
    "false";

  const handleSubmitQuiz = async (answer) => {
    const dataToSend = {
      userSheetID: userSheet._id,
      sessionID: chapterData._id,
      subjectNum: materialNumber,
      quiz: Object.values(answer).map((el, idx) => ({
        quizNum: Object.keys(answer)[idx],
        answer: el,
      })),
    };
    // const index = [];
    // const answerArr = Object.values(answer);
    // const optionsArr = materialData.quizzes.map((el) => el.answerOptions);
    // for (let i = 0; i < answerArr.length; i++) {
    //   const answer = answerArr[i];
    //   for (let j = 0; j < optionsArr.length; j++) {
    //     const option = optionsArr[j].map((el) => el.value);
    //     if (option.includes(answer)) {
    //       index.push(j);
    //     }
    //   }
    // }
    // const dataToSend = {
    //   userSheetID: userSheet._id,
    //   sessionID: chapterData._id,
    //   subjectNum: materialNumber,
    //   quiz: Object.values(answer).map((el, idx) => ({
    //     quizNum: index[idx] + 1,
    //     answer: el,
    //   })),
    // };

    try {
      const res = await postAnswerQuiz(dataToSend);
      if (res.status) {
        // setOpenQuizModal(false);
        setGetScore(res?.score);
        setIsOpenModalScore(true);
        navigate("", { replace: true });
      }
    } catch (error) {}
  };
  const handleSubmitPopup = async () => {
    const dataToSend = {
      userSheetID: userSheet._id,
      sessionID: chapterData._id,
      subjectNum: materialNumber,
      quiz: [
        {
          quizNum: 1,
          answer: popupAnswer || false,
        },
      ],
    };
    try {
      const res = await postAnswerQuiz(dataToSend);
      if (res.status) {
        setOpenQuizModal(false);
        navigate("", { replace: true });
      }
    } catch (error) {}
  };

  const handleSubmitTpm = async (e) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024;

    if (file && file.size < maxSize) {
      const dataToSend = {
        userSheetID: userSheet._id,
        sessionID: chapterData._id,
        subjectNum: materialNumber,
        file: file,
      };
      try {
        const res = await postAnswerTpm(dataToSend);
        if (res.status) {
          navigate("", { replace: true });
          setFinishUploadFile(true);
        }
      } catch (error) {
        setErrorUploadFile(translateError(error.response.data.error));
      }
    } else {
      setErrorUploadFile(
        "File terlalu besar. Batas maksimum untuk unggahan adalah 5 MB. Harap periksa ukuran file Anda dan coba lagi."
      );
    }
  };

  const handleSubmitMatter = async () => {
    const dataToSend = {
      userSheetID: userSheet._id,
      sessionID: chapterData._id,
      subjectNum: materialData.num,
      // file: e.target.files[0],
    };
    try {
      const res = await postAnswerMatter(dataToSend);
      if (res.status) {
        navigate("", { replace: true });
      }
    } catch (error) {}
  };

  const redeem = (e) => {
    // token, sequence,redeemCode,email,class_id
    setLoadingRedeem(true);
    checkPrakerjaStatus({
      sequence: String(chapterNumber),
      redeem_code: redeemCode,
      email: user.email,
      courseID: data._id,
    })
      .then((res) => {
        // dispatch({
        //   type: SET_ALERT,
        //   payload: { status: res.status, message: res.message },
        // });
        // setOpenModal(false);
        setLoadingRedeem(false);

        if (res.status) {
          localStorage.setItem(
            "prakerjaPayload",
            JSON.stringify({
              sequence: chapterNumber,
              redeem_code: redeemCode,
              email: user.email,
              courseID: materialData._id,
            })
          );
          localStorage.setItem("callbackTo", pathname);
          // setSuccessModal(true);
          if (res?.data?.attendance_status === 1) {
            setOpenModal(false);
          }
          if (res.data.url) {
            setPrakerjaLink(res.data.url);
            window.open(res.data.url);
          }
        } else {
          setOpenModal(false);
        }
      })
      .catch((e) => {
        // setOpenModal(false)
        setLoadingRedeem(false);
        setErrorRedeem(
          e.response.data?.message || "Kode Redeem Tidak Ditemukan"
        );
      });
  };

  useEffect(() => {
    if (
      materialNumber === 1 &&
      data.platform !== "OFFLINE" &&
      data.platform !== "WORKSHOP" &&
      chapterNumber !== 1 &&
      data.type === "PRAKERJA"
    ) {
      const data = JSON.parse(localStorage.getItem("prakerjaPayload"));
      if (data) {
        setRedeemCode(data.redeem_code);
        checkPrakerjaStatus(data)
          .then((res) => {
            if (res?.data?.attendance_status === 1) {
              setOpenModal(false);
            } else {
              setOpenModal(true);
            }
          })
          .catch((e) => {
            setOpenModal(true);
          });
      } else {
        setOpenModal(true);
      }
    } else {
      setOpenModal(false);
    }
  }, [chapterNumber, data.platform, data.type, materialNumber]);

  useEffect(() => {
    if (
      materialNumber !== 1 &&
      userSheet.sessions[chapterNumber - 1].subjects[materialNumber - 2]
        ?.isComplete &&
      !userSheet.sessions[chapterNumber - 1].subjects[materialNumber - 1]
        ?.isComplete
    ) {
      if (
        materialData?.content?.type === "DOCUMENT" &&
        materialData.type === "MATTER" &&
        materialData?.content?.type !== "VIDEO"
      ) {
        handleSubmitMatter();
      }
    }
    if (
      materialNumber === 1 &&
      !userSheet.sessions[chapterNumber - 1].subjects[materialNumber - 1]
        ?.isComplete
    ) {
      if (
        materialData?.content?.type === "DOCUMENT" &&
        materialData.type === "MATTER" &&
        materialData?.content?.type !== "VIDEO"
      ) {
        handleSubmitMatter();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const chapterBeforeComplete = useMemo(() => {
    if (user?.isAssesor) {
      return true;
    }
    if (materialNumber === 1) return true;
    return userSheet.sessions[chapterNumber - 1]["subjects"][materialNumber - 2]
      ?.isComplete;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterNumber, materialNumber]);

  const nextChapterData = useMemo(() => {
    // if (materialNumber === 1) return true;
    return userSheet.sessions[chapterNumber - 1]["subjects"][materialNumber];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterNumber, materialNumber]);

  const activeSessionWebinar = (sIndex) => {
    if (sIndex === 0) {
      return userSheet?.score?.preTest >= 0;
    }
    if (sIndex > 0) {
      if (
        userSheet?.sessions[sIndex - 1]?.subjects
          .filter((el) => el.type !== "TASK")
          .some((el) => !el.isComplete)
      ) {
        return false;
      }
      if (user?.isAssesor) {
        return true;
      }
    }
    if (moment(new Date()).format("YYYY-MM-DD") < schedule[sIndex]?.date) {
      return false;
    } else if (
      moment(new Date()).format("YYYY-MM-DD") === schedule[sIndex]?.date
    ) {
      if (
        schedule[0]?.startHour <=
        new Date().getHours() + 1 + ":" + new Date().getMinutes()
      ) {
        return true;
      }
    }
    return true;
  };

  const activeSessionVideo = (sIndex) => {
    if (sIndex === 0) {
      return !!userSheet.score.preTest;
    }
    if (sIndex > 0) {
      if (!userSheet?.sessions[sIndex - 1]?.completionDate) {
        return false;
      }
      if (user?.isAssesor) {
        return true;
      }
      if (
        userSheet.sessions[sIndex - 1].completionDate &&
        +moment(userSheet.sessions[sIndex - 1].completionDate).format("Do") >=
          +moment().format("Do")
      ) {
        return false;
      }
    }

    return true;
  };

  const finishedAllSession = () => {
    const arr = [];
    for (let i = 0; i < userSheet.sessions.length; i++) {
      const session = userSheet.sessions[i].subjects;
      for (let j = 0; j < session.length; j++) {
        const subject = session[j];
        if (subject.type !== "TASK") {
          arr.push(subject);
        }
        if (subject.type === "TASK") {
          arr.push({
            ...subject,
            isComplete:
              user.isAssesor || data.platform === "WORKSHOP"
                ? true
                : subject.score.overall > 0,
          });
        }
      }
    }
    return arr.every((el) => el.isComplete === true);
  };

  const handleNextButton = () => {
    switch (data.platform) {
      case "WEBINAR":
        if (materialNumber === chapterData.subjects.length) {
          if (chapterNumber === data.sessions.length) {
            if (finishedAllSession()) {
              if (!withNoPostTest) {
                navigate(`/classes/learn/${data._id}/posttest?t=${title}`);
              } else {
                if (!withNoSkillTest) {
                  navigate(`/classes/learn/${data._id}/uk?t=${title}`);
                } else {
                  navigate(`/classes/learn/${data._id}/certificate?t=${title}`);
                }
              }
            } else {
              setOpenDisableSession(true);
            }
          } else {
            if (!activeSessionWebinar(+chapterNumber)) {
              setOpenDisableSession(true);
            } else {
              navigate(
                `/classes/learn/${data._id}/chapter${
                  chapterNumber + 1
                }-material1?t=${title}`
              );
            }
          }
        } else {
          navigate(
            `/classes/learn/${data._id}/chapter${chapterNumber}-material${
              materialNumber + 1
            }?t=${title}`
          );
        }
        break;
      case "WORKSHOP":
        if (materialNumber === chapterData.subjects.length) {
          if (chapterNumber === data.sessions.length) {
            if (finishedAllSession()) {
              if (!withNoPostTest) {
                navigate(`/classes/learn/${data._id}/posttest?t=${title}`);
              } else {
                if (!withNoSkillTest) {
                  navigate(`/classes/learn/${data._id}/uk?t=${title}`);
                } else {
                  navigate(`/classes/learn/${data._id}/certificate?t=${title}`);
                }
              }
            } else {
              setOpenDisableSession(true);
            }
          } else {
            navigate(
              `/classes/learn/${data._id}/chapter${
                chapterNumber + 1
              }-material1?t=${title}`
            );
          }
        } else {
          navigate(
            `/classes/learn/${data._id}/chapter${chapterNumber}-material${
              materialNumber + 1
            }?t=${title}`
          );
        }
        break;
      case "VIDEO":
        if (materialNumber === chapterData.subjects.length) {
          if (chapterNumber === data.sessions.length) {
            if (finishedAllSession() || data.type === "GENERAL") {
              if (!withNoPostTest) {
                navigate(`/classes/learn/${data._id}/posttest?t=${title}`);
              } else {
                if (!withNoSkillTest) {
                  navigate(`/classes/learn/${data._id}/uk?t=${title}`);
                } else {
                  navigate(`/classes/learn/${data._id}/certificate?t=${title}`);
                }
              }
            } else {
              setOpenDisableSession(true);
            }
          } else {
            if (activeSessionVideo(chapterNumber) || data.type === "GENERAL") {
              navigate(
                `/classes/learn/${data._id}/chapter${
                  chapterNumber + 1
                }-material1?t=${title}`
              );
            } else {
              setOpenDisableSession(true);
            }
          }
        } else {
          navigate(
            `/classes/learn/${data._id}/chapter${chapterNumber}-material${
              materialNumber + 1
            }?t=${title}`
          );
        }
        break;
      default:
        if (materialNumber === chapterData.subjects.length) {
          if (chapterNumber === data.sessions.length) {
            navigate(`/classes/learn/${data._id}/posttest?t=${title}`);
          } else {
            if (
              userSheet.sessions[chapterNumber].status !== "CLOSED" &&
              userSheet.score.preTest >= 0
            ) {
              navigate(
                `/classes/learn/${data._id}/chapter${
                  chapterNumber + 1
                }-material1?t=${title}`
              );
            } else {
              setOpenDisableSession(true);
            }
          }
        } else {
          navigate(
            `/classes/learn/${data._id}/chapter${chapterNumber}-material${
              materialNumber + 1
            }?t=${title}`
          );
        }
        break;
    }
  };

  const handlePrevButton = () => {
    if (materialNumber === 1) {
      if (chapterNumber === 1) {
        navigate(`/classes/learn/${data._id}/rules?t=${title}`);
      } else {
        navigate(
          `/classes/learn/${data._id}/chapter${chapterNumber - 1}-material${
            data.sessions[chapterNumber - 2].subjects.length
          }?t=${title}`
        );
      }
    } else {
      navigate(
        `/classes/learn/${data._id}/chapter${chapterNumber}-material${
          materialNumber - 1
        }?t=${title}`
      );
    }
  };

  const nextButtonText = useMemo(() => {
    if (materialNumber === chapterData.subjects.length) {
      if (chapterNumber === data.sessions.length) {
        if (!withNoPostTest) {
          return "Lanjut Post Test";
        } else {
          if (!withNoSkillTest) {
            return "Lanjut Kerjakan Uji Keterampilan";
          } else {
            return "Lihat Sertifikat";
          }
        }
      }
      return `Sesi Selanjutnya`;
    } else {
      if (nextChapterData?.type === "MATTER") {
        return "Materi Selanjutnya";
      } else if (nextChapterData?.type === "QUIZ") {
        return "Lanjut Kuis";
      } else {
        return "Lanjut Kerjakan TPM";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialNumber, chapterNumber]);

  const finishSessionVideo = () => {
    const payload = { sessionNum: chapterNumber };
    try {
      postCompletionDate(payload, userSheet._id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPopupAnswer("");
  }, [chapterNumber, materialNumber]);

  useEffect(() => {
    const progress =
      userSheet.sessions[chapterNumber - 1]["subjects"][
        userSheet.sessions[chapterNumber - 1]["subjects"].length - 1
      ];
    if (
      chapterNumber - 1 ||
      !userSheet.sessions[chapterNumber - 1]["subjects"].completionDate
    ) {
      if (progress?.isComplete) {
        finishSessionVideo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterNumber, materialNumber, userSheet.sessions]);
  // console.log("sskor nya", progressData.score.quiz);
  const modalDisableSession = useMemo(() => {
    return (
      <Modal isOpen={openDisableSession}>
        <div className={Styles.disabledOpenSession}>
          <div className={Styles.modalBody}>
            <p>Sesi Belum Dimulai</p>
            <img src={Images.SESSION_DISABLE} alt="" />
            <p>
              {data.platform === "WEBINAR"
                ? "Sesi belum bisa dibuka, silakan akses 1 jam sebelum pelatihan dimulai (redeem dan verifikasi wajah)"
                : "Sesi belum bisa dibuka, silakan akses di hari berikutnya (redeem dan verifikasi wajah)"}
            </p>
          </div>
          <div className={Styles.modalAction}>
            <Button
              text={"Tutup"}
              onClick={() => setOpenDisableSession(false)}
            />
          </div>
        </div>
      </Modal>
    );
  }, [openDisableSession, data]);

  const handleCloseScore = () => {
    setOpenQuizModal(false);
    setIsOpenModalScore(false);
  };

  switch (materialData?.type) {
    case "MATTER":
      return (
        <div className={Styles.container}>
          {data.platform === "WEBINAR" && (
            <div className={Styles.meetUrl}>
              <p>Link Zoom Meeting</p>
              <div>
                <img src={Images.PC_LAYOUT} alt="" />
                <p
                  onClick={() =>
                    window.open(data?.sessions[chapterNumber - 1]?.meetURL)
                  }
                  className={Styles.link}
                >
                  {data?.sessions[chapterNumber - 1]?.meetURL}
                </p>

                <div>
                  <Icon
                    icon={"copy"}
                    size={24}
                    color={"#9360A8"}
                    onClick={() =>
                      navigator.clipboard.writeText(
                        data.sessions[chapterNumber - 1].meetURL
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <div className={Styles.titleWrapper}>
            <p>{materialData?.title || "MATERI"}</p>
          </div>
          <div className={Styles.filePreviewSection}>
            {chapterBeforeComplete && materialData.content.uri ? (
              <FilePreview
                fileUrl={
                  materialData.content.uri.includes("https")
                    ? materialData.content.uri
                    : fileBaseUrl + materialData.content.uri
                }
                type={materialData.content.type}
                setOpenPopup={setOpenPopup}
                handleSubmit={handleSubmitPopup}
                chapterNumber={chapterNumber}
                isRegularClass={data.type === "GENERAL"}
              />
            ) : (
              <ThumbnailClassVideo onClick={() => navigate(-1)} />
            )}
          </div>
          <NextPrevButton
            prevText={"Sebelumnya"}
            handleNext={handleNextButton}
            nextText={nextButtonText}
            // disabledNext={!nextButtonDisabled()}
            handlePrev={handlePrevButton}
          />
          <div className={Styles.descSection}>
            <MaterialDesc
              desc={materialData.content.description || "-"}
              withAdditional={!!materialData?.additional?.uri}
              additional={materialData?.additional?.uri}
            />
          </div>
          <ModalPopUpQuiz
            isCorrect={popupAnswer === "Benar"}
            isWrong={popupAnswer === "Salah"}
            onClickIsCorrect={() => setPopupAnswer("Benar")}
            onClickIsWrong={() => setPopupAnswer("Salah")}
            isOpen={openPopup}
            question={materialData?.quizzes[0]?.contentHTML}
            answerQuestion={
              materialData?.quizzes[0]?.answerOptions[0]?.isCorrectAnswer
            }
            submitQuiz={handleSubmitPopup}
            onCloseQuiz={() => setOpenPopup(false)}
          />
          {!user?.isAssesor && (
            <ReedemCodeModal
              isOpen={openModal}
              onClose={() => {
                setOpenModal("");
                setErrorRedeem("");
              }}
              value={redeemCode}
              onChange={(e) => setRedeemCode(e.target.value)}
              onSubmit={redeem}
              disabledClose
              isError={errorRedeem}
              setIsError={setErrorRedeem}
              prakerjaLink={prakerjaLink}
              loading={loadingRedeem}
            />
          )}
          {modalDisableSession}
        </div>
      );
    case "QUIZ":
      return (
        <div>
          {!chapterBeforeComplete && (
            <PreTestWarningInformation
              headerText="Kuis Belum Dapat Dikerjakan"
              description="Silahkan selesaikan materi sebelumya terlebih dahulu agar Anda dapat mengerjakan kuis ini."
            />
          )}

          <div className={Styles.container}>
            <div className={Styles.titleWrapper}>
              <p>{materialData?.title || "KUIS"}</p>
            </div>
            <div className={Styles.fileSection}>
              <PreTestHeader
                headerPreTest="Kuis"
                isScore={
                  progressData.score.quiz === -1
                    ? false
                    : `${progressData.score.quiz}`
                }
                score={
                  progressData.score.quiz === -1
                    ? 0
                    : `${progressData.score.quiz}`
                }
                type={"quiz"}
                headerDecription="Silahkan kerjakan soal kuis secara teliti, Berikut beberapa ketentuan yang perlu Anda ketahui sebelum mengerjakan kuis "
                listDescription={[
                  "Minimal skor saat mengerjakan kuis adalah 80",
                  "Anda dapat mengerjakan kuis berulang kali",
                  "Nilai yang ditampilkan adalah nilai tertinggi yang Anda dapatkan",
                ]}
                textButton="Mulai Kuis"
                modalTitle="Mulai Kuis?"
                onClickButtonStart={() => setOpenQuizModal(true)}
                modalDescription={
                  progressData?.score?.quiz !== -1
                    ? `Apakah Anda yakin ingin mengulang Kuis? skor kuis Anda sekarang adalah
                      <span style="color: #9360A8;font-size:16px;font-weight:600;">
                        ${progressData?.score?.quiz}
                      </span>
                        nilai selanjutnya yang akan ditampilkan adalah nilai terbesar yang Anda dapatkan`
                    : "Apakah Anda yakin ingin mengerjakan Kuis sekarang? Anda harus menyelesaikan Kuis hingga selesai dan tidak dapat kembali sebelum Kuis yang Anda kerjakan selesai."
                }
                isLockedQuiz={!chapterBeforeComplete}
                textScore={"Skor Akhir Kuis"}
              />
              <NextPrevButton
                prevText={"Sebelumnya"}
                handleNext={handleNextButton}
                nextText={nextButtonText}
                // disabledNext={!nextButtonDisabled()}
                handlePrev={handlePrevButton}
              />
            </div>
            <div className={Styles.descSection}>
              <ClassesDesc data={data} />
            </div>
            <Modal isOpen={openQuizModal}>
              <QuizModal
                data={materialData}
                handleSubmit={(answer) => handleSubmitQuiz(answer)}
              />
            </Modal>
            <ModalResultScore
              isOpen={issOpenModalScore}
              handleBack={handleCloseScore}
              currentScore={progressData.score.quiz}
              getScore={getScore}
              type={"kuis"}
            />
          </div>
          {modalDisableSession}
        </div>
      );
    default:
      return (
        <div className={Styles.container}>
          <div className={Styles.titleWrapper}>
            <p>Tugas Praktik Mandiri</p>
          </div>
          <div className={Styles.filePreviewSection}>
            <FilePreview
              fileUrl={
                materialData.content.uri.includes("https")
                  ? materialData.content.uri
                  : fileBaseUrl + materialData.content.uri
              }
              type={materialData.content.type}
              enableFastForward={data.type === "GENERAL"}
            />
          </div>
          <NextPrevButton
            prevText={"Sebelumnya"}
            handleNext={handleNextButton}
            nextText={nextButtonText}
            // disabledNext={!nextButtonDisabled()}
            handlePrev={handlePrevButton}
          />
          <div className={Styles.descSection}>
            <MaterialDesc
              withAdditional={false}
              desc={materialData.content.description}
            />
            <UploadFileSection
              file={
                progressData.content.answer
                  ? fileBaseUrl + progressData.content.answer
                  : false
              }
              fileName={progressData.content.answer}
              handleChangeFile={handleSubmitTpm}
            />
          </div>
          {progressData.score.feedback && (
            <div className={Styles.scoreSection}>
              <div className={Styles.sectionTitle}>
                <p>Hasil Tugas Praktek Mandiri</p>
              </div>
              <div className={Styles.sectionBody}>
                <div className={Styles.wrapScoreResult}>
                  <div
                    className={Styles.scoreText}
                    score={progressData.score.overall}
                  ></div>
                </div>
                <div>
                  <p>Feedback</p>
                  <p>{progressData.score.feedback}</p>
                </div>
              </div>
            </div>
          )}
          <Modal isOpen={finishUploadFile}>
            <FileUploadSuccess
              text={"File Tugas Praktek Mandiri berhasil diunggah"}
              onClose={() => setFinishUploadFile(false)}
            />
          </Modal>
          <Modal isOpen={errorUploadFile}>
            <FileUploadSuccess
              text={errorUploadFile}
              onClose={() => setErrorUploadFile(false)}
              status="reject"
              headText="File Gagal Diunggah"
            />
          </Modal>
          {modalDisableSession}
        </div>
      );
  }
  // const content = useMemo(() => {
  //   switch (materialData?.type) {
  //     case "MATTER":
  //       return (
  //         <div className={Styles.container}>
  //           {data.platform === "WEBINAR" && (
  //             <div className={Styles.meetUrl}>
  //               <p>Link Zoom Meeting</p>
  //               <div>
  //                 <img src={Images.PC_LAYOUT} />
  //                 <p
  //                   onClick={() =>
  //                     window.open(data?.sessions[chapterNumber]?.meetURL)
  //                   }
  //                   className={Styles.link}
  //                 >
  //                   {data?.sessions[chapterNumber - 1]?.meetURL}
  //                 </p>

  //                 <div>
  //                   <Icon
  //                     icon={"copy"}
  //                     size={24}
  //                     color={"#9360A8"}
  //                     onClick={() =>
  //                       navigator.clipboard.writeText(
  //                         data.sessions[chapterNumber].meetURL
  //                       )
  //                     }
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //           )}
  //           <div className={Styles.titleWrapper}>
  //             <p>{materialData.title}</p>
  //           </div>
  //           <div className={Styles.filePreviewSection}>
  //             {chapterBeforeComplete && materialData.content.uri ? (
  //               <FilePreview
  //                 fileUrl={
  //                   materialData.content.uri.includes("https")
  //                     ? materialData.content.uri
  //                     : fileBaseUrl + materialData.content.uri
  //                 }
  //                 type={materialData.content.type}
  //                 setOpenPopup={setOpenPopup}
  //                 chapterNumber={chapterNumber}
  //               />
  //             ) : (
  //               <ThumbnailClassVideo onClick={() => navigate(-1)} />
  //             )}
  //           </div>
  //           <NextPrevButton
  //             prevText={"Sebelumnya"}
  //             handleNext={handleNextButton}
  //             nextText={nextButtonText}
  //             // disabledNext={!nextButtonDisabled()}
  //             handlePrev={handlePrevButton}
  //           />
  //           <div className={Styles.descSection}>
  //             <MaterialDesc
  //               desc={materialData.content.description || "-"}
  //               additional={materialData.additional.uri}
  //             />
  //           </div>
  //           <ModalPopUpQuiz
  //             isCorrect={popupAnswer === "Benar"}
  //             isWrong={popupAnswer === "Salah"}
  //             onClickIsCorrect={() => setPopupAnswer("Benar")}
  //             onClickIsWrong={() => setPopupAnswer("Salah")}
  //             isOpen={openPopup}
  //             question={materialData?.quizzes[0]?.contentHTML}
  //             answerQuestion={
  //               materialData?.quizzes[0]?.answerOptions[0]?.isCorrectAnswer
  //             }
  //             submitQuiz={handleSubmitPopup}
  //             onCloseQuiz={() => setOpenPopup(false)}
  //           />
  //           {!!user?.isAssesor && (
  //             <ReedemCodeModal
  //               isOpen={openModal}
  //               onClose={() => setOpenModal("")}
  //               value={redeemCode}
  //               onChange={(e) => setRedeemCode(e.target.value)}
  //               onSubmit={redeem}
  //               disabledClose
  //             />
  //           )}
  //         </div>
  //       );
  //     case "QUIZ":
  //       return (
  //         <div>
  //           {!chapterBeforeComplete && (
  //             <PreTestWarningInformation
  //               headerText="Kuis Belum Dapat Dikerjakan"
  //               description="Silahkan selesaikan materi sebelumya terlebih dahulu agar Anda dapat mengerjakan kuis ini."
  //             />
  //           )}

  //           <div className={Styles.container}>
  //             <div className={Styles.titleWrapper}>
  //               <p>{materialData.title}</p>
  //             </div>
  //             <div className={Styles.fileSection}>
  //               <PreTestHeader
  //                 headerPreTest="Kuis"
  //                 isScore={
  //                   progressData.score.quiz === -1
  //                     ? false
  //                     : `${progressData.score.quiz}`
  //                 }
  //                 score={
  //                   progressData.score.quiz === -1
  //                     ? 0
  //                     : `${progressData.score.quiz}`
  //                 }
  //                 type={"quiz"}
  //                 headerDecription="Silakan kerjakan soal kuis secara teliti, Berikut beberapa ketentuannya : "
  //                 listDescription={[
  //                   "Minimal skor saat mengerjakan kuis adalah 80",
  //                   "Anda dapat mengerjakan kuis berulang kali",
  //                   "Nilai yang ditampilkan adalah nilai tertinggi yang Anda dapatkan",
  //                 ]}
  //                 textButton="Mulai Kuis"
  //                 modalTitle="Mulai Kuis?"
  //                 onClickButtonStart={() => setOpenQuizModal(true)}
  //                 modalDescription="Apakah Anda yakin ingin mengerjakan Kuis sekarang? Anda harus menyelesaikan Kuis hingga selesai dan tidak dapat kembali sebelum Kuis yang Anda kerjakan selesai."
  //                 isLockedQuiz={!chapterBeforeComplete}
  //                 textScore={"Skor Akhir Kuis"}
  //               />
  //               <NextPrevButton
  //                 prevText={"Sebelumnya"}
  //                 handleNext={handleNextButton}
  //                 nextText={nextButtonText}
  //                 // disabledNext={!nextButtonDisabled()}
  //                 handlePrev={handlePrevButton}
  //               />
  //             </div>
  //             <div className={Styles.descSection}>
  //               <ClassesDesc data={data} />
  //             </div>
  //             <Modal isOpen={openQuizModal}>
  //               <QuizModal
  //                 data={materialData}
  //                 handleSubmit={(answer) => handleSubmitQuiz(answer)}
  //               />
  //             </Modal>
  //           </div>
  //         </div>
  //       );
  //     default:
  //       return (
  //         <div className={Styles.container}>
  //           <div className={Styles.titleWrapper}>
  //             <p>Tugas Praktik Mandiri</p>
  //           </div>
  //           <div className={Styles.filePreviewSection}>
  //             <FilePreview
  //               fileUrl={
  //                 materialData.content.uri.includes("https")
  //                   ? materialData.content.uri
  //                   : fileBaseUrl + materialData.content.uri
  //               }
  //               type={materialData.content.type}
  //             />
  //           </div>
  //           <NextPrevButton
  //             prevText={"Sebelumnya"}
  //             handleNext={handleNextButton}
  //             nextText={nextButtonText}
  //             // disabledNext={!nextButtonDisabled()}
  //             handlePrev={handlePrevButton}
  //           />
  //           <div className={Styles.descSection}>
  //             <MaterialDesc
  //               withAdditional={false}
  //               desc={materialData.content.description}
  //             />
  //             <UploadFileSection
  //               file={
  //                 progressData.content.answer
  //                   ? fileBaseUrl + progressData.content.answer
  //                   : false
  //               }
  //               fileName={progressData.content.answer}
  //               handleChangeFile={handleSubmitTpm}
  //             />
  //           </div>
  //           <Modal isOpen={finishUploadFile}>
  //             <FileUploadSuccess
  //               text={"File Tugas Praktek Mandiri berhasil diunggah"}
  //               onClose={() => setFinishUploadFile(false)}
  //             />
  //           </Modal>
  //           <Modal isOpen={errorUploadFile}>
  //             <FileUploadSuccess
  //               text={errorUploadFile}
  //               onClose={() => setErrorUploadFile(false)}
  //               status="reject"
  //               headText="File Gagal Diunggah"
  //             />
  //           </Modal>
  //         </div>
  //       );
  //   }
  // }, [
  //   data,
  //   userSheet,
  //   redeemCode,
  //   finishUploadFile,
  //   errorUploadFile,
  //   openModal,
  //   openPopup,
  //   openQuizModal,
  //   popupAnswer,
  //   chapterBeforeComplete,
  //   progressData
  // ]);

  // return (
  //   <div className={Styles.body}>
  //     {content}
  //     <Modal isOpen={openDisableSession}>
  //       <div className={Styles.disabledOpenSession}>
  //         <div className={Styles.modalBody}>
  //           <p>Sesi Belum Dimulai</p>
  //           <img src={Images.SESSION_DISABLE} alt="" />
  //           <p>
  //             {data.platform === "WEBINAR"
  //               ? "Sesi belum bisa dibuka, silakan akses 1 jam sebelum pelatihan dimulai (redeem dan verifikasi wajah)"
  //               : "Sesi belum bisa dibuka, silakan akses di hari berikutnya (redeem dan verifikasi wajah)"}
  //           </p>
  //         </div>
  //         <div className={Styles.modalAction}>
  //           <Button
  //             text={"Tutup"}
  //             onClick={() => setOpenDisableSession(false)}
  //           />
  //         </div>
  //       </div>
  //     </Modal>
  //   </div>
  // );
}
