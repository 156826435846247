import { API } from "@Config/index";
import toast from "react-hot-toast";

export function getAdminPromoList(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/promo`, {
        params: { ...query },
        headers: {
          Authorization:  localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function _searchClassByTitle(title) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/promo/course`, {
        params: { title },
        headers: {
          Authorization:  localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}
export function _fetchPromo(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/promo/${id}`, {
        headers: {
          Authorization:  localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function _deletePromo(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(`/promo/delete/${id}`, {
        headers: {
          Authorization:  localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
      toast.success("Promo Berhasil Dihapus");
    } catch (error) {
      reject(error);
    }
  });
}

export function _createPromo(body) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/promo/create`, body, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      toast.success("Promo Berhasil Dibuat");
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function _editPromo(id,body) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/promo/edit/${id}`, body, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      toast.success("Promo Berhasil Diubah");
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}
