import React, { useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Button from "@Atom/Button";
import InputField from "@Molecule/InputField";
import OTPField from "@Molecule/OTPField";
import {
  decryptStorageData,
  encryptStorageData,
} from "@Helpers/encryptDecrypt";
import SuccessBadge from "@Assets/Images/success-modal.png";
import {
  changeUserEmail,
  getOtpForVerificationEmail,
  userEmailVerification,
} from "@Services/user";
import { isValidEmail } from "@Helpers/emailValidate";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
export default function ModalVerificationEmail({ onClose = () => {} }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();
  const user = decryptStorageData("user", localStorage);
  const [isLoadingHit, setIsLoadingHit] = useState(false);
  const [isOpenChangeEmail, setIsOpenChangeEmail] = useState(false);

  const [inputEmail, setInputEmail] = useState("");
  const [OTP, setOTP] = useState();
  const HeaderText = <h2>Harap Lakukan Verifikasi Email</h2>;
  const [isSuccessVerify, setIsSuccessVerify] = useState(false);
  const [isErrorVerify, setIsErrorVerify] = useState(false);

  const handleSendOTP = async () => {
    setIsLoadingHit(true);
    try {
      const res = await getOtpForVerificationEmail();
      if (res.status) {
        navigate(`${pathname}?hash=${res?.hash}`);
      }
    } catch (error) {
      console.error("Error send otp email: ", error);
    } finally {
      setIsLoadingHit(false);
    }
  };
  const handleVerifyOTP = async () => {
    setIsLoadingHit(true);
    try {
      const res = await userEmailVerification({
        otp: OTP,
        hash: query.get("hash"),
      });
      if (res.status === 200) {
        setIsSuccessVerify(true);
        navigate(pathname);
      }
    } catch (error) {
      console.log("Error verify OTP email", error);
      setIsErrorVerify(true);
    } finally {
      setIsLoadingHit(false);
    }
  };
  const handleUpdateEmail = async () => {
    setIsLoadingHit(true);
    try {
      const res = await changeUserEmail({ email: inputEmail });
      if (res.status === 200) {
        setIsOpenChangeEmail(false);
        encryptStorageData("user", { ...user, email: inputEmail });
        navigate(`${pathname}?hash=${res?.data?.hash}`);
      }
    } catch (error) {
      console.log("Error update email", error);
    } finally {
      setIsLoadingHit(false);
    }
  };

  return (
    <Modal isOpen>
      <div className={Styles.wrapperModalVerifEmail}>
        {isSuccessVerify ? (
          <div className={Styles.successDialog}>
            <h1>Email Berhasil Diverifikasi</h1>
            <img src={SuccessBadge} alt="successBadge" />
            <Button
              className={Styles.btn}
              text={"Tutup"}
              onClick={() => {
                encryptStorageData("user", { ...user, isEmailVerified: true });
                onClose();
              }}
            />
          </div>
        ) : isOpenChangeEmail ? (
          <div className={Styles.inputEmail}>
            {HeaderText}
            <p>Silahkan masukkan email baru</p>
            <div className={Styles.wrapInputField}>
              <InputField
                placeholder="Masukkan Email Anda"
                isEmail
                value={inputEmail}
                setValue={(e) => setInputEmail(e)}
              />
            </div>
            <Button
              disabled={!isValidEmail(inputEmail) || isLoadingHit}
              text={isLoadingHit ? "Mengkonfirmasi..." : "Konfirmasi"}
              onClick={() => handleUpdateEmail()}
            />
          </div>
        ) : query.get("hash") ? (
          <div className={Styles.inputEmail}>
            {HeaderText}
            <p>Kode OTP telah dikirimkan ke email {user?.email} </p>
            <OTPField
              height="60px"
              isNumberInput
              setValue={setOTP}
              value={OTP}
              className={Styles.inputOTP}
              autoFocus
            />
            <div className={Styles.between}>
              <button
                className={Styles.btnChangeNumber}
                onClick={() => {
                  setIsOpenChangeEmail(true);
                  navigate(pathname);
                }}
              >
                Ganti email
              </button>
              {isErrorVerify && <span>Kode OTP yang anda masukan salah</span>}
            </div>
            <div className={Styles.rowButton}>
              <Button
                text={"Kirim Ulang Kode"}
                className={Styles.btn}
                variant="outlined"
                onClick={() => handleSendOTP()}
              />
              <Button
                disabled={isLoadingHit || !OTP}
                text={isLoadingHit ? "Mengkonfirmasi..." : "Konfirmasi"}
                className={Styles.btn}
                onClick={() => handleVerifyOTP()}
              />
            </div>
          </div>
        ) : (
          <div className={Styles.firstDialog}>
            {HeaderText}
            <p>
              Kamu belum melakukan verifikasi email pada akun ini, mohon lakukan
              verifikasi email terlebih dahulu untuk dapat mengakses akun ini.
            </p>
            <Button
              text={isLoadingHit ? "Mengirim kode OTP..." : "Kirim Kode OTP"}
              disabled={isLoadingHit}
              onClick={() => handleSendOTP()}
            />
          </div>
        )}
      </div>
    </Modal>
  );
}
