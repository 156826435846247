import React from "react";

export default function WaveTsunami({
  isGradient = false,
  color = "#E9F8E8",
  isMobile = false,
  ...props
}) {
  if (isGradient) {
    if (isMobile) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 390 759"
          fill="none"
          {...props}
        >
          <path
            d="M-69 36.1156C-69 36.1156 -4.80469 82.4913 101.414 82.4913C207.633 82.4913 341.695 0 450.492 0C559.289 0 591 36.1156 591 36.1156V759H272H-69V36.1156Z"
            fill="url(#paint0_linear_23831_95538)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_23831_95538"
              x1="-69"
              y1="0"
              x2="675.889"
              y2="95.0965"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#795EC6" />
              <stop offset="1" stop-color="#8CC5DE" />
            </linearGradient>
          </defs>
        </svg>
      );
    } else
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1280"
          height="346"
          viewBox="0 0 1280 346"
          fill="none"
          {...props}
        >
          <path
            d="M0 44C0 44 124.5 100.5 330.5 100.5C536.5 100.5 796.5 0 1007.5 0C1218.5 0 1280 44 1280 44V346H0V44Z"
            fill="url(#paint0_linear_22486_82997)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_22486_82997"
              x1="0"
              y1="0"
              x2="1133.74"
              y2="615.767"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#795EC6" />
              <stop offset="1" stop-color="#8CC5DE" />
            </linearGradient>
          </defs>
        </svg>
      );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="578"
        height="98"
        viewBox="0 0 578 98"
        fill="none"
        {...props}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0L15.895 12.25C32.2717 24.5 64.0617 49 96.3333 63.2917C128.605 77.5833 160.395 81.6667 192.667 69.4167C224.938 57.1667 256.728 28.5833 289 24.5C321.272 20.4167 353.062 40.8333 385.333 55.125C417.605 69.4167 449.395 77.5833 481.667 77.5833C513.938 77.5833 545.728 69.4167 562.105 65.3333L578 61.25V98H562.105C545.728 98 513.938 98 481.667 98C449.395 98 417.605 98 385.333 98C353.062 98 321.272 98 289 98C256.728 98 224.938 98 192.667 98C160.395 98 128.605 98 96.3333 98C64.0617 98 32.2717 98 15.895 98H0V0Z"
          fill={color}
        />
      </svg>
    );
  }
}
