import React from "react";
import style from "./index.module.scss";
export default function Modal({ children, isOpen, onClose = () => { } }) {
  if (!isOpen) {
    return null;
  }
  return (
    // <div>
      isOpen &&
        <div className={style.background}>
          {children}
          <div
            className={style.outside}
            onClick={onClose}
          />

        </div>
      
    // </div>
  );
}
