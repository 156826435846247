import Styles from "./style.module.scss";
import heroImage from "@Assets/Images/hero-skills-education.png";
import heroImageMobile from "@Assets/Images/hero-img-skills-edu-mobile.png";
import useWindowSize from "@Hooks/useWindowSize";
import Button from "@Atom/Button";
import Icon from "@Atom/Icon";
import WaveTsunami from "@Assets/Icon/wave-tsunami";

export default function ImageGallerySkillsEducation() {
  const { width, height } = useWindowSize();

  const listFeatureClass = [
    "Sistem sekolah terpadu",
    "Monitor progress belajar siswa",
    "Pembuatan rapor otomatis",
  ];

  return (
    <div className={Styles.wrapperImageGalleryPrakerja}>
      <WaveTsunami
        className={Styles.wave}
        color="#E9F8E8"
        height={`${height}px`}
        width={`${width}px`}
      />
      <section className={Styles.sectionLeft}>
        <div className={Styles.headerText}>
          <h1>Permudah Kegiatan Belajar Mengajar di Sekolah Dengan</h1>
          <span>Skills For Education</span>
        </div>
        <div className={Styles.listFeatures}>
          {listFeatureClass.map((l, idx) => (
            <div key={idx}>
              <Icon icon={"check-circle"} color={"#7AC61C"} size={"24"} />
              <span key={idx}>{l}</span>
            </div>
          ))}
        </div>
        <div className={Styles.buttonAction}>
          <Button
            text={"Jadwalkan Demo"}
            className={Styles.btn}
            endIcon={"arrow-up-right"}
            onClick={() =>
              document.getElementById("contact-us-form").scrollIntoView({
                behavior: "smooth",
              })
            }
          />
          <Button
            text={"Pelajari Lebih Lanjut"}
            variant="outlined"
            endIcon={"chevron-right"}
            className={`${Styles.lastBtn} ${Styles.btn} `}
            onClick={() =>
              document
                .getElementById("describe-skills-education")
                .scrollIntoView({
                  behavior: "smooth",
                })
            }
          />
        </div>
      </section>
      <section className={Styles.sectionRight}>
        <div className={Styles.borderWrapper}>
          <img
            src={width >= 900 ? heroImage : heroImageMobile}
            alt="hero-images"
            className={Styles.heroImage}
          />
        </div>
      </section>
    </div>
  );
}
