import Modal from "@Atom/Modal";
import React from "react";
import style from "./style.module.scss";
import InputText from "@Atom/InputText";
import Button from "@Atom/Button";
import { useNavigate } from "react-router-dom";
import informationIcon from "@Assets/Icon/infoCircleGradient.svg";
export default function ReedemCodeModal({
  onChange,
  value,
  onSubmit,
  isOpen,
  onClose,
  disabledClose = false,
  isError,
  setIsError,
  prakerjaLink,
  loading = false,
}) {
  const navigate = useNavigate();
  return (
    <div>
      <Modal isOpen={isOpen}>
        <div className={style.container}>
          <div className={style.headerCard}>
            <h1>Reedem Code</h1>
            <p>
              Silahkan masukaan reedem code yang Anda gunakan saat pembelian
              kelas ini untuk dapat melanjutkan
            </p>
          </div>
          <div className={style.mainCard}>
            <InputText
              required={true}
              labelValue="Reedem Code"
              htmlFor={"reedem code"}
              id={"reedem code"}
              placeholder="Reedem Code"
              onChange={(e) => {
                onChange(e);
              }}
              value={value}
              isError={!!isError}
            />
            {!!isError && <p className={style.isError}>{isError}</p>}
          </div>
          <div className={style.informationWrapper}>
            <div>
              <img src={informationIcon} alt="" />
              <div>
                <p className={style.infoText}>
                  {prakerjaLink
                    ? "Silahkan cek tab baru pada browser Anda untuk melakukan verifikasi muka, bila tidak ada tab baru terbuka silahkan salin link dibawah ini untuk membuka halaman verifikasi muka:"
                    : "Silahkan cek tab baru pada browser Anda untuk melakukan verifikasi muka"}
                </p>
                <p
                  className={style.prakerjaLink}
                  hide={!prakerjaLink ? "true" : "false"}
                  onClick={() => navigator.clipboard.writeText(prakerjaLink)}
                >
                  Salin Link
                </p>
              </div>
            </div>
          </div>
          <div className={style.buttonCard}>
            <div
              className={style.buttonBack}
              onClick={
                disabledClose
                  ? () => {
                      navigate(-1);
                    }
                  : onClose
              }
            >
              <span>Kembali</span>
            </div>

            <Button
              onClick={() => {
                onSubmit();
              }}
              disabled={loading || value === ""}
              text={"Submit"}
              textSize="l"
              textWidth="semibold"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
