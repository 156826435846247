import Button from "@Atom/Button";
import Card from "@Atom/Card";
import FormErrorMessage from "@Atom/FormErrorMessage";
import { doesObjectContainEmptyValue } from "@Helpers/doesObjectContainEmptyValue";
import InputField from "@Molecule/InputField";
import OTPField from "@Molecule/OTPField";
import {
  login,
  loginVerification,
  register,
  registerVerification,
} from "@Services/user";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import { encryptStorageData } from "@Helpers/encryptDecrypt";
import { translateError } from "@Helpers/translateError";
import useQuery from "@Hooks/useQuery";

export default function AuthLayout({ type = "" }) {
  const [form, setForm] = useState({});
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [OTPMode, setOTPMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const query = useQuery();
  const callbackQuery = query.get("c");

  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  }, [error, setError]);

  // const query = useQUery

  useEffect(() => {
    if (type === "login") {
      setForm({
        emailOrWhatsAppNumber: "",
      });
    } else {
      setForm({
        name: "",
        email: "",
        whatsAppNumber: "",
      });
    }
  }, [type]);

  const [hash, setHash] = useState();

  const handleSubmit = useCallback(
    async (actionType, isResend) => {
      try {
        setIsSubmitting(true);
        switch (actionType) {
          case "login":
            switch (OTPMode) {
              case false:
                const response = await login(form?.emailOrWhatsAppNumber);
                setHash(response?.hash);
                setOTPMode(true);
                break;
              case true:
                if (!isResend) {
                  const loginResponse = await loginVerification(hash, otp);
                  localStorage.setItem(
                    "accessToken",
                    loginResponse?.access_token
                  );
                  encryptStorageData("user", loginResponse?.user);
                  if (callbackQuery) {
                    navigate(callbackQuery);
                  } else {
                    const isStudent =
                      loginResponse.user?.isInstructor === false &&
                      loginResponse.user?.role === "EXTERNAL";
                    if (isStudent) {
                      navigate("/student");
                    } else {
                      navigate("/");
                    }
                  }
                } else {
                  const response = await login(form?.emailOrWhatsAppNumber);
                  setHash(response?.hash);
                }
                break;
              default:
                break;
            }
            break;
          case "register":
            switch (OTPMode) {
              case false:
                const response = await register(
                  form?.name,
                  form?.email,
                  "62" + form?.whatsAppNumber
                );
                setHash(response?.hash);
                setOTPMode(true);
                break;
              case true:
                if (!isResend) {
                  const registerResponse = await registerVerification(
                    hash,
                    otp
                  );
                  localStorage.setItem(
                    "accessToken",
                    registerResponse?.access_token
                  );
                  encryptStorageData("user", registerResponse?.user);
                  if (callbackQuery) {
                    navigate(callbackQuery);
                  } else {
                    const isStudent =
                      registerResponse.user?.isInstructor === false &&
                      registerResponse.user?.role === "EXTERNAL";
                    if (isStudent) {
                      navigate("/student");
                    } else {
                      navigate("/");
                    }
                  }
                } else {
                  const response = await register(
                    form?.name,
                    form?.email,
                    "62" + form?.whatsAppNumber
                  );
                  setHash(response?.hash);
                }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        setIsSubmitting(false);
      } catch (err) {
        setIsSubmitting(false);
        setError(
          translateError(err?.response.data.error) || "Something went wrong"
        );
      }
    },
    [
      OTPMode,
      callbackQuery,
      form?.email,
      form?.emailOrWhatsAppNumber,
      form?.name,
      form?.whatsAppNumber,
      hash,
      navigate,
      otp,
    ]
  );

  const handleChangeForm = useCallback((name, newVal) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: newVal,
      };
    });
  }, []);

  const timerRef = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = useCallback((e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        // (hours > 9 ? hours : '0' + hours) + ':' +
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  }, []);

  const clearTimer = useCallback(
    (e) => {
      // If you adjust it you should also need to
      // adjust the Endtime formula we are about
      // to code next
      setTimer("00:60");

      // If you try to remove this line the
      // updating of timer Variable will be
      // after 1000ms or 1sec
      if (timerRef.current) clearInterval(timerRef.current);
      const id = setInterval(() => {
        startTimer(e);
      }, 1000);
      timerRef.current = id;
    },
    [startTimer]
  );

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    if (hash) {
      clearTimer(getDeadTime());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const handleResend = useCallback(
    async (e) => {
      e?.preventDefault();
      await handleSubmit(type, true);
      setOtp("");
      clearTimer(getDeadTime());
    },
    [clearTimer, handleSubmit, type]
  );

  const template = useMemo(() => {
    switch (OTPMode) {
      case false:
        switch (type) {
          case "login":
            return {
              title: "Silahkan masuk ke akun Anda",
              form: (
                <form onSubmit={(e) => e?.preventDefault()}>
                  <InputField
                    // title={'Email atau Nomor WhatsApp'}
                    title={"Nomor WhatsApp"}
                    value={form?.emailOrWhatsAppNumber}
                    setValue={(newVal) =>
                      handleChangeForm("emailOrWhatsAppNumber", newVal)
                    }
                    placeholder="Masukkan Email / nomor WhatsApp"
                    // remark={
                    //   <p className={Styles.remark}>
                    //     <Icon icon={'information-solid'} size={20} color={'#F5D38B'} />
                    //     <span>Untuk login menggunakan nomor Whatsapp mohon sertakan, contoh : 628**********</span>
                    //   </p>
                    // }
                    required
                  />
                  <FormErrorMessage message={error} />
                  <Button
                    text={"Login"}
                    type={"submit"}
                    disabled={doesObjectContainEmptyValue(form) || isSubmitting}
                    onClick={() => handleSubmit("login")}
                  />
                </form>
              ),
              footerText: "Belum punya akun?",
              footerButton: (
                <Button
                  className={Styles.switchButton}
                  variant="text"
                  endIcon={"arrow-right-alt"}
                  iconSize={20}
                  text={"Daftar Sekarang"}
                  onClick={() => navigate("/register")}
                />
              ),
            };
          case "register":
            return {
              title: "Silahkan daftar akun Anda di sini",
              form: (
                <form onSubmit={(e) => e?.preventDefault()}>
                  <div className={Styles.fields}>
                    <InputField
                      title={"Nama"}
                      value={form?.name}
                      setValue={(newVal) => handleChangeForm("name", newVal)}
                      placeholder="Masukkan nama anda"
                      readOnly={isSubmitting}
                      required
                    />
                    <InputField
                      title={"Email"}
                      value={form?.email}
                      setValue={(newVal) => handleChangeForm("email", newVal)}
                      placeholder="Masukkan email anda"
                      readOnly={isSubmitting}
                      required
                    />
                    <InputField
                      title={"Nomor WhatsApp"}
                      value={form?.whatsAppNumber}
                      setValue={(newVal) =>
                        handleChangeForm("whatsAppNumber", newVal)
                      }
                      placeholder="Masukkan nomor WhatsApp anda"
                      isPhoneNumber
                      readOnly={isSubmitting}
                      required
                    />
                  </div>
                  <FormErrorMessage message={error} />

                  <Button
                    type="submit"
                    text={isSubmitting ? "Mendaftarkan..." : "Daftar"}
                    disabled={doesObjectContainEmptyValue(form) || isSubmitting}
                    onClick={() => handleSubmit("register")}
                  />
                </form>
              ),
              footerText: "Sudah punya akun?",
              footerButton: (
                <Button
                  onClick={() => navigate("/login")}
                  className={Styles.switchButton}
                  variant="text"
                  endIcon={"arrow-right-alt"}
                  iconSize={20}
                  text={"Masuk sekarang"}
                />
              ),
            };
          default:
            return {};
        }
      case true:
        return {
          title: "Konfirmasi Kode OTP",
          description: `Masukkan Kode OTP yang telah kami kirimkan ke ${
            form?.emailOrWhatsAppNumber || "62" + form?.whatsAppNumber
          }`,
          form: (
            <form onSubmit={(e) => e?.preventDefault()}>
              <OTPField length={6} value={otp} setValue={setOtp} />
              <div className={Styles.resendWrapper}>
                <Button
                  text={"Kirim Ulang Kode"}
                  disabled={timer !== "00:00"}
                  onClick={handleResend}
                  type="button"
                  variant="text"
                />
                <span>{timer}</span>
              </div>
              <FormErrorMessage message={error} />
              <Button
                type="submit"
                text={isSubmitting ? "Mengkonfirmasi..." : "Konfirmasi"}
                disabled={otp?.length !== 6 || isSubmitting}
                onClick={() => handleSubmit(type)}
              />
            </form>
          ),
          footerText: "",
          footerButton: "",
        };
      default:
        return {};
    }
  }, [
    OTPMode,
    error,
    form,
    handleChangeForm,
    handleResend,
    handleSubmit,
    isSubmitting,
    navigate,
    otp,
    timer,
    type,
  ]);

  return (
    <div className={Styles.container}>
      <img src={Images.LEFT_CLAY} alt="" className={Styles.leftClay} />
      <img src={Images.RIGHT_CLAY} alt="" className={Styles.rightClay} />
      <div className={Styles.cardWrapper}>
        <Card className={Styles.card}>
          <img src={Images.SHADOWED_LOGO} alt="" />
          <div className={Styles.main}>
            {OTPMode && (
              <div>
                <Button
                  variant="text"
                  text={"Kembali"}
                  startIcon={"chevron-left"}
                  style={{ padding: 0 }}
                  onClick={() => setOTPMode(false)}
                />
              </div>
            )}
            <div className={Styles.title}>
              <h3>{template?.title}</h3>
              {!!template?.description && <span>{template?.description}</span>}
            </div>
            {template?.form}
            <p>
              <span>{template?.footerText}</span>
              {template?.footerButton}
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
}
