import Styles from "./style.module.scss";

export default function Skeleton({ h = "20px", w = "100%", isV2 = true }) {
  return (
    <div
      className={`${Styles.wrapperLoadSkeleton} ${Styles[isV2]}`}
      style={{ height: h, width: w }}
    ></div>
  );
}
export const CircleSkeleton = ({ h = "20px", w = "20px" }) => {
  return (
    <div
      className={Styles.circleSkeleton}
      style={{ height: h, width: w }}
    ></div>
  );
};

export const BoxSkeleton = ({ h = "40px", w = "", style, isV2 = true }) => {
  return (
    <div
      className={`${Styles.boxSkeleton} ${Styles[isV2]}`}
      style={style ?? { height: h, width: w }}
    ></div>
  );
};
