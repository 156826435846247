import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";

export default function Searchbar({
  value,
  setValue = () => {},
  placeholder = "Search",
  className = "",
  width = "280px",
  height = "38px",
  ...props
}) {
  return (
    <div
      {...props}
      style={{ width }}
      className={`${Styles.container} ${className}`}
    >
      <input
        value={value}
        onChange={(e) => setValue(e?.target?.value)}
        placeholder={placeholder}
        style={{ height }}
      />
      <Icon icon={"search-normal"} size={18} className={Styles.icon} />
    </div>
  );
}
