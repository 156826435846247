import Sidebar from "@Molecule/Sidebar";
import LandingPageLayout from "@Organism/Landing";
import {
  getArticleLandingPage,
  getLandingPageData,
} from "@Services/landingPage";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
export async function loader() {
  const datataClass = await getLandingPageData();
  const dataArticle = await getArticleLandingPage();

  return defer({
    dataFetchLandingPage: Promise.all([datataClass, dataArticle]).then(
      ([classCourses, articles]) => ({
        classCourses,
        articles,
      })
    ),
  });
}

const LandingPage = () => {
  const { dataFetchLandingPage } = useLoaderData();
  return (
    <Suspense fallback={<p>loading....</p>}>
      <Await
        resolve={dataFetchLandingPage}
        errorElement={
          <div style={{ height: "100vh" }}>
            <p>error...</p>
          </div>
        }
      >
        {({ classCourses, articles }) => (
          <Sidebar type={"landing"}>
            <LandingPageLayout
              data={classCourses}
              dataArticles={articles?.data}
            />
          </Sidebar>
        )}
      </Await>
    </Suspense>
  );
};

export default LandingPage;
