import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import TabHeader from "@Atom/TabHeader";
import iconArrow from "@Assets/Icon/arrowNextGradient.svg";
import Images from "@Theme/Images";
import iconPrakerja from "@Assets/Images/prakerja.png";
import { API, fileBaseUrl } from "@Config/index";
import { capitalizeWords } from "@Helpers/capitallizeWords";
import useWindowSize from "@Hooks/useWindowSize";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Icon from "@Atom/Icon";
export default function FindClassLandingPage({ type, onClick }) {
  const { width } = useWindowSize();

  const [tabs, setTabs] = useState(1);
  const tabOptions = useMemo(() => {
    return [
      {
        value: "",
        type: "Semua",
      },
      {
        value: "",
        type: "Terlaris",
      },
      {
        value: "VIDEO",
        type: "Pembelajaran Mandiri (Video)",
      },
      {
        value: "WEBINAR",
        type: "Kelas Webinar (Online)",
      },
      {
        value: "OFFLINE",
        type: "Kelas Tatap Muka (Offline)",
      },
    ];
  }, []);
  const tabOptionsRegular = useMemo(() => {
    return [
      {
        value: "",
        type: "Semua",
      },
      {
        value: "",
        type: "Terlaris",
      },
      {
        value: "WORKSHOP",
        type: "Workshop",
      },
      {
        value: "VIDEO",
        type: "Pembelajaran Mandiri (Video)",
      },
      {
        value: "WEBINAR",
        type: "Kelas Webinar (Online)",
      },
      {
        value: "OFFLINE",
        type: "Kelas Tatap Muka (Offline)",
      },
    ];
  }, []);

  const tabToMap = type === "regular" ? tabOptionsRegular : tabOptions;

  const [dataFetch, setDataFetch] = useState([]);
  const getLandingPageAllData = async () => {
    try {
      const { data } = await API.get(
        `/landing/allClass?limit=6&page=1&sort=new&type=${
          type ? "GENERAL" : "PRAKERJA"
        }&platform=${_.get(tabToMap, [tabs - 1, "value"], "")}`
      );
      setDataFetch(data);
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    getLandingPageAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs, type]);

  return (
    <div
      className={Styles.container}
      id={type === "regular" ? "regulerclass" : ""}
    >
      <section className={Styles.wrapperClass}>
        <main>
          <div
            className={Styles.tabs}
            is-mobile={width < 768 ? "true" : "false"}
          >
            <TabHeader
              tabs={tabs}
              setTabs={setTabs}
              tabOptions={tabToMap.map((el) => el.type)}
              className={Styles.overFlowTabHeader}
            />
            <div
              className={Styles.button}
              style={width < 768 ? { display: "none" } : undefined}
            >
              <p onClick={onClick}>Lihat Semua</p>
              <img src={iconArrow} alt="arrow-button" />
            </div>
          </div>
          <div className={Styles.mainContain}>
            <div className={Styles.detailClass}>
              <div className={Styles.typeClass}>
                <img
                  src={
                    type === "regular"
                      ? Images.CLASS_REGULAR_LANDING_PAGE
                      : Images.CLASS_PRAKERJA_LANDING_PAGE
                  }
                  alt=""
                />
                <div className={Styles.typeDesc}>
                  <h1>
                    {type === "regular" ? "Kelas Regular" : "Kelas Prakerja"}
                  </h1>
                  <p>
                    {type === "regular"
                      ? "Skills.id menyediakan kelas regular untuk meningkatkan kemampuan kamu di berbagai bidang"
                      : "Kelas ini merupakan program afiliasi Skills.id x Prakerja dengan pengajar profesional"}
                  </p>
                </div>
              </div>
            </div>
            <div className={Styles.wrapperCard}>
              {dataFetch.data?.map((item, idx) => (
                <div
                  onClick={() =>
                    navigate(
                      `course/${item.title
                        ?.split(" ")
                        .join("-")
                        .split("/")
                        .join("-")
                        .split("?")
                        .join("")}/${item._id}`
                    )
                  }
                  style={{ width: "100%", display: "flex" }}
                  key={idx}
                >
                  <div className={Styles.card}>
                    <div className={Styles.image}>
                      <img
                        src={
                          item.asset.thumbnail.imageURL.includes("https")
                            ? item.asset.thumbnail.imageURL
                            : fileBaseUrl + item.asset.thumbnail.imageURL
                        }
                        alt="thumbnail-images"
                        // onError={() => setIsImageError(true)}
                      />
                      {item?.promo?.quota < 999 && item?.promo?.quota !== 0 && (
                        <div className={Styles.badge}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="8"
                            viewBox="0 0 10 8"
                            fill="none"
                            className={Styles.popoverBadge}
                          >
                            <path d="M10 8L0 0H10V8Z" fill="#764A87" />
                          </svg>
                          <Icon
                            icon={"discount-shape-filled"}
                            color={"#fff"}
                            size={"20"}
                          />
                          <span>Kuota Terbatas</span>
                        </div>
                      )}
                      <div className={Styles.imgText}>
                        <span>Kelas Terlaris</span>
                      </div>
                    </div>
                    <div className={Styles.description}>
                      <div className={Styles.class}>
                        <div>
                          <span>
                            {capitalizeWords(item?.platform) === "Offline"
                              ? capitalizeWords("Kelas " + item?.platform)
                              : capitalizeWords(item?.platform)}
                          </span>
                        </div>
                        {type !== "regular" && (
                          <div>
                            <img src={iconPrakerja} alt="prakerja" />
                          </div>
                        )}
                      </div>
                      <div className={Styles.title}>
                        <p>{item?.title}</p>
                        <p>Oleh {item?.instructor?.name}</p>
                      </div>
                      <div className={Styles.price}>
                        <div
                          className={
                            item?.price?.discount > 0
                              ? Styles.flexRow
                              : Styles.flexCol
                          }
                        >
                          <p
                            className={
                              item?.price?.discount > 0
                                ? Styles.lineThrough
                                : ""
                            }
                          >
                            Rp {item?.price?.base?.toLocaleString("id")}
                          </p>
                          {item?.price?.discount > 0 && (
                            <div className={Styles.discount}>
                              <span>{item?.price?.discountPercentage}%</span>
                            </div>
                          )}
                        </div>
                        {item?.price?.discount > 0 && (
                          <p>
                            Rp{" "}
                            {item?.price?.afterDiscount?.toLocaleString("id")}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {width < 768 && (
            <div className={Styles.wrapperGradientText}>
              <p className={Styles.gradientText} onClick={onClick}>
                Lihat Semua
              </p>
            </div>
          )}
        </main>
      </section>
    </div>
  );
}
