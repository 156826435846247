import Icon from "@Atom/Icon";
import usePagination, { DOTS } from "@Hooks/usePagination";
import { useEffect } from "react";
import Styles from "./style.module.scss";
import Select from "@Atom/Select";

const Pagination = ({
  total = 1,
  limit,
  setLimit,
  selectedPage = 1,
  setSelectedPage = () => {},
  pageList,
  setPageList = () => {},
  totalPage = 1,
  siblingCount = 1,
  bgColor,
  noPageList = false,
  noLimitSetter = false,
  isV2 = false,
  ...props
}) => {
  const paginationRange = usePagination({
    selectedPage,
    siblingCount,
    totalPage,
    isV2,
  });

  const onNext = () => {
    if (selectedPage < totalPage) {
      setSelectedPage(selectedPage + 1);
    }
  };

  const onPrevious = () => {
    if (selectedPage > 1) {
      setSelectedPage(selectedPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  const prevDisabled = selectedPage === 1;
  const nextDisabled = selectedPage === lastPage;

  const options = ["5", "10", "15"];

  useEffect(() => {
    if (!noPageList) {
      setSelectedPage(1);
    }
  }, [limit, setSelectedPage, noPageList]);

  useEffect(() => {
    const list = [];
    if (!noPageList) {
      if (total / limit >= 1) {
        for (let i = 1; i <= total / limit; i++) {
          list.push({ number: i });
        }
      }
      if (total / limit < 1) {
        list.push({ number: 1 });
      }
      if (total / limit >= 1 && total % limit > 0) {
        list.push({ number: Math.floor(total / limit + 1) });
      }

      setPageList(list);
    }
  }, [limit, total, setPageList, noPageList]);

  return (
    <div
      style={{ backgroundColor: bgColor || "auto" }}
      className={`${Styles.container} ${isV2 && Styles.center} `}
    >
      {!isV2 && (
        <div className={Styles.leftSide}>
          <span>Showing</span>
          <Select
            width="70px"
            setValue={setLimit}
            value={limit}
            options={options}
          />
          <span>{`of ${total} Data`}</span>
        </div>
      )}
      <div className={Styles.rightSide}>
        <div className={Styles.pageIndicator}>
          <div className={Styles.arrowWrapper}>
            <div
              className={`${Styles.arrow} ${
                prevDisabled ? Styles.disabled : ""
              }`}
              onClick={onPrevious}
            >
              <Icon icon="chevron-left" size={isV2 ? 20 : 16} />
            </div>
          </div>
          {paginationRange.map((each) => {
            if (each === DOTS) {
              return isV2 ? (
                <div key={each + Math.random()}>•••</div>
              ) : (
                <div
                  key={each + Math.random()}
                  className={`${Styles.page} ${Styles.disabled}`}
                >
                  &#8230;
                </div>
              );
            }
            return (
              <li
                key={each + Math.random()}
                className={`${
                  selectedPage === each ? Styles.selectedPage : ""
                } ${Styles.page}`}
                onClick={() => setSelectedPage(each)}
              >
                {each}
              </li>
            );
          })}
          <div className={Styles.arrowWrapper}>
            <div
              className={`${Styles.arrow} ${
                nextDisabled ? Styles.disabled : ""
              }`}
              onClick={onNext}
            >
              <Icon icon="chevron-right" size={isV2 ? 20 : 16} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
