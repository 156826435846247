import React, { useState } from "react";
import Styles from "./style.module.scss";
import InputField from "@Molecule/InputField";
import Button from "@Atom/Button";
import TelephoneHome from "@Assets/Images/telephone-home.png";
import ContctUsBadge from "@Assets/Images/contact-us-badge.png";

export default function FormContactUs() {
  const [inputForm, setInputForm] = useState({
    name: "",
    email: "",
    telephone: "",
    educationInstance: "",
  });

  const handleChangeForm = (e, name) => {
    setInputForm((prev) => ({
      ...prev,
      [name]: e,
    }));
  };

  const handleSendWhatsAppMessage = (e) => {
    e.preventDefault();
    const { name, educationInstance, email, telephone } = inputForm;
    const message = `Halo, nama saya ${name}. Saya tertarik menggunakan sistem Skills For Education yang telah dibuat oleh tim Anda. Berikut adalah detail saya:
    Email: ${email}
    Nomor Telepon: ${telephone}
    Nama Instansi Pendidikan: ${educationInstance},
  Saya ingin mengetahui lebih lanjut mengenai sistem ini. Terima kasih!
    `;
    const whatsappUrl = `https://wa.me/628118286996?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  const isDisabledButton = () => {
    const { educationInstance, email, name, telephone } = inputForm;
    if (!name || !email || !telephone || !educationInstance) return true;
    return false;
  };

  return (
    <div className={Styles.sectionFormContactUs} id="contact-us-form">
      <div className={Styles.leftSection}>
        <div className={Styles.wrapImg}>
          <img src={ContctUsBadge} alt="contact-us" />
          <img src={TelephoneHome} alt="telpehone" />
        </div>
      </div>

      <div className={Styles.right}>
        <div className={Styles.headerForm}>
          <p>Tertarik untuk menggunakan sistem kami?</p>
          <h2>Hubungi Kami Sekarang Juga!</h2>
        </div>
        <InputField
          setValue={(e) => handleChangeForm(e, "name")}
          value={inputForm.name}
          title={"Nama Lengkap"}
          placeholder="Masukkan Nama Anda"
        />
        <InputField
          setValue={(e) => handleChangeForm(e, "email")}
          value={inputForm.email}
          title={"Email"}
          placeholder="Masukkan Email Anda"
          isEmail
        />
        <InputField
          setValue={(e) => handleChangeForm(e, "telephone")}
          isPhoneNumber
          value={inputForm.telephone}
          title={"No Telepon"}
          placeholder="Masukkan no telepon Anda"
        />
        <InputField
          setValue={(e) => handleChangeForm(e, "educationInstance")}
          value={inputForm.educationInstance}
          title={"Nama Instansi Pendidikan"}
          placeholder="Masukkan nama instansi pendidikan"
        />
        <Button
          text={"Kirim"}
          onClick={(e) => handleSendWhatsAppMessage(e)}
          endIcon={"send-solid"}
          className={Styles.btn}
          disabled={isDisabledButton()}
        />
      </div>
    </div>
  );
}
