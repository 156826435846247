import React, { useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Button from "@Atom/Button";
import Searchbar from "@Atom/Searchbar";
import useAllBlog from "@Hooks/Admin/useAllBlog";
import BlogCard from "@Molecule/_blog/BlogCard";
import { BoxSkeleton } from "@Molecule/Skeleton";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import { useNavigate } from "react-router-dom";
import ModalDeleteBlog from "@Molecule/_blog/ModalDeleteBlog";

const tabList = [
  { label: "Semua Artikel", value: "" },
  { label: "Publish", value: "PUBLISH" },
  { label: "Draft", value: "DRAFT" },
  { label: "Unpublish", value: "UNPUBLISH" },
];

export default function ListAllBlog() {
  const user = decryptStorageData("user", localStorage);
  const {
    activeTab,
    setActiveTab,
    search,
    setSearch,
    isLoading,
    data,
    containerRef,
    withFilter,
    refetchData,
  } = useAllBlog();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const navigate = useNavigate();
  return (
    <div className={Styles.container}>
      <ModalDeleteBlog
        title={selectedData?.name}
        handleClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
        callback={refetchData}
      />
      <DoodleGradientCard>
        <div className={Styles.doodleCard}>
          <h2>
            Selamat Datang <span className={Styles.span}> {user?.name}</span>
          </h2>
          <p>Silakan buat dan atur artikel untuk skills.id</p>
        </div>
      </DoodleGradientCard>
      <div className={Styles.tabWrapper}>
        {tabList.map((el, idx) => (
          <Button
            variant="tab"
            text={el.label}
            key={idx}
            isActive={el.value === activeTab}
            onClick={() => activeTab !== el.value && setActiveTab(el.value)}
          />
        ))}
      </div>
      <div className={Styles.searchWrapper}>
        <Searchbar
          placeholder="Cari judul artikel"
          value={search}
          setValue={setSearch}
        />
        <div>
          <Button
            text={"Download Report"}
            startIcon={"document-upload-filled"}
            variant="outlined"
          />
          <Button
            text={"Tambah Artikel"}
            startIcon={"add-circle"}
            onClick={() => navigate("create")}
          />
        </div>
      </div>
      <div className={Styles.contentWrapper}>
        {data.map((el, idx) => (
          <BlogCard
            key={idx}
            data={el}
            setOpenDeleteModal={setOpenDeleteModal}
            setSelectedData={setSelectedData}
          />
        ))}
        {isLoading &&
          [1, 2, 3, 4, 5].map((el, idx) => (
            <BoxSkeleton
              key={idx}
              style={{ width: "100%", height: 140, borderRadius: "15px" }}
            />
          ))}
        {!isLoading && data.length === 0 && (
          <div>
            <TableEmptyHandler
              title=""
              desription={
                withFilter
                  ? "Artikel Tidak Ditemukan"
                  : "Belum ada artikel ditambahkan"
              }
            >
              <Button
                text={"Tambah Artikel"}
                startIcon={"add-circle"}
                onClick={() => navigate("create")}
              />
            </TableEmptyHandler>
          </div>
        )}
      </div>
      <div ref={containerRef} />
    </div>
  );
}
