import { useEffect, useState } from "react";
import { useDebounce } from "../../useDebounce";
import { getAdminPromoList } from "@Services/promo";

export default function useAllPromo() {
  const [activeTab, setActiveTab] = useState("ALL");
  const [search, setSearch] = useState("");
  const [OpenAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState(1);
  const deboucedSearch = useDebounce(search, 500);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res = await getAdminPromoList({
        page,
        limit,
        search,
        period: activeTab,
      });
      const data = res.promos;
      setTotalPage(res.totalPages);
      setTotalData(res.totalData);
      setTotalPage(res.totalPages);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, page, deboucedSearch]);

  useEffect(() => {
    setPage(1);
    setData([]);
  }, [activeTab, deboucedSearch]);

  const handleCloseModal = () => {
    setOpenAddModal(false);
    setOpenEditModal(false);
  };

  return {
    activeTab,
    setActiveTab,
    search,
    setSearch,
    isLoading,
    data,
    page,
    setPage,
    limit,
    setLimit,
    totalData,
    loading: isLoading,
    totalPage,
    OpenAddModal,
    setOpenAddModal,
    handleCloseModal,
    refetchData: fetchData,
    openEditModal,
    setOpenEditModal,
  };
}
