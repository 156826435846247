import React, { useMemo } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import Button from "@Atom/Button";
import Searchbar from "@Atom/Searchbar";
import useAllPromo from "@Hooks/Admin/promo/useAllPromo";
import moment from "moment";
import Table from "@Molecule/Table";
import Icon from "@Atom/Icon";
import ModalAddPromo from "./ModalAddPromo";

const tabList = [
  { label: "Semua", value: "ALL" },
  { label: "Periode Aktif", value: "ACTIVE" },
  { label: "Periode Tidak Aktif", value: "INACTIVE" },
];

export default function ListAllPromo() {
  const user = decryptStorageData("user", localStorage);
  const {
    activeTab,
    setActiveTab,
    search,
    setSearch,
    data,
    page,
    setPage,
    limit,
    setLimit,
    totalData,
    loading,
    totalPage,
    OpenAddModal,
    handleCloseModal,
    setOpenAddModal,
    openEditModal,
    setOpenEditModal,
    refetchData,
  } = useAllPromo();

  const template = useMemo(() => {
    return {
      data,
      columns: [
        {
          name: "",
          title: "Nama Promo",
          renderData: (row) => (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setOpenEditModal(row._id)}
            >
              {row?.name}
            </span>
          ),
        },
        {
          name: "email",
          title: "Periode Promo",
          renderData: (row) => (
            <div className={Styles.periodWrapper} style={{ cursor: "pointer" }}>
              <div className={Styles.period}>
                <Icon icon="checklist" size={12} color={"#60AA15"} />
                <p>{moment(row.start).format("DD MMM YYYY")}</p>
              </div>
              <div className={Styles.period}>
                <Icon icon="bold-circle" size={12} color={"#B22A12"} />
                <p>{moment(row.end).format("DD MMM YYYY")}</p>
              </div>
            </div>
          ),
        },
        {
          name: "totalCourse",
          title: "Jumlah Kelas",
          align: "right",
          renderData: (row) => <span>{row?.totalCourse || 0}</span>,
        },
        {
          name: "",
          title: "Jumlah Kelas Dibeli",
          renderData: (row) => <span>{row?.totalUsed}</span>,
          align: "right",
        },
      ],
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={Styles.container}>
      <DoodleGradientCard>
        <div className={Styles.doodleCard}>
          <h2>
            Selamat Datang <span className={Styles.span}> {user?.name}</span>
          </h2>
          <p>Silakan buat dan atur promo untuk skills.id</p>
        </div>
      </DoodleGradientCard>
      <div className={Styles.tabWrapper}>
        {tabList.map((el, idx) => (
          <Button
            variant="tab"
            text={el.label}
            key={idx}
            isActive={el.value === activeTab}
            onClick={() => activeTab !== el.value && setActiveTab(el.value)}
          />
        ))}
      </div>
      <div className={Styles.searchWrapper}>
        <Searchbar
          placeholder="Cari nama promo"
          value={search}
          setValue={setSearch}
        />
        <div>
          <Button
            text={"Download Report"}
            startIcon={"document-upload-filled"}
            variant="outlined"
          />
          <Button
            text={"Tambah Promo"}
            startIcon={"add-circle"}
            onClick={() => setOpenAddModal(true)}
          />
        </div>
      </div>
      <div className={Styles.contentWrapper}>
        {/* {data.map((el, idx) => (
          <BlogCard key={idx} data={el} />
        ))} */}
        <Table
          data={template?.data}
          totalData={totalData}
          totalPage={totalPage}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table}`}
          limit={limit}
          setLimit={setLimit}
          withPagination
          withNumbering
          isLoading={loading}
        />
      </div>
      {OpenAddModal && (
        <ModalAddPromo
          open={OpenAddModal}
          onClose={handleCloseModal}
          refetchData={refetchData}
        />
      )}
      {openEditModal && (
        <ModalAddPromo
          refetchData={refetchData}
          open={Boolean(openEditModal)}
          onClose={handleCloseModal}
          isEdit={openEditModal}
        />
      )}
    </div>
  );
}
