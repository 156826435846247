import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import prakerjaText from "@Assets/Images/prakerjaText.png";
import heroImage from "@Assets/Images/Hero-pict-landing-page.png";
import heroImageMobile from "@Assets/Images/hero-pict-landing-page-mobile.png";
import { getCoursebyVoucher } from "@Services/course";
import { useNavigate } from "react-router-dom";
import { translateError } from "@Helpers/translateError";
import useWindowSize from "@Hooks/useWindowSize";

export default function ImageGalleryPrakerja() {
  const [voucherCode, setVoucherCode] = useState();
  const [errorRedeem, setErrorRedeem] = useState("");
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const handleExchangeVoucher = async () => {
    try {
      const res = await getCoursebyVoucher(voucherCode);
      console.log(res);
      if (res.status) {
        navigate(
          `/course/${res?.data?.title
            ?.split(" ")
            .join("-")
            .split("/")
            .join("-")}/${res.data._id}`
        );
      }
    } catch (error) {
      setErrorRedeem(translateError(error.response.data.error));
    }
  };

  useEffect(() => {
    if (voucherCode) {
      setErrorRedeem(false);
    }
  }, [voucherCode]);

  return (
    <div className={Styles.wrapperImageGalleryPrakerja}>
      <img
        src={Images.LEFT_CLAY}
        alt="clay-background"
        className={Styles.clay}
      />
      <section className={Styles.sectionLeft}>
        <div className={Styles.headerText}>
          <h1>Jadi Talenta Terbaik dan Bangun Potensimu</h1>
          <span>Skills.id & Prakerja </span>
        </div>
        <div className={Styles.generateVoucher}>
          <h1>Punya Voucher Prakerja?</h1>
          <p>
            Dapatkan keuntungan lebih dengan menukarkan Voucher Prakerja Anda di
            Skills.id
          </p>
          <div className={Styles.wrapperButton}>
            <input
              type="text"
              placeholder="Masukan voucher prakerja"
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
            />
            <div
              className={Styles.button}
              onClick={() => handleExchangeVoucher()}
            >
              <span>Tukar</span>
            </div>
          </div>
          {!!errorRedeem && (
            <span className={Styles.errorMessage}>
              {errorRedeem.toLowerCase() !== "voucher already exchanged"
                ? "Voucher Tidak Di Temukan"
                : errorRedeem}
            </span>
          )}
        </div>
        <div className={Styles.skillsXprakerja}>
          <div>
            <img
              src={Images.LOGO}
              alt="skills.id"
              className={Styles.imagesLogo}
            />
            <span>Skills.id</span>
          </div>
          <span>X</span>
          <img
            src={prakerjaText}
            alt="prakerja"
            className={Styles.imagesLogoPrakerja}
          />
        </div>
      </section>
      <section className={Styles.sectionRight}>
        <div className={Styles.borderWrapper}>
          <img
            src={width >= 768 ? heroImage : heroImageMobile}
            alt="hero-images"
            className={Styles.heroImage}
          />
        </div>
      </section>
    </div>
  );
}
