import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import Images from "@Theme/Images";
import Button from "@Atom/Button";
import { fileBaseUrl } from "@Config/index";
import { capitalizeWords } from "@Helpers/capitallizeWords";
import iconPrakerja from "@Assets/Images/prakerja.png";
import CheckBox from "@Atom/Checkbox";
import Searchbar from "@Atom/Searchbar";
import { useLocation, useNavigate } from "react-router-dom";
import RadioButton from "@Atom/RadioButton";
import useQuery from "@Hooks/useQuery";
import { getAllCourses } from "@Services/course";
import { useDebounce } from "@Hooks/useDebounce";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import Skeleton, { BoxSkeleton } from "@Molecule/Skeleton";
import Spinner from "@Atom/Spinner";
import Icon from "@Atom/Icon";
import useOutsideClick from "@Hooks/useOutsideClick";

const filterOption = [
  {
    type: "Kategori Kelas",
    value: "category",
    options: [
      { type: "Semua kategori", value: "all" },
      { type: "Sales", value: "Sales" },
      { type: "IT/Software", value: "IT/Software" },
      { type: "Jurnalistik", value: "Jurnalistik" },
      { type: "Manajemen", value: "Manajemen" },
      { type: "Bisnis", value: "Bisnis" },
      { type: "Communication", value: "Communication" },
      { type: "Design", value: "Design" },
      { type: "Language", value: "Language" },
      { type: "Seni/Kerajinan", value: "Seni/Kerajinan" },
      { type: "Lifestyle", value: "Lifestyle" },
      { type: "Marketing", value: "Marketing" },
      { type: "SDM", value: "SDM" },
    ],
  },

  {
    type: "Jenis Kelas",
    value: "type",
    // options: ["GENERAL", "PRAKERJA"],
    options: [
      { type: "Reguler", value: "GENERAL" },
      { type: "Prakerja", value: "PRAKERJA" },
    ],
    isRadio: true,
  },
  {
    type: "Tipe Kelas",
    value: "platform",
    options: [
      { type: "Kelas Online", value: "WEBINAR" },
      { type: "Kursus", value: "OFFLINE" },
      { type: "Video", value: "VIDEO" },
    ],
  },
  {
    value: "level",
    type: "Level Kelas",
    // options: ["BEGINNER", "INTERMEDIATE", "EXPERT"],
    options: [
      { type: "Beginner", value: "BEGINNER" },
      { type: "Intermediate", value: "INTERMEDIATE" },
      { type: "Expert", value: "EXPERT" },
    ],
  },
];

export default function AllCoursePage({ isLoader = true }) {
  const [data, setData] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [isLoadingScroll, setIsLoadingScroll] = useState(false);
  const [isInitialLoader, setIsInitialLoader] = useState(isLoader);
  const [isOpenSortFilter, setIsOpenSortFilter] = useState(false);
  const [filter, setFilter] = useState({
    platform: [],
    category: ["all"],
    type: query.get("type")?.toUpperCase() || "",
    level: [],
    sort: { text: "Terbaru", value: "latest" },
  });

  const listSortFilter = [
    { text: "Terbaru", value: "latest" },
    // "Terlaris",
    { text: "Harga Terendah", value: "lowestPrice" },
    { text: "Harga Tertinggi", value: "highestPrice" },
  ];

  useEffect(() => {
    if (query.get("categories")) {
      setFilter((prev) => ({
        ...prev,
        category: query.getAll("categories"),
      }));
    } else if (query.get("type")) {
      setFilter((prev) => ({
        ...prev,
        type: query.get("type").toUpperCase(),
      }));
    }
  }, [query]);

  const handleChangeFilter = (name, value) => {
    window.scrollTo({
      behavior: "smooth",
      top: 220,
    });
    setData([]);
    setPage(1);
    const temp = { ...filter };

    if (temp.category.length >= 1 && value === "all") {
      temp.category = ["all"];
    }

    if (temp.category.includes("all")) {
      temp.category = temp.category.filter((c) => c !== "all");
    }

    if (temp[name].includes(value)) {
      temp[name] = temp[name].filter((el) => el !== value);
      if (temp.category.length === 0) {
        temp.category.push("all");
      }
    } else {
      temp[name] = [...temp[name], value];
    }
    setFilter(temp);

    if (temp.category.includes("all")) {
      navigate(`${pathname}`);
    } else if (!temp.category.includes("all")) {
      navigate(
        `${pathname}?${temp.category.map((c) => `categories=${c}`).join("&")}`
      );
    }
  };

  useEffect(() => {
    setPage(1);
  }, [filter, debounceSearch]);

  const fetchAllDataCourses = useCallback(
    async () => {
      if (isLoadingScroll) return;

      setIsLoadingScroll(true);

      const { level, platform, type, sort } = filter;
      try {
        const res = await getAllCourses(
          query.getAll("categories"),
          platform,
          type,
          level,
          sort.value,
          page,
          10,
          debounceSearch
        );

        const totalPage = Math.ceil(res?.totalData / 10);

        if (debounceSearch) {
          setData(res?.data);
          setTotalPage(totalPage);
          return;
        }

        const newData = res?.data || [];
        const updatedData = [
          ...data,
          ...newData.filter(
            (item) =>
              !data.some((existingItem) => existingItem._id === item._id)
          ),
        ];
        if (isResetFilter) {
          setData(newData);
        } else {
          setData(updatedData);
        }
        setTotalPage(totalPage);
      } catch (error) {
        console.log("Error get data courses", error);
      } finally {
        setIsLoadingScroll(false);
        setIsInitialLoader(false);
        setIsResetFilter(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debounceSearch, filter, page]
  );

  useEffect(() => {
    fetchAllDataCourses();
  }, [fetchAllDataCourses]);

  const endOfListRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingScroll && !isInitialLoader) {
          if (page < totalPage) {
            setPage((p) => p + 1);
          }
        }
      },
      { threshold: 1 }
    );

    if (endOfListRef.current) {
      observer.observe(endOfListRef.current);
    }

    return () => observer.disconnect();
  }, [page, totalPage, isInitialLoader, isLoadingScroll]);

  const refBoxFilterSort = useRef(null);
  const outBoxFilterSort = useOutsideClick(refBoxFilterSort);

  useEffect(() => {
    if (isOpenSortFilter && outBoxFilterSort) {
      setIsOpenSortFilter(false);
    }
  }, [isOpenSortFilter, outBoxFilterSort]);

  return (
    <div className={Styles.container}>
      <div className={Styles.headWrapper}>
        <div className={Styles.back}>
          <Button
            text={"Kembali"}
            variant="text"
            startIcon={"chevron-left"}
            onClick={() => navigate("/")}
          />
        </div>
        <DoodleGradientCard className={Styles.headerSection}>
          <div className={Styles.leftSection}>
            <p>Semua Kelas di Skills.id</p>
            <p>Temukan dan pilih kelasmu</p>
          </div>
          <div className={Styles.rightSection}>
            <img src={Images.MOCKUP_IMAGE} alt="images" />
          </div>
        </DoodleGradientCard>
      </div>
      <div className={Styles.content}>
        <div className={Styles.contentWrapper}>
          <div className={Styles.filterSection}>
            <div className={Styles.filterHead}>
              <p>Filter Pencarianmu</p>
              <Button
                text={"Hapus Filter"}
                variant="text"
                onClick={() => {
                  navigate(pathname, { replace: true });
                  setIsResetFilter(true);
                  window.scrollTo({
                    behavior: "smooth",
                    top: 220,
                  });
                  setFilter({
                    platform: [],
                    category: ["all"],
                    type: "",
                    level: [],
                    sort: { text: "Terbaru", value: "latest" },
                  });
                }}
              />
            </div>
            <div className={Styles.filterCardWrapper}>
              {filterOption.map((el, idx) => (
                <div className={Styles.filterCard} key={idx}>
                  <p>{el.type}</p>
                  <div className={Styles.filterOptions}>
                    {el.options.map((option, idx) => (
                      <div className={Styles.option} key={idx}>
                        {el?.isRadio ? (
                          <RadioButton
                            isSelected={filter[el.value] === option.value}
                            onClick={() => {
                              if (query.get("type")) {
                                navigate(pathname);
                              }
                              setData([]);
                              setPage(1);
                              window.scrollTo({
                                behavior: "smooth",
                                top: 220,
                              });
                              setFilter((prev) => ({
                                ...prev,
                                type: option.value,
                              }));
                            }}
                          />
                        ) : (
                          <CheckBox
                            isChecked={filter[el.value].includes(option.value)}
                            onClick={() =>
                              handleChangeFilter(el.value, option.value)
                            }
                          />
                        )}
                        <p>{option.type}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={Styles.itemsWrapper}>
            <div className={Styles.itemHead}>
              <Searchbar
                value={search}
                placeholder="Cari kelas apa saja"
                setValue={setSearch}
                height="48px"
                className={Styles.shadowSearchBar}
              />
              <div className={Styles.btnSortFilter}>
                <p>Urutkan Berdasarkan :</p>
                <div onClick={() => setIsOpenSortFilter(!isOpenSortFilter)}>
                  <span>{filter.sort.text}</span>
                  <Icon
                    icon={"arrow-left"}
                    size={"18"}
                    className={`${Styles.icon} ${Styles[isOpenSortFilter]} `}
                  />
                </div>
                {isOpenSortFilter && (
                  <div
                    ref={refBoxFilterSort}
                    className={Styles.wrapListDropdown}
                  >
                    <ul>
                      {listSortFilter.map((s, idx) => (
                        <li
                          className={Styles[s.value === filter.sort.value]}
                          onClick={() => {
                            setFilter((prevFilter) => ({
                              ...prevFilter,
                              sort: s,
                            }));
                            setIsOpenSortFilter(false);
                            setData([]);
                            setPage(1);
                          }}
                          key={s.text + idx}
                        >
                          {s?.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {data.length === 0 &&
            !search &&
            !isLoadingScroll &&
            (filter.category.length !== 0 ||
              filter.level.length !== 0 ||
              filter.platform.length !== 0 ||
              !filter.type) ? (
              <TableEmptyHandler
                title="Tidak ada daftar kelas"
                desription="saat ini belum ada kelas yang ditambahkan"
              />
            ) : data.length === 0 && search ? (
              <TableEmptyHandler
                title="Kelas Tidak Ditemukan"
                desription="Data yang anda cari tidak ada dalam daftar"
              />
            ) : (
              <DataMapping isLoading={isLoadingScroll || isInitialLoader} />
            )}

            <DataMapping
              data={data}
              ref={endOfListRef}
              isLoading={isInitialLoader}
            />

            {isLoadingScroll && data.length > 9 && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spinner width="40px" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const DataMapping = forwardRef(({ data, isLoading = true }, endOfListRef) => {
  const navigate = useNavigate();
  return (
    <div className={Styles.wrapperCard}>
      {(isLoading ? Array.from({ length: 8 }) : data)?.map((item, idx) => (
        <div
          onClick={() =>
            navigate(
              `/course/${item.title
                ?.split(" ")
                .join("-")
                .split("/")
                .join("-")}/${item._id}`
            )
          }
          style={{ width: "100%" }}
          key={idx}
        >
          <div className={Styles.card} isloadingskeleton={isLoading.toString()}>
            {isLoading ? (
              <BoxSkeleton h="250px" w="100%" />
            ) : (
              <div className={Styles.image}>
                <img
                  src={
                    item?.asset.thumbnail.imageURL.includes("https")
                      ? item?.asset.thumbnail.imageURL
                      : fileBaseUrl + item?.asset.thumbnail.imageURL
                  }
                  alt="thumbnail-images"
                />
                {item?.promo?.quota < 999 && item?.promo?.quota !== 0 && (
                  <div className={Styles.badge}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="8"
                      viewBox="0 0 10 8"
                      fill="none"
                      className={Styles.popoverBadge}
                    >
                      <path d="M10 8L0 0H10V8Z" fill="#764A87" />
                    </svg>
                    <Icon
                      icon={"discount-shape-filled"}
                      color={"#fff"}
                      size={"20"}
                    />
                    <span>Kuota Terbatas</span>
                  </div>
                )}
                <div className={Styles.imgText}>
                  <span>Kelas Terlaris</span>
                </div>
              </div>
            )}
            {isLoading ? (
              <div className={Styles.description}>
                <Skeleton w="200px" />
                <Skeleton w="auto" />
                <Skeleton w="130%" />
              </div>
            ) : (
              <div
                className={Styles.description}
                isloadingskeleton={isLoading.toString()}
              >
                <div className={Styles.class}>
                  <div>
                    <span>
                      {capitalizeWords(item?.platform) === "Offline"
                        ? capitalizeWords("Kelas " + item?.platform)
                        : capitalizeWords(item?.platform)}
                    </span>
                  </div>
                  {item?.type === "PRAKERJA" && (
                    <div>
                      <img src={iconPrakerja} alt="prakerja" />
                    </div>
                  )}
                </div>

                <div className={Styles.title}>
                  <p>{capitalizeWords(item?.title)}</p>
                  <p>Oleh {capitalizeWords(item?.instructor?.name)}</p>
                </div>

                <div className={Styles.price}>
                  <div
                    className={
                      item?.price?.discount > 0
                        ? Styles.flexRow
                        : Styles.flexCol
                    }
                  >
                    <p
                      className={
                        item?.price?.discount > 0 && Styles.lineThrough
                      }
                    >
                      Rp {item?.price?.base?.toLocaleString("id")}
                    </p>
                    {item?.price?.discount > 0 && (
                      <div className={Styles.discount}>
                        <span>{item?.price?.discountPercentage}%</span>
                      </div>
                    )}
                  </div>
                  {item?.price?.discount > 0 && (
                    <p>Rp {item?.price?.afterDiscount?.toLocaleString("id")}</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      <div ref={endOfListRef} />
    </div>
  );
});
