export function translateError(string) {
  const translatedError = [
    "Oops, ada masalah di server kami. Lagi diperbaiki ya!",
    "tipe wajib diisi",
    "tipe tidak ditemukan",
    "sesi wajib diisi",
    "batch wajib diisi",
    "status wajib diisi",
    "Data tidak ditemukan",
    "Failed read excel",
    "Template tidak ditemukan",
    "Failed send notification",
    "Sertifikat belum tersedia",
    "Nama wajib diisi",
    "Nama Prakerja Tidak Sesuai",
    "Progress tidak ditemukan",
    "Kelas tidak ditemukan",
    "Kelas tidak ditemukan",
    "File and type length doesnt match",
    "Instruktur tidak ditemukan",
    "only for VIDEO kelas",
    "User sheet tidak ditemukan",
    "Sesi tidak ditemukan",
    "Materi tidak ditemukan",
    "Siswa belum mengupload tugas",
    "Voucher tidak ditemukan",
    "Kelas tidak ditemukan",
    "Type only accept uk & tpm value",
    "Sesi and Materi dibutuhkan untuk tpm",
    "Sesi tidak ditemukan",
    "Materi tidak ditemukan",
    "Invalid token",
    "Redeem Code diperlukan untuk mengecek tugas",
    "Voucher tidak ditemukan",
    "Sesi tidak ditemukan",
    "Selesaikan kuis sebelumnya dan dapatkan minimal 80",
    "Anda sudah memberi penilaian",
    "Anda belum membeli kelas ini",
    "Gagal Mengupload File",
    "File dibutuhkan",
    "Isi semua inputan",
    "sheet tidak ditemukan",
    "Sesi tidak ditemukan",
    "sessionNum wajib diisi",
    "Email sudah terpakai",
    "Nomor WhatsApp sudah terpakai",
    "Kode expired",
    "OTP Salah",
    "Invalid email/phone",
    "Voucher tidak ditemukan",
    "Voucher already exchanged",
    "Kelas Salah",
    "Batch tidak ditemukan",
    "kelas Tidak Ditemukan",
    "Kelas sudah memiliki promo aktif",
    "Kelas tidak ditemukan",
    "Promo tidak ditemukan",
  ];

  const listOfError = [
    "Internal Server Error",
    "type required",
    "type not allowed",
    "sessionNum is required",
    "coursebatch is required",
    "status is required",
    "Data Not Found",
    "Failed read excel",
    "Template not found",
    "Failed send notification",
    "Certificate not found",
    "Name required",
    "Name on certificate doesnt match",
    "Sheet not found",
    "User course not found",
    "Course not found",
    "File and type length doesnt match",
    "Instructor not found",
    "only for VIDEO course",
    "User sheet not found",
    "Session in sheet not found",
    "Subject in sheet not found",
    "Student hasnt upload any answer yet",
    "Voucher not found",
    "Course not found",
    "Type only accept uk & tpm value",
    "sessionID and subjectNum required for tpm",
    "Session not found in sheet",
    "Subject not found in sheet",
    "Invalid token",
    "redeemCode required to check submission",
    "Voucher Not Found",
    "Session in sheet not found",
    "Finish previous quiz and get at least 80",
    "You already rate this course",
    "You havent purchase this course yet",
    "ERROR FAILED UPLOAD FILES",
    "File required",
    "Fill All Field",
    "sheet not found",
    "Session not found",
    "sessionNum is required",
    "Email already taken",
    "Phone already taken",
    "Code expired",
    "Wrong otp code",
    "Invalid email/phone",
    "Voucher not found",
    "Voucher already exchanged",
    "Wrong course",
    "Batch not found",
    "course with this id not found",
    "Course already has an active promo",
    "Course not found",
    "Promo not found",
  ];
  return (
    translatedError[listOfError.findIndex((el) => el === string)] ||
    "Oops, ada masalah di server kami. Lagi diperbaiki ya!"
  );
}
