import DetailArticleLayout from "@Organism/Article/Detail";
import { getDetailArticle } from "@Services/articles";
import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";

export const loader = ({ params }) => {
  const { id } = params;
  return defer({
    dataDetailArticle: getDetailArticle(id),
  });
};

export default function DetailArticlePage() {
  const { dataDetailArticle } = useLoaderData();
  return (
    <Suspense fallback={<DetailArticleLayout isLoader />}>
      <Await
        resolve={dataDetailArticle}
        errorElement={<h1>Error get data detail</h1>}
      >
        {(value) => <DetailArticleLayout data={value?.data} />}
      </Await>
    </Suspense>
  );
}
