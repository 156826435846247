import React from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import Modal from "@Atom/Modal";

export default function ModalConfirmDelete({
  isOpen,
  onClose,
  onConfirm,
  header,
  body,
  loading,
  confirmButton,
  cancelButton,
}) {
  return (
    <Modal isOpen={isOpen}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <Icon icon={"danger"} size={24} />
          <p>{header}</p>
        </div>
        <div className={Styles.contentWrapper}>
          <p>{body}</p>
        </div>
        <div className={Styles.actionWrapper}>
          <Button
            disabled={loading}
            text={loading ? "Loading..." :cancelButton}
            onClick={onClose}
            variant="outlined"
          />
          <Button disabled={loading} text={loading ? "Loading..." : confirmButton} onClick={onConfirm} />
        </div>
      </div>
    </Modal>
  );
}
