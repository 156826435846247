import Modal from "@Atom/Modal";
import React, { useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import Button from "@Atom/Button";
import { createArticleCategories } from "@Services/articles";

export default function ModalCreateCategories({
  open,
  handleSubmit,
  handleClose,
}) {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false)
  const handleAddCategories = async () => {
      try {
        setLoading(true)
        await createArticleCategories({name});
        setLoading(false)
        handleSubmit(name);
        handleClose()
      } catch (error) {
        setLoading(false)
      }
  }
  return (
    <Modal isOpen={open}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <p className={Styles.title}>Buat Kategori</p>
          <Icon icon={"cross"} size={24} onClick={handleClose} />
        </div>
        <div className={Styles.content}>
          <p>Nama Kategori</p>
          <Input value={name} setValue={setName} placeholder="Masukkan nama ketegori"/>
        </div>
        <div className={Styles.actionWrapper}>
          <Button text="Batal" variant="outlined" onClick={handleClose} disabled={loading}/>
          <Button text={loading ? "Menyimpan..." :"Simpan"} onClick={handleAddCategories} disabled={loading}/>
        </div>
      </div>
    </Modal>
  );
}
