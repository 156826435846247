import { API } from "@Config/index";

export function getAllArticles(page, limit, categories = []) {
  return new Promise(async (resolve, reject) => {
    const categoryQuery = categories
      ?.map((cat) => `&category=${cat}`)
      ?.join("");
    try {
      const { data } = await API.get(
        `/articles/page?page=${page}&limit=${limit}${categoryQuery}`
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getDetailArticle(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/articles/detail/${id}`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAllArticleCategories() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/articles/categories`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function createArticleCategories(body) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/articles/categories`, body, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getDetailArticleAdmin(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/articles/${id}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAdminArticleList(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/articles`, {
        params: { ...query, limit: 5 },
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function createArticle(body) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/articles`, body, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateArticle(id, body) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(`/articles/${id}`, body, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function createArticleDraft(body) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(`/articles/draft`, body, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function deleteArticle(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(`/articles/${id}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data.data);
    } catch (error) {
      reject(error);
    }
  });
}
