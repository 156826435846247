import React, { Fragment, useEffect, useState } from "react";
import Styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import { getDetailArticleAdmin } from "@Services/articles";
import { fileBaseUrl } from "@Config/index";
import moment from "moment";
import ModalDeleteBlog from "@Molecule/_blog/ModalDeleteBlog";
import LoadingContainer from "@Molecule/LoadingContainer";

export default function BlogDetailAdmin() {
  const [data, setData] = useState();
  const { id } = useParams();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getDetailArticleAdmin(id);
        setData(res);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [id]);

  const navigate = useNavigate();
  return (
    <div className={Styles.container}>
      <div className={Styles.breadcrumbsWrapper}>
        <div className={Styles.backIcon} onClick={() => navigate(-1)}>
          <Icon icon={"chevron-left"} size={16} />
        </div>
        <div className={Styles.breadcrumb}>
          <p>Daftar Artikel</p>
          <Icon icon={"chevron-right"} size={16} color={"#3E4856"} />
          <p>{data?.name}</p>
        </div>
      </div>
      {!data ? (
        <div style={{ width: "100%", height: "60vh" }}>
          <LoadingContainer />
        </div>
      ) : (
        <Fragment>
          <div className={Styles.header}>
            <div className={Styles.buttonWrapper}>
              <Button
                text={"Hapus"}
                variant="outlined-danger"
                startIcon={"trash1"}
                onClick={() => setOpenDeleteModal(id)}
              />
              <Button
                text={"Edit Artikel"}
                startIcon={"edit"}
                onClick={() => navigate("edit")}
              />
            </div>
            <div className={Styles.tag} status={data?.status}>
              <p>{data?.status.toLowerCase()}</p>
            </div>
          </div>
          <div className={Styles.contentWrapper}>
            <div className={Styles.imageWrapper}>
              {data?.category && (
                <div className={Styles.category}>
                  <p>{data?.category}</p>
                </div>
              )}

              <img
                src={
                  data?.banner?.url
                    ? fileBaseUrl + data?.banner?.url
                    : "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                }
                alt=""
              />
            </div>
            <div className={Styles.content}>
              <p>{data?.name}</p>
              <div className={Styles.dateWrapper}>
                <Icon icon={"calendar-outlined"} size={20} color={"#ADB5BD"} />
                <p>
                  {moment(data?.updatedAt).format("DD MMMM YYYY")} •{" "}
                  {moment(data?.updatedAt).format("HH:mm")}
                </p>
              </div>
              <div dangerouslySetInnerHTML={{ __html: data?.content }} />
            </div>
          </div>
        </Fragment>
      )}
      <ModalDeleteBlog
        title={data?.name}
        handleClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
      />
    </div>
  );
}
