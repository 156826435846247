import React, { useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Searchbar from "@Atom/Searchbar";
import useQuery from "@Hooks/useQuery";
import Table from "@Molecule/Table";

export default function PrakerjaStatusDetail({ data, loading }) {
  // eslint-disable-next-line no-unused-vars
  const query = useQuery();
  const batchNum = query.get("b");
  const date = query.get("d");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const {pathname} = useLocation()

  const template = useMemo(() => {
    return {
      data: data.filter((el) =>
        el.name.toLowerCase().includes(search.toLowerCase())
      ),
      columns: [
        {
          name: "",
          //   width: width > 768 ? "455px" : "200px",
          title: "Nama Peserta",
          renderData: (row) => <span>{row.name}</span>,
        },
        {
          name: "",
          title: "No. Handphone",
          renderData: (row) => <span>{row?.phoneNumber}</span>,
        },
        {
          name: "",
          align: "right",
          title: "Status Verifikasi",
          renderData: (row) => (
            <div className={Styles.statusContainer}>
              <span
                className={`${Styles.status} ${
                  row?.isVerified ? Styles.success : Styles.notSuccess
                }`}
              >
                {row?.isVerified
                  ? "Sudah Verifikasi Muka"
                  : "Belum Verifikasi Muka"}
              </span>
            </div>
          ),
        },
      ],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, search]);

  return (
    <div className={Styles.container}>
      <div>
        <Button
          variant="text"
          startIcon={"arrow-left-back"}
          text={"Kembali"}
          onClick={() =>
            navigate(`${pathname.split("/").slice(0, 4).join("/") + "?t=3"}`)
          }
        />
      </div>
      <div className={Styles.headSection}>
        <div className={Styles.headText}>
          <p>Verifikasi Muka</p>
          <div className={Styles.bulletPoint}>
            <p>•</p>
          </div>
          <p>
            Batch {batchNum}, {date}
          </p>
        </div>
        <div className={Styles.searchSection}>
          <Searchbar value={search} setValue={setSearch} />
        </div>
      </div>
      <div className={Styles.tableContainer}>
        <Table
          data={template?.data}
          totalData={template?.data?.legth}
          columns={template?.columns}
          withNumbering
          isLoading={loading}
        />
      </div>
    </div>
  );
}
