import { API } from "@Config/index";

export function createClass(payload, isVideo = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.postForm(
        !isVideo ? `/course` : "/course/video-course",
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function editClassInstructor(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patchForm(
        `/instructor/course/${id}`,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            "ngrok-skip-browser-warning": "true",
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAllCourses(
  category,
  platform,
  type,
  level,
  sort,
  page = 1,
  limit = 10,
  search = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/course`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          category,
          platform,
          type,
          level,
          sort,
          page,
          limit,
          search,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getCourse(id = "", isBeforeBuy = false) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/course/${isBeforeBuy ? "bb/" : ""}${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getCoursebyVoucher(voucher) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/voucher/class/${voucher}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateCourseActiveClient(id, clientName = "KINOBI") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(
        `/referral-client/${clientName}`,
        {
          courseID: id,
        },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
