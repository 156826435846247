import React, { Fragment, useEffect, useRef, useState } from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import {
  createArticle,
  createArticleDraft,
  getAllArticleCategories,
  getDetailArticleAdmin,
  updateArticle,
} from "@Services/articles";
import QuillEditor from "@Atom/QuillEditor";
import ModalConfirmDelete from "@Molecule/ModalConfirmDelete";
import ModalSuccess from "@Molecule/ModalSuccess";
import toast from "react-hot-toast";
import { fileBaseUrl } from "@Config/index";
import ModalDeleteBlog from "@Molecule/_blog/ModalDeleteBlog";
import ModalCreateCategories from "@Molecule/_blog/ModalCreateCategories";

export default function BlogCreateAdmin({ isEdit }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const inputRef = useRef();
  const [openConfirmBack, setOpenConfirmBack] = useState(false);
  const [openConfirmUnpublish, setOpenConfirmUnpublish] = useState(false);
  const [openAddCategories, setOpenAddCategories] = useState(false);
  const [modalSuccessCreate, setModalSuccessCreate] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [inputValues, setInputValues] = useState({
    file: null,
    content: "",
    name: "",
    category: "",
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleUploadPhoto = () => {
    inputRef.current.click();
  };
  const handleChangeValue = (name, value) => {
    if (name === "category" && value === "Buat Kategori Lain") {
      setOpenAddCategories(true);
    } else {
      setInputValues({ ...inputValues, [name]: value });
    }
  };
  useEffect(() => {
    const getCateogries = async () => {
      try {
        const { data } = await getAllArticleCategories();
        setCategories(data.map((el) => el.name));
      } catch (error) {
        console.log(error);
      }
    };
    const getData = async () => {
      try {
        const res = await getDetailArticleAdmin(id);
        setData(res);
        setInputValues({
          content: res.content,
          category: res.category,
          name: res.name,
          file: res?.banner?.url,
        });
      } catch (error) {
        console.log(error);
      }
    };
    const getDataDraft = async () => {
      await getCateogries();
      if (isEdit) {
        getData();
      }
    };
    getDataDraft();
  }, [id, isEdit]);

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) handleChangeValue("file", file);
  };

  const handlePublishArticle = async () => {
    try {
      setLoadingSubmit(true);
      if (isEdit) {
        let temp = { ...inputValues };
        if (typeof temp.file === "string") delete temp.file;
        await createArticle(temp);
        toast.success(`Artikel “${inputValues.name}” telah berhasil disimpan`);
      } else {
        const res = await createArticle(inputValues);
        setModalSuccessCreate(res.data._id);
      }
      setLoadingSubmit(false);
    } catch (error) {
      setLoadingSubmit(false);
      toast.error("Gagal membuat artikel");
    }
  };

  const handleSaveArticle = async () => {
    try {
      setLoadingSubmit(true);
      let temp = { ...inputValues };
      if (typeof temp.file === "string") delete temp.file;
      await updateArticle(id, temp);
      toast.success(`Artikel “${inputValues.name}” telah berhasil disimpan`);
      setLoadingSubmit(false);
      navigate("/admin/blog");
    } catch (error) {
      setLoadingSubmit(false);
      toast.error("Gagal membuat artikel");
    }
  };

  const handleUnpublishArticle = async () => {
    try {
      setLoadingSubmit(true);
      await updateArticle(id, { status: "UNPUBLISH" });
      toast.success(
        `Artikel “${inputValues.name}” telah berhasil di-unpublish`
      );
      setLoadingSubmit(false);
      navigate("/admin/blog");
    } catch (error) {
      setLoadingSubmit(false);
      toast.error("Gagal membuat artikel");
    }
  };

  const handlePublishStatusArticle = async () => {
    try {
      setLoadingSubmit(true);
      let temp = { ...inputValues };
      if (typeof temp.file === "string") delete temp.file;
      await updateArticle(id, { ...temp, status: "PUBLISH" });
      setModalSuccessCreate(data._id);
      setLoadingSubmit(false);
    } catch (error) {
      setLoadingSubmit(false);
      toast.error("Gagal publish artikel");
    }
  };

  const handleCreateArticleDraft = async () => {
    try {
      setLoadingSubmit(true);
      const res = await createArticleDraft(inputValues);
      toast.success(`Artikel ${res.data.name} berhasil disimpan sebagai draft`);
      navigate(-1);
      setLoadingSubmit(false);
    } catch (error) {
      setLoadingSubmit(false);
      toast.error("Gagal membuat artikel");
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.buttonWrapper}>
        <Button
          text={"Kembali"}
          onClick={() => setOpenConfirmBack(true)}
          startIcon={"chevron-left"}
          variant="text"
        />
        <div>
          {isEdit ? (
            <Fragment>
              {data?.status === "PUBLISH" && (
                <Button
                  text={loadingSubmit ? "loading..." : "Unpublish Artikel"}
                  variant="text"
                  startIcon={"work-scheme"}
                  disabled={loadingSubmit}
                  onClick={() => setOpenConfirmUnpublish(true)}
                />
              )}
              <Button
                text={loadingSubmit ? "loading..." : "Delete"}
                variant="outlined-danger"
                startIcon={"trash1"}
                disabled={loadingSubmit}
                onClick={() => setOpenDeleteModal(id)}
              />
              {data?.status === "DRAFT" && (
                <Button
                  text={loadingSubmit ? "loading..." : "Simpan Ke Draft"}
                  variant="outlined"
                  startIcon={"work-scheme"}
                  disabled={loadingSubmit}
                  onClick={handleSaveArticle}
                />
              )}
            </Fragment>
          ) : (
            <Button
              text={loadingSubmit ? "loading..." : "Simpan Ke Draft"}
              variant="outlined"
              startIcon={"work-scheme"}
              disabled={loadingSubmit}
              onClick={handleCreateArticleDraft}
            />
          )}
          {data?.status === "PUBLISH" ? (
            <Button
              text={loadingSubmit ? "loading..." : "Simpan"}
              startIcon={"folder-add"}
              onClick={handleSaveArticle}
              disabled={loadingSubmit}
            />
          ) : (
            <Button
              text={loadingSubmit ? "loading..." : "Publish"}
              startIcon={"send-solid"}
              onClick={
                isEdit ? handlePublishStatusArticle : handlePublishArticle
              }
              disabled={loadingSubmit}
            />
          )}
        </div>
      </div>
      <div className={Styles.inputWrapper}>
        <div className={Styles.input}>
          <p>Banner & Thumbnail</p>
          <input
            ref={inputRef}
            hidden
            type="file"
            accept="image/*"
            onChange={handleChangeFile}
          />
          {inputValues.file ? (
            <div className={Styles.imagePreviewWrapper}>
              <div className={Styles.overlay}>
                <Button
                  text={"Hapus"}
                  variant="neutral-outlined"
                  startIcon={"trash1"}
                  onClick={() => handleChangeValue("file", "")}
                />
                <Button
                  text={"Ubah Foto"}
                  variant="neutral-outlined"
                  startIcon={"edit-2"}
                  onClick={handleUploadPhoto}
                />
              </div>
              <img
                src={
                  typeof inputValues.file === "string"
                    ? fileBaseUrl + inputValues.file
                    : URL.createObjectURL(inputValues.file)
                }
                alt=""
              />
            </div>
          ) : (
            <div className={Styles.imageInputWrapper}>
              <Icon
                icon={"document-upload-filled"}
                size={48}
                color={"#2E3192"}
              />
              <p>Unggah Gambar</p>
              <p>{`Unggah file dengan format JPEG/JPG,
            resolusi 1920x1080`}</p>
              <Button
                text={"Browse File"}
                variant="outlined"
                onClick={handleUploadPhoto}
              />
            </div>
          )}
        </div>
        <div className={Styles.input}>
          <p>Judul Artikel</p>
          <Input
            placeholder="Masukkan judul artikel"
            value={inputValues.name}
            setValue={(value) => handleChangeValue("name", value)}
          />
        </div>
        <div className={Styles.input}>
          <p>Kategori</p>
          <Input
            placeholder="Pilih kategori artikel"
            isDropdown
            dropdownOptions={[...categories, "Buat Kategori Lain"]}
            value={inputValues.category}
            setValue={(value) => handleChangeValue("category", value)}
          />
        </div>
        <QuillEditor
          value={inputValues.content}
          onChange={(value) => handleChangeValue("content", value)}
          type="full"
        />
      </div>
      <ModalConfirmDelete
        isOpen={openConfirmBack}
        header={"Artikel Belum Disimpan"}
        body={`Artikel yang kamu tulis belum disimpan, bila kamu keluar dari halaman ini maka seluruh tulisan kamu akan hilang.`}
        onClose={() => navigate(-1)}
        onConfirm={isEdit ? handleSaveArticle : handleCreateArticleDraft}
        confirmButton={isEdit ? "Ya, Simpan Artikel" : "Simpan Sebagai Draft"}
        cancelButton={isEdit ? "Keluar" : "Keluar dan Hapus Artikel"}
      />
      <ModalSuccess
        isOpen={modalSuccessCreate}
        header={"Artikel Berhasil Dipublikasikan"}
        onConfirm={() => navigate(-1)}
        confirmButton={"Tutup"}
      >
        <p style={{ marginBottom: "16px" }}>
          Artikel <strong>“{inputValues?.name}”</strong> telah berhasil
          dipublikasikan. Anda dapat melihat artikel pada link berikut ini:
        </p>
        <p
          className={Styles.hyperlink}
          onClick={() =>
            window.open(`https://www.skills.id/article/${modalSuccessCreate}`)
          }
        >
          🔗https://www.skills.id/article/{modalSuccessCreate}
        </p>
      </ModalSuccess>
      <ModalDeleteBlog
        title={inputValues?.name}
        handleClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
      />
      <ModalConfirmDelete
        isOpen={openConfirmUnpublish}
        header={"Unpublish Artikel"}
        body={`Apakah kamu yakin ingin meng-unpublish artikel “${inputValues.name}”`}
        onClose={() => navigate(-1)}
        onConfirm={handleUnpublishArticle}
        confirmButton={"Ya, Unpublish Artikel"}
        cancelButton={"Batal"}
      />
      {openAddCategories && (
        <ModalCreateCategories
          open={openAddCategories}
          handleClose={() => setOpenAddCategories(false)}
          handleSubmit={(val) => {
            setCategories((prev) => [...prev, val]);
            handleChangeValue("category", val);
          }}
        />
      )}
    </div>
  );
}
