import React, { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import AccordionAdmin from "@Molecule/AccordionAdmin";
import moment from "moment";
import Icon from "@Atom/Icon";
import Button from "@Atom/Button";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDataVerificationStatusVideo } from "@Services/admin";
import Table from "@Molecule/Table";
import { useDebounce } from "@Hooks/useDebounce";
import Searchbar from "@Atom/Searchbar";
import ToolTip from "@Molecule/ToolTip/inde";
import InputField from "@Molecule/InputField";

export default function AbsenceClassAdmin({ data, dataClass }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataVerification, setDataVerification] = useState({
    data: [],
    totalData: 0,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedTrigger = useDebounce(search, 500);
  const [batch, setBatch] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filter, setFilter] = useState({
    batch: null,
    startDate: null,
    endDate: null,
  });
  const [openFilter, setOpenFilter] = useState(false);

  const handleResetFilter = () => {
    setFilter({
      batch: null,
      startDate: null,
      endDate: null,
    });
  };

  const getVideoVerivicationStatus = async () => {
    try {
      setLoading(true);
      const res = await fetchDataVerificationStatusVideo(
        id,
        page,
        limit,
        search
      );
      setLoading(false);
      if (res.status) {
        setDataVerification(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [debouncedTrigger]);

  useEffect(() => {
    if (dataClass.platform === "VIDEO") {
      getVideoVerivicationStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, debouncedTrigger]);

  const template = useMemo(() => {
    return {
      data: dataVerification.data,
      columns: [
        {
          name: "name",
          title: "Nama Peserta",
        },
        {
          name: "",
          title: "Tanggal pembelian Kelas",
          renderData: (row) => (
            <span>{moment(row.buyDate).format("DD MMMM YYYY")}</span>
          ),
        },
        {
          name: "",
          title: "Sesi 1",
          renderData: (row) => (
            <ToolTip
              position="top"
              headerText="Tanggal Verifikasi Muka"
              description={
                row.verificationResults[0]?.isVerified ? (
                  <span>
                    {moment(row.verificationResults[0].whenVerified).format(
                      "DD MMMM YYYY, HH:mm"
                    )}
                  </span>
                ) : (
                  "Peserta belum melakukan verifikasi"
                )
              }
            >
              <Icon
                icon={"checklist"}
                size={24}
                color={
                  row.verificationResults[0]?.isVerified ? "#60AA15" : "#C2C2C2"
                }
              />
            </ToolTip>
          ),
        },
        {
          name: "",
          title: "Sesi 2",
          renderData: (row) => (
            <ToolTip
              position="top"
              headerText="Tanggal Verifikasi Muka"
              description={
                row.verificationResults[1]?.isVerified ? (
                  <span>
                    {moment(row.verificationResults[0].whenVerified).format(
                      "DD MMMM YYYY, HH:mm"
                    )}
                  </span>
                ) : (
                  "Peserta belum melakukan verifikasi"
                )
              }
            >
              <Icon
                icon={"checklist"}
                size={24}
                color={
                  row.verificationResults[1]?.isVerified ? "#60AA15" : "#C2C2C2"
                }
              />
            </ToolTip>
          ),
        },
        {
          name: "",
          title: "Sesi 3",
          renderData: (row) => (
            <ToolTip
              position="top"
              headerText="Tanggal Verifikasi Muka"
              description={
                row.verificationResults[2]?.isVerified ? (
                  <span>
                    {moment(row.verificationResults[0].whenVerified).format(
                      "DD MMMM YYYY, HH:mm"
                    )}
                  </span>
                ) : (
                  "Peserta belum melakukan verifikasi"
                )
              }
            >
              <Icon
                icon={"checklist"}
                size={24}
                color={
                  row.verificationResults[2]?.isVerified ? "#60AA15" : "#C2C2C2"
                }
              />
            </ToolTip>
          ),
        },
        {
          name: "",
          title: "Sesi 4",
          renderData: (row) => (
            <ToolTip
              position="top"
              headerText="Tanggal Verifikasi Muka"
              description={
                row.verificationResults[3]?.isVerified ? (
                  <span>
                    {moment(row.verificationResults[0].whenVerified).format(
                      "DD MMMM YYYY, HH:mm"
                    )}
                  </span>
                ) : (
                  "Peserta belum melakukan verifikasi"
                )
              }
            >
              <Icon
                icon={"checklist"}
                size={24}
                color={
                  row.verificationResults[3]?.isVerified ? "#60AA15" : "#C2C2C2"
                }
              />
            </ToolTip>
          ),
        },
        {
          name: "",
          title: "Sesi 5",
          renderData: (row) => (
            <ToolTip
              position="top"
              headerText="Tanggal Verifikasi Muka"
              description={
                row.verificationResults[4]?.isVerified ? (
                  <span>
                    {moment(row.verificationResults[0].whenVerified).format(
                      "DD MMMM YYYY, HH:mm"
                    )}
                  </span>
                ) : (
                  "Peserta belum melakukan verifikasi"
                )
              }
            >
              <Icon
                icon={"checklist"}
                size={24}
                color={
                  row.verificationResults[4]?.isVerified ? "#60AA15" : "#C2C2C2"
                }
              />
            </ToolTip>
          ),
        },
      ],
    };
  }, [dataVerification]);

  const filterBatch = useMemo(() => {
    let temp = [...data.batches.map((el, idx) => ({ ...el, num: idx + 1 }))];
    if (filter.batch) {
      return [temp[filter.batch - 1]];
    }
    if (filter.startDate) {
      temp = temp.filter(
        (el) => new Date(el.endDate) > new Date(filter.startDate)
      );
    }

    if (filter.endDate) {
      temp = temp.filter(
        (el) => new Date(el.startDate) < new Date(filter.endDate)
      );
    }

    return temp;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const filterCount = useMemo(() => {
    let count = 0;
    if (filter.batch) {
      count += 1;
    }
    if (filter.startDate) {
      count += 1;
    }
    if (filter.endDate) {
      count += 1;
    }
    return count;
  }, [filter]);

  console.log(dataClass);

  return (
    <div className={Styles.container}>
      {dataClass.platform !== "VIDEO" ? (
        <div className={Styles.batchContainer}>
          <div className={Styles.filterContainer}>
            {(filter.batch || filter.startDate || filter.endDate) && (
              <div>
                <p onClick={handleResetFilter}>Reset</p>
              </div>
            )}
            <div
              className={Styles.filterButton}
              onClick={() => setOpenFilter(!openFilter)}
              is-active={JSON.stringify(!!filterCount)}
            >
              <Icon
                icon={"setting-4"}
                size={20}
                color={filterCount ? "#9360A8" : ""}
              />
              <p>Filter</p>

              <div className={Styles.filterCount}>
                <p>{filterCount}</p>
              </div>
            </div>
            <div
              className={Styles.dropdownContainer}
              is-open={JSON.stringify(openFilter)}
            >
              <div className={Styles.dropdownHeader}>
                <p>Filter</p>
                <p
                  onClick={() => {
                    setBatch();
                    setStartDate();
                    setEndDate();
                  }}
                >
                  Reset
                </p>
              </div>
              <InputField
                title="Nomor Batch"
                placeholder="Contoh 10"
                value={batch}
                setValue={setBatch}
                isNumber
              />
              <div className={Styles.filterDateWrapper}>
                <p>Tanggal Batch</p>
                <div className={Styles.filterDate}>
                  <InputField
                    placeholder="dari"
                    isDate
                    value={startDate}
                    setValue={setStartDate}
                  />
                  <p>-</p>
                  <InputField
                    title={""}
                    placeholder="Sampai"
                    isDate
                    value={endDate}
                    setValue={setEndDate}
                  />
                </div>
              </div>
              <div className={Styles.filterAction}>
                <Button
                  text={"Kembali"}
                  variant="outlined"
                  onClick={() => setOpenFilter(false)}
                />
                <Button
                  text={"Terapkan"}
                  onClick={() => {
                    setFilter({ batch, startDate, endDate });
                    setOpenFilter(false);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={Styles.batchWrapper}>
            {filterBatch.map((el, idx) => (
              <div key={idx}>
                <AccordionAdmin
                  head={
                    <div className={Styles.accordionHead}>
                      <div>
                        <Icon icon={"calendar2"} size={20} color={"#FFFFFF"} />
                      </div>
                      <div>
                        <p>Batch {el.num}</p>
                        <p>
                          {moment(el.startDate).format("DD")} -{" "}
                          {moment(el.endDate).format("DD MMM YYYY")}
                        </p>
                      </div>
                    </div>
                  }
                  body={
                    <div className={Styles.scheduleBody}>
                      {el.sessions.map((session, idxSession) => (
                        <div className={Styles.scheduleCard} key={idxSession}>
                          <div className={Styles.cardHead}>
                            <p>Sesi {idxSession + 1}</p>
                            <p>{moment(session.date).format("DD MMMM YYYY")}</p>
                          </div>
                          <div className={Styles.buttonWrapper}>
                            {dataClass.platform === "OFFLINE" && (
                              <Button
                                variant="outlined"
                                text={"Atur Absensi"}
                                onClick={() =>
                                  navigate(
                                    `absence?k=1&s=${idxSession + 1}&b=${
                                      idx + 1
                                    }`
                                  )
                                }
                              />
                            )}
                            {dataClass.platform === "WEBINAR" &&
                              dataClass.type === "PRAKERJA" && (
                                <Button
                                  variant="outlined"
                                  text={"Verifikasi Muka"}
                                  onClick={() =>
                                    navigate(
                                      `status?k=1&s=${idxSession + 1}&b=${
                                        idx + 1
                                      }&d=${moment(session.date).format(
                                        "DD MMMM YYYY"
                                      )}`
                                    )
                                  }
                                />
                              )}
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <div className={Styles.topWrapper}>
            <Searchbar value={search} setValue={setSearch} />
            <div className={Styles.mapLegendWrapper}>
              <div className={Styles.mapLegend}>
                <Icon icon={"checklist"} size={20} color={"#60AA15"} />
                <p>Sudah Verifikasi Muka</p>
              </div>
              <div className={Styles.mapLegend}>
                <Icon icon={"checklist"} size={20} color={"#C2C2C2"} />
                <p>Belum Verifikasi Muka</p>
              </div>
            </div>
          </div>
          <div className={Styles.tableContainer}>
            <Table
              data={template?.data}
              totalData={dataVerification?.totalData}
              totalPage={1}
              columns={template?.columns}
              page={page}
              setPage={setPage}
              className={`${Styles.table}`}
              limit={limit}
              setLimit={setLimit}
              withPagination
              withNumbering
              isLoading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
}
