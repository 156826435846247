import React, { useState } from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import Images from "@Theme/Images";
import WaveTsunami from "@Assets/Icon/wave-tsunami";
import Modal from "@Atom/Modal";
import ImgModalNotAvailable from "@Assets/Images/flagship-empty.png";
import { Link, useNavigate } from "react-router-dom";
import useWindowSize from "@Hooks/useWindowSize";
export default function FlagshipProgram() {
  const { width } = useWindowSize();
  return (
    <section className={Styles.sectionFlagshipProgram}>
      <div className={Styles.container}>
        <div className={Styles.description}>
          <div>
            <h1>Program Unggulan Dari Skills.id</h1>
            {width > 768 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="195"
                height="18"
                viewBox="0 0 195 18"
                fill="none"
                className={Styles.underline}
              >
                <path
                  d="M4.43511 13.0906C42.2244 8.08572 81.4813 9.61638 119.402 6.49214C129.899 6.43455 139.019 5.29843 148.896 5.17322C162.802 4.99693 176.6 4.47479 190.507 4.96042"
                  stroke="#5ED9E9"
                  stroke-width="8"
                  stroke-linecap="round"
                />
              </svg>
            ) : (
              <svg
                className={Styles.underline}
                xmlns="http://www.w3.org/2000/svg"
                width="104"
                height="11"
                viewBox="0 0 104 11"
                fill="none"
              >
                <path
                  d="M2.00032 8.28434C22.5045 3.87616 43.3475 5.87969 63.7942 3.31279C69.3992 3.39185 74.3424 2.39817 79.6213 2.40308C87.0532 2.41 94.4514 2.07722 101.839 2.73161"
                  stroke="#5ED9E9"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </svg>
            )}
          </div>
          <p>
            Skills.id tidak hanya menyediakan pelatihan online, kami juga
            menyediakan berbagai program seru lainnya yang bisa bantu kamu
            berkembang. Yuk, cek daftar program unggulan kami yang mungkin cocok
            untuk kebutuhan pengembangan diri kamu!
          </p>
        </div>
        <div className={Styles.wrapperCard}>
          <Card type="education" />
          <Card />
          <Card type="bootcamp" />
          <Card type="corporation" />
        </div>
      </div>
    </section>
  );
}

function Card({ type = "prakerja" }) {
  const navigate = useNavigate();
  const isEducation = type === "education";
  const isBootcamp = type === "bootcamp";
  const isCorporation = type === "corporation";

  const [isOpenProgram, setIsOpenProgram] = useState(false);

  return (
    <>
      <Modal isOpen={isOpenProgram} onClose={() => void {}}>
        <div className={Styles.modalAlert}>
          <div className={Styles.title}>
            <h4>
              🚀 Program {isBootcamp ? "Bootcamp" : "skills for corporation"}{" "}
              Kami Akan Segera Hadir! 🔥
            </h4>
            <Icon
              icon={"cross"}
              color={"#000"}
              size={"24"}
              className={Styles.closeIcon}
              onClick={() => setIsOpenProgram(false)}
            />
          </div>
          <p>
            Jangan lewatkan kesempatan untuk bergabung dalam program{" "}
            {isBootcamp ? "bootcamp " : "skills corporation "}
            kami! Untuk info lebih lanjut, silakan hubungi admin kami. 
          </p>
          <img src={ImgModalNotAvailable} alt="alert" />
          <Link
            to={"https://wa.me/+628118286996"}
            className={Styles.whatsAppButton}
          >
            <Icon icon={"whatsapp"} color={"#fff"} size={"20"} />
            <span>Hubungi Kami</span>
          </Link>
        </div>
      </Modal>
      <div
        className={Styles.card}
        onClick={() =>
          isBootcamp || isCorporation
            ? setIsOpenProgram(true)
            : navigate(isEducation ? "education" : "prakerja")
        }
      >
        <div
          className={`${Styles.imagesBg} ${
            isEducation
              ? Styles.education
              : isBootcamp
              ? Styles.bootcamp
              : isCorporation
              ? Styles.corporation
              : Styles.prakerja
          }`}
        >
          <WaveTsunami
            color={
              isEducation
                ? "#E9F8E8"
                : isBootcamp
                ? "#DED3FA"
                : isCorporation
                ? "#F3FFD4"
                : "#C9DFFA"
            }
            className={Styles.wave}
            width="640"
            height="120"
          />
          <img
            src={
              isEducation
                ? Images.SKILLS_EDUCATION
                : isBootcamp
                ? Images.SKILLS_BOOTCAMP
                : isCorporation
                ? Images.SKILLS_CORPORATION
                : Images.LOGO_PRAKERJA
            }
            alt={type}
          />
        </div>

        <div className={Styles.wrapperDescription}>
          <div className={Styles.description}>
            <h4>
              {isEducation
                ? "Skills for Education"
                : isBootcamp
                ? "Bootcamp"
                : isCorporation
                ? "Skills for Corporation"
                : "Prakerja"}
            </h4>
            <p>
              {isEducation
                ? "Skills.id menyediakan layanan e-learning untuk memudahkan proses belajar dan mengajar yang interaktif."
                : isBootcamp
                ? "Skills.id menyediakan program pelatihan intensif yang dirancang untuk memberikan keterampilan dan pengetahuan dalam waktu yang relatif singkat."
                : isCorporation
                ? "Skills.id dapat bekerja sama dengan berbagai perusahaan untuk memberikan layanan pelatihan bagi karyawan."
                : "Skills.id bekerja sama dengan pemerintah dalam menjalankan program beasiswa pelatihan untuk meningkatkan kompetensi kerja dan kewirausahaan."}
            </p>
          </div>
          <div className={Styles.roundedIcon}>
            <Icon
              icon={
                isEducation
                  ? "arrow-up-right"
                  : isBootcamp
                  ? "lock"
                  : isCorporation
                  ? "lock"
                  : "arrow-up-right"
              }
              color={"#795EC6"}
              size={"18"}
              className={Styles.icon}
            />
          </div>
        </div>
      </div>
    </>
  );
}
