import React from "react";
import Styles from "./style.module.scss";
import HeroImg from "@Assets/Images/img-discuss.png";

export default function DescribeSkillsEducation() {
  return (
    <div className={Styles.describePrakerja} id="describe-skills-education">
      <img src={HeroImg} alt="people-mockup" />
      <div className={Styles.wrapperText}>
        <h1>
          Apa itu <span>Skills for Education</span>
        </h1>
        <div className={Styles.text}>
          <p>
            {`Skills.id menawarkan program kerjasama untuk meningkatkan mutu pendidikan melalui layanan Learning Management System (LMS) yang kami sediakan. Dengan sistem yang terintegrasi, kami membantu institusi pendidikan, komunitas belajar, dan sekolah dalam mengelola pembelajaran secara efektif dan efisien.

Salah satu contoh sukses dari implementasi program ini adalah Tree Homeschool, yang telah memanfaatkan LMS dari Skills.id untuk mendukung proses belajar-mengajar mereka. Dengan penggunaan platform ini, Tree Homeschool berhasil meningkatkan keterlibatan siswa, memperbaiki sistem administrasi pembelajaran, serta menciptakan pengalaman belajar yang lebih interaktif dan terstruktur.

 Melalui program ini, kami berkomitmen untuk terus mendukung ekosistem pendidikan dalam menghadapi tantangan pembelajaran di era digital.
            `}
          </p>
        </div>
      </div>
    </div>
  );
}
