import Button from "@Atom/Button";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import { priceFormat } from "@Helpers/priceFormat";
import useWindowSize from "@Hooks/useWindowSize";
import Footer from "@Molecule/Footer";
import Table from "@Molecule/Table";
import Images from "@Theme/Images";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import { useEffect } from "react";
import { getAllTransaction } from "@Services/admin";
import { fileBaseUrl } from "@Config/index";

export default function AdminTransactionLayout() {
  const user = decryptStorageData("user", localStorage);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [transactionType, setTransactionType] = useState("PAID-OFF");
  const [data, setData] = useState({
    data: [],
    totalData: 0,
  });
  const [loading, setLoading] = useState(false);

  const { width } = useWindowSize();

  console.log(data);

  const template = useMemo(() => {
    return {
      data: data.data,
      columns: [
        {
          name: "courseTitle",
          title: "Judul Kelas",
          hideOnMobile: true,
          renderData: (row) => (
            <span
              className={Styles.invoiceNumber}
              onClick={() => navigate(`${pathname}/${row?._id}`)}
            >
              {row?.courseTitle}
            </span>
          ),
        },
        {
          name: "referralCode",
          title: "Transaksi Melalui",
          renderData: (row) => (
            <div className={Styles.referral}>
              <img
                src={
                  row.referralCode === "ref-00001"
                    ? fileBaseUrl + "1742182020252-kinobi.png"
                    : Images.LOGO
                }
                alt="referral"
              />
              <span>
                {row.referralCode === "ref-00001" ? "Kinobi" : "Skills.id"}
              </span>
            </div>
          ),
        },
        {
          name: "invoiceCode",
          title: "Nomor Invoice",
          renderData: (row) => (
            <span
              className={Styles.invoiceNumber}
              onClick={() => navigate(`${pathname}/${row?._id}`)}
            >
              {row?.invoiceCode}
            </span>
          ),
        },
        {
          name: "price",
          title: "Harga Kelas",
          hideOnMobile: true,
          renderData: (row) => <span>Rp{priceFormat(+row?.price.actual)}</span>,
        },
      ],
    };
  }, [data.data, navigate, pathname]);

  const getTransactionList = async () => {
    try {
      setLoading(true);
      const res = await getAllTransaction(page, limit, transactionType);
      setData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getTransactionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, transactionType]);

  const tabs = useMemo(() => {
    return [
      {
        title: "Transaksi Selesai",
        type: "PAID-OFF",
      },
      {
        title: "Menunggu Pembayaran",
        type: "PENDING",
      },
      {
        title: "Transaksi Dibatalkan",
        type: "FAILED",
      },
    ];
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.wrapper}>
        <DoodleGradientCard className={Styles.header}>
          <div className={Styles.title}>
            <h3>Selamat Datang {<span>{user?.name}</span>}</h3>
            <span>Berikut adalah daftar seluruh transaksi di Skills.id</span>
          </div>
          {width > 768 && (
            <img className={Styles.img} src={Images.MONEY_DOCUMENT} alt="" />
          )}
        </DoodleGradientCard>
        <div className={Styles.tabs}>
          {tabs?.map((t, tI) => (
            <Button
              key={tI}
              text={t?.title}
              variant="tab"
              isActive={transactionType === t?.type}
              onClick={() => {
                setPage(1);
                setTransactionType(t?.type);
              }}
            />
          ))}
        </div>

        <Table
          data={template?.data}
          totalData={data?.totalData}
          totalPage={data?.totalData ? Math.ceil(data?.totalData / limit) : 0}
          columns={template?.columns}
          page={page}
          setPage={setPage}
          className={`${Styles.table} ${
            !template?.data?.length ? Styles.grow : ""
          }`}
          limit={limit}
          setLimit={setLimit}
          withNumbering
          withPagination
          isLoading={loading}
          expandComponent={(row) => (
            <div className={Styles.expandedInfo}>
              <div className={Styles.infoRow}>
                <span>Judul Kelas</span>
                <span>{row?.courseTitle}</span>
              </div>
              <div className={Styles.infoRow}>
                <span>Harga</span>
                <span>Rp{priceFormat(row?.price.actual)}</span>
              </div>
            </div>
          )}
        />
      </div>

      <Footer />
    </div>
  );
}
