import React, { useMemo, useRef, useState } from "react";
import Style from "./style.module.scss";

import Icon from "@Atom/Icon";
import { fileBaseUrl } from "@Config/index";
import useWindowSize from "@Hooks/useWindowSize";
export default function BannerLandingPage({ className }) {
  const { width } = useWindowSize();

  const ImgBannerToShow = useMemo(() => {
    const ImgWebsiteBootcamp = "1739861805934-website-bootcamp.jpg";
    const ImgMobileBootcamp = "1739861653030-mobile-bootcamp.jpg";
    const ImgMobileWorkshop = "1739861727553-mobile-workshop.jpg";
    const ImgWebsiteWorkshop = "1739861828424-website-workshop.jpg";
    if (width < 768) {
      return [ImgMobileBootcamp, ImgMobileWorkshop];
    } else return [ImgWebsiteBootcamp, ImgWebsiteWorkshop];
  }, [width]);

  const [counterX, setCounterX] = useState(0);
  const carouselRef = useRef();
  const [imageWidth, setImageWidth] = useState(0);

  const nextImage = () => {
    setCounterX((prevIndex) =>
      prevIndex === ImgBannerToShow.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCounterX((prevIndex) =>
      prevIndex === 0 ? ImgBannerToShow.length - 1 : prevIndex - 1
    );
  };

  const touchStartX = useRef(0);
  const touchMoveX = useRef(0);

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchMoveX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchMoveX.current > 50) {
      if (counterX === 1) return;
      nextImage();
    } else if (touchMoveX.current - touchStartX.current > 50) {
      if (counterX === 0) return;
      prevImage();
    }
  };

  return (
    <div className={className}>
      <div className={Style.wrapperBanner}>
        <div className={Style.relativeButton}>
          <div className={Style.leftIcon} onClick={prevImage}>
            <Icon icon={"arrow-left"} className={Style.iconL} />
          </div>
          <div className={Style.rightIcon} onClick={nextImage}>
            <Icon icon={"arrow-left"} className={Style.iconR} />
          </div>
          <div
            className={Style.wrapperCarousel}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {ImgBannerToShow.map((i, idx) => (
              <img
                key={idx}
                src={fileBaseUrl + i}
                alt="banner-carousel"
                className={Style.active}
                ref={carouselRef}
                style={{
                  transform: `translateX(-${counterX * imageWidth}px)`,
                }}
                onLoad={() => setImageWidth(carouselRef.current.offsetWidth)}
              />
            ))}
          </div>
        </div>

        <section className={Style.currentActve}>
          {ImgBannerToShow.map((_, index) => (
            <div
              key={index}
              className={index === counterX ? Style.isActived : Style.isActive}
            ></div>
          ))}
        </section>
      </div>
    </div>
  );
}
