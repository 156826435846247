import { API } from "@Config/index";
import { decryptStorageData } from "@Helpers/encryptDecrypt";

export function login(emailOrPhone = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/login`, { emailOrPhone });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function loginVerification(hash = "", otp = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/verifyOtp/login`, { hash, otp });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function register(name = "", email = "", phoneNumber = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/register`, {
        name,
        email,
        phoneNumber,
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function registerVerification(hash = "", otp = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/user/verifyOtp`, { hash, otp });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAllInstructors(name = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/user/instructor`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          name,
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function changeUserEmail(email) {
  return new Promise(async (resolve, reject) => {
    const user = decryptStorageData("user", localStorage);
    try {
      const data = await API.patch(`/user/email/${user._id}`, email, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function userEmailVerification(payload) {
  return new Promise(async (resolve, reject) => {
    const user = decryptStorageData("user", localStorage);
    try {
      const data = await API.patch(`/user/verify/${user.email}`, payload, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getOtpForVerificationEmail() {
  return new Promise(async (resolve, reject) => {
    const user = decryptStorageData("user", localStorage);
    try {
      const { data } = await API.get(`/user/email/${user.email}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
