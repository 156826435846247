import React, { useState } from "react";
import Styles from "./style.module.scss";
import DoodleGradientCard from "@Atom/DoodleGradientCard";
import TransparentSearchbar from "@Atom/TransparentSearchbar";
import Images from "@Theme/Images";
import exampleImage from "@Assets/Images/exampleImageClass.png";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import feedbackChat from "@Assets/Images/feedback_chat.png";
import Footer from "@Molecule/Footer";
import { decryptStorageData } from "@Helpers/encryptDecrypt";
import NotFound from "@Molecule/NotFound";
import { useNavigate } from "react-router-dom";
export default function Certificate({
  instructor = "Lauren Octavia",
  titleClass = "Merancang Strategi Branding untuk Spesialisasi dan Pemasaran Digital Diera Globalisasi dan Tech Winter",
  isPrakerja,
  image = exampleImage,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(!isOpen);
  };
  const user = decryptStorageData("user", localStorage);
  const [ratingStar, setRatingStar] = useState();
  const handleRatingStar = (r) => {
    if (ratingStar === r) {
      setRatingStar(0);
    } else {
      setRatingStar(r);
    }
  };
  const navigate = useNavigate();
  return (
    <div className={Styles.container}>
      <div className={Styles.layout}>
        <section className={Styles.doodle}>
          <DoodleGradientCard>
            <div className={Styles.topCard}>
              <div className={Styles.leftSection}>
                <div>
                  <p className={Styles.title}>
                    Selamat Datang <span>{user?.name}</span>
                  </p>
                  <p className={Styles.desc}>
                    Berikut daftar sertifikat milikmu, silahkan download untuk
                    melihat sertifikat
                  </p>
                </div>
                <TransparentSearchbar placeholder="Cari Sertifikatmu" />
              </div>
              <div className={Styles.rightSection}>
                <img
                  src={Images.STUDENT_DOODLE_ICON}
                  width={"168px"}
                  height={"168px"}
                  alt="icon"
                />
              </div>
            </div>
          </DoodleGradientCard>
        </section>
        <section className={Styles.tabsHeader}>
          {/* <div className={Styles.wrapperCard}>
            {[1, 2, 4, 5].map((_, idx) => (
              <div className={Styles.card} key={idx}>
                <img src={image} className={Styles.images} alt="images" />
                <div className={Styles.describeClass}>
                  <div className={Styles.classDescription}>
                    <div className={Styles.text}>
                      <div className={Styles.typeClass}>
                        {isPrakerja ? (
                          <img
                            src={iconPrakerja}
                            alt="prakerja"
                            width={"43.269px"}
                            height={"13.823px"}
                          />
                        ) : (
                          <div>
                            <span>Kelas Regular</span>
                          </div>
                        )}
                      </div>
                      <div className={Styles.textEllipsis}>
                        <p>{titleClass}</p>
                      </div>
                      <p>{instructor}</p>
                    </div>
                    <div
                      className={Styles.buttonCard}
                      onClick={handleOpenModal}
                    >
                      <span> Unduh Sertifikat</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          <NotFound
            isStudent={true}
            onClick={() => navigate("/courses")}
            title="Sekarang kamu belum punya Sertifikat nih"
          />
        </section>
        <Modal isOpen={isOpen}>
          <div className={Styles.modalContainer}>
            <section>
              <div className={Styles.headerModal}>
                <h1>Cetak Sertifikat</h1>
                <Icon
                  icon={"cross"}
                  onClick={handleOpenModal}
                  size={"24px"}
                  className={Styles.closeIcon}
                />
              </div>
              <main className={Styles.main}>
                <div className={Styles.text}>
                  <p>
                    Sertifikat pelatihan ini diberikan atas nama{" "}
                    <span>{user?.name}</span> Silahkan berikan rating dan
                    feedback untuk kelas ini
                  </p>
                </div>
                <div className={Styles.review}>
                  <div className={Styles.cardRating}>
                    <div className={Styles.rating}>
                      <h1>Rating</h1>
                      <div className={Styles.star}>
                        {[1, 2, 3, 4, 5].map((item, idx) => (
                          <Icon
                            key={idx}
                            icon={ratingStar >= item ? "star" : "Star-Atom"}
                            color={ratingStar >= item ? "#ffa534" : "#BABABA"}
                            size={"32"}
                            className={Styles.iconStars}
                            onClick={() => handleRatingStar(item)}
                          />
                        ))}
                      </div>
                      <div className={Styles.expression}>
                        <span
                          style={
                            ratingStar === 1 ? { color: "black" } : undefined
                          }
                        >
                          Sangat Buruk
                        </span>
                        <span
                          style={
                            ratingStar === 5 ? { color: "black" } : undefined
                          }
                        >
                          Sangat Baik
                        </span>
                      </div>
                    </div>
                    <div className={Styles.feedback}>
                      <h1>Feedback</h1>
                      <textarea placeholder="Feedback" />
                    </div>
                  </div>
                  <div className={Styles.buttonModal}>
                    <div onClick={() => setIsOpen(false)}>
                      <span>Kembali</span>
                    </div>
                    <div>
                      <span>Submit</span>
                    </div>
                  </div>
                </div>
              </main>
            </section>
          </div>
        </Modal>
        <Modal>
          <div className={Styles.feedbackModal}>
            <div className={Styles.wrapperImage}>
              <img src={feedbackChat} alt="chat-images" />
            </div>
            <div className={Styles.desc}>
              <h1>Bantu Kami Jadi Lebih Baik</h1>
              <p>
                Kami membutuhkan kesediaan Anda untuk mengisi Reflective Journal
                dan memberikan kesan selama mengikuti program dari Skills Id
              </p>
            </div>
            <div className={Styles.buttonModal}>
              <div>
                <span>Kembali</span>
              </div>
              <div>
                <span>Mulai</span>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
}
