import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuillEditor = ({ value, onChange, ...props }) => {
  const quillRef = useRef(null);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline"],
        ["link", "blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image"], // Image upload button
      ],
      //   handlers: {
      //     image: handleImageUpload,
      //   },
    },
  };

  const formats = [
    "font",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
    "blockquote",
    "code-block",
    "image",
  ];

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      style={{ minHeight: "200px" }}
      modules={modules}
      formats={formats}
      value={value ?? ""}
      onChange={onChange}
      {...props}
    />
  );
};

export default QuillEditor;
